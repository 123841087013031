import React, { useEffect, useState } from 'react'
import { Button, DatePicker, Row, Col,Divider, message, notification } from 'antd';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { apiServer } from '../../utils/custom-axios';

const { MonthPicker, RangePicker } = DatePicker;
const dateFormat = 'YYYY-MM-DD';
const monthFormat = 'YYYY/MM';
const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY'];

function Deadline(props) {
   const [OldClose_date, setOldClose_date] = useState('');
   const [Close_date, setClose_date] = useState(moment().format('YYYY-MM-DD'));
   useEffect(() => {
        
      getDeadline();
      
   }, [])

   const getDeadline = async () => {

      // 세션이 있을때만 가져오도록 한다.
      if(sessionStorage.getItem('SSID')) {
          let data = await apiServer.get('/deadline').then (response => response.data);
          // console.log('deadline date',data);
          setOldClose_date(data.deadline);
      };
   };
   
   const datepickerhandler = (_date,_datestring) =>{
      if (_datestring <= OldClose_date) {
         message.info('이전 마감일보다 작거나 같을 수 없습니다.');
      } else {
         setClose_date(_datestring);
      // console.log('Close_date',Close_date);
      }
   }
   const update_check = () => {

      if (Close_date <= OldClose_date) {
         message.info('이전 마감일보다 작거나 같을 수 없습니다.');
      } else {
         update_deadline();
       //console.log('Close_date',Close_date);
      }
   }
   const update_deadline = async () => {

      let cdata = await apiServer.put('/deadline',{Close_date}).then ( res => res.data);
      if(cdata == "OK") {
          openNotificationWithIcon('success', '마감일 수정', '기존 마감일을 수정하였습니다.');
          // record = {};
          //setSelectLowData({});
          getDeadline();
      }
      // setData(data);
   }

   const openNotificationWithIcon = (type, title, message) => {
     notification[type]({
       message: title,
       description: message,
     });
   };


   return(
    <div style={{ width: '95%', textAlign: 'center'}}>
      <img src="images/title_head_deadline_01.png" /><br /><br /><br />
      <Row type="flex">
            <Col span={8}></Col>
            <Col span = {4}>
             <center>
             이전 종료일 : 
             </center>
             </Col>
             <Col span={4}>
                <center>
             {OldClose_date}
             </center>
             </Col>
            
            <Col span={8}></Col>
      </Row>
      <Row>
         <Col>
         <p> 
         </p>
         </Col>
      </Row>
       <Row type="flex">
          <Col span={8}></Col>
          <Col span = {4}>
             <center>
             바꿀 종료일 : 
             </center>

           </Col>
          <Col span = {4}>
             <center>
             <DatePicker 
             defaultValue={moment(moment(), dateFormat)} 
             format={dateFormat}
             onChange={datepickerhandler} />
             </center>
             
          </Col>
          
          <Col span={8}></Col>
       </Row>
       <Row>
          <Divider></Divider>
       </Row>
      <Row>
    
         <Col span={24}>
            <center>
            <Button type="primary" size="Large" onClick={(e) => update_check()}>갱신하기</Button>
            </center>
         </Col>
     
      </Row>
         


   </div>
   ) 
}
export default Deadline;