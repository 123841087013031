import React, { useState,useEffect } from 'react';
import { useNavigate , Routes , Route, Link, useLocation} from "react-router-dom";

import { Menu, Layout } from 'antd';
import { QuestionCircleOutlined, MenuOutlined } from '@ant-design/icons';
import { useDispatch } from "react-redux";
import { logoutUser } from "../.././_actions/user_action";

import { IMG_URL } from "../../config";

const { Header, Footer, Sider, Content } = Layout;

// import './menus.css';

const { SubMenu } = Menu;

function Menus (props) {

  const dispatch = useDispatch();
  const [Division, setDivision] = useState('');
  const [SSID, setSSID] = useState('');
  const [Menuindex, setMenuindex] = useState('');
  const [Selectable, setSelectable] = useState(true);
  
  
  let division = sessionStorage.getItem('division');
  let ssid = sessionStorage.getItem('SSID');
  let menuindex = sessionStorage.getItem('menuindex');
  let userName =sessionStorage.getItem('userName');

  let userString =  "사용자 : " + userName + "님";

  let location = useLocation();
  
  const navigate = useNavigate();

  // let division = localStorage.getItem('division');
  // setDivision(division);
  useEffect(() => {
    // let division = localStorage.getItem('division');
    setDivision(division);
    setSSID(ssid);
    if(menuindex === null){ menuindex = '1'}
    setMenuindex(menuindex)
    // console.log('menu_division',division);
    // console.log('menuindex',menuindex);
    // console.log('props',props);
    // if(location.pathname === '/404'){ onoff }

  },[division,ssid])

  const logoutHandler = () => {

    dispatch(logoutUser()).then( res => {
        if(!res.payload.isAuth) {
            localStorage.clear();
            sessionStorage.clear();

           navigate("/login");

        } 
        else {
            alert('error');
        }
    }); 

  };

  const myinfoHandler = () =>{
  };

  const Menu_ClickHandler = (e) => {
    sessionStorage.setItem('menuindex',e.key)
//    console.log('key',e.key);

  };
  const Menu_ClickHandler_0 = () => {
    sessionStorage.setItem('menuindex','0');

    
    setSelectable(false);
    setMenuindex('');
    // setSelectable(true);

  }


  return(
    <>
    {SSID !== null ? 
    <div className="layout">
    
    {/* <Header style={{ position: 'fixed', zIndex: 1, width: '100%', left: 0, padding: 0 }}>  */}
      <>
        {/* <Button>ok</Button> */}
        <Link 
        onClick={Menu_ClickHandler_0}
        to="/"><div className="logobox"><div className="logo" /></div></Link>
        {Division === '협력사' ? 
        <>
        
        
        
      <Menu
        theme="light"
        mode="horizontal"
        // selectedKeys={Menuindex}
        selectable={Selectable}
        defaultSelectedKeys={Menuindex}
        style={{ lineHeight: '64px' }}
        onClick={Menu_ClickHandler}
      >

        <Menu.Item key="1">
              <Link to="/">
              <img className='ant-menu-item' src="images/android-icon-48x48.png" 
                   style={{marginTop:6}} /></Link>
        </Menu.Item>

        <Menu.Item key="2"><Link to="/notice">공지사항</Link></Menu.Item>

        <Menu.Item key="3"><Link to="/salesupload">매출등록</Link></Menu.Item>
    
        <Menu.Item key="4"><Link to="/sales">매출조회</Link></Menu.Item>
        {/* </Menu>
        </Col> 
        <Col span={12}> 
        <Menu
        theme="dark"
        mode="horizontal"
        // selectedKeys={[Menuindex]}
        defaultSelectedKeys={Menuindex}
        style={{ lineHeight: '64px' }}
        onClick={Menu_ClickHandler}
      > */}
        <SubMenu key="submenu" title={userString}>
              <Menu.Item key="5"><Link to="/myinfo">정보변경</Link></Menu.Item>
              <Menu.Item key="6"><a onClick={logoutHandler}>로그아웃</a></Menu.Item>
        </SubMenu>
        <Menu.Item key="7"><Link to="/help1">사용자도움말</Link> <QuestionCircleOutlined /></Menu.Item>
        
      </Menu>
      
      </>
      : null}
        {Division === '가맹점' ? 
      <Menu
        theme="light"
        mode="horizontal"
        // selectedKeys={[Menuindex]}
        defaultSelectedKeys={Menuindex}
        style={{ lineHeight: '64px' }}
        onClick={Menu_ClickHandler}
      >
        <Menu.Item key="1">
              <Link to="/">
              <img className='ant-menu-item' src="images/android-icon-48x48.png" 
                   style={{marginTop:6}} /></Link>
        </Menu.Item>

        <Menu.Item key="2"><Link to="/notice">공지사항</Link></Menu.Item>

        <Menu.Item key="3"><Link to="/invoices">청구서조회</Link></Menu.Item>
    
        {/* <Menu.Item key="3">매출조회</Menu.Item> */}
    
        <SubMenu key="submenu" title={userString}>
              <Menu.Item key="4"><Link to="/myinfo">정보변경</Link></Menu.Item>
              <Menu.Item key="5"><a onClick={logoutHandler}>로그아웃</a></Menu.Item>
        </SubMenu>
        <Menu.Item key="6"><Link to="/help2">사용자도움말</Link> <QuestionCircleOutlined /></Menu.Item>
        
      </Menu>
      : null}
        {Division === '본사' ? 
       
          
        
        <>
        
      <Menu
        theme="light"
        mode="horizontal"
        // selectedKeys={[Menuindex]}
        defaultSelectedKeys={Menuindex}
        style={{ lineHeight: '64px' }}
        onClick={Menu_ClickHandler}
      >
            <Menu.Item key="1">
              <Link to="/">
              <img className='ant-menu-item' src="images/android-icon-48x48.png" 
                   style={{marginTop:6}} /></Link>
            </Menu.Item>
            <Menu.Item key="2"><Link to="/notice">공지사항</Link></Menu.Item>
            <Menu.Item key="3"><Link to="/franchisee">가맹점관리</Link></Menu.Item>
            <Menu.Item key="4"><Link to="/partners">협력사관리</Link></Menu.Item>
            <Menu.Item key="5"><Link to="/deadline">마감일지정</Link></Menu.Item>
            <Menu.Item key="6"><Link to="/groups">그룹관리</Link></Menu.Item>
            <Menu.Item key="7"><Link to="/users">사용자관리</Link></Menu.Item>
            <SubMenu key="submenu" title={userString}>
              <Menu.Item key="8"><Link to="/myinfo">정보변경</Link></Menu.Item>
              <Menu.Item key="9"><a onClick={logoutHandler}>로그아웃</a></Menu.Item>
            </SubMenu>
      </Menu>
      </>
     
      : null}
      {/* <Col span = {4}>
      <Button><Link to="/notice">공지사항</Link></Button>
      </Col>
      <Col span = {4}>
      <Button><Link to="/notice">공지사항</Link></Button>
      </Col>
      <Col span = {4}>
      <Button><Link to="/notice">공지사항</Link></Button>
      </Col>
      <Col span = {4}>
      </Col>
      <Col span = {4}>
      <Button><Link to="/notice">공지사항</Link></Button>
      </Col>
      <Col span = {4}>
      <Button><Link to="/notice">공지사항</Link></Button>
      </Col> */}

      {/* <Col span = {12}>
      <Button><Link to="/notice">공지사항</Link></Button>
     
      <Button><Link to="/notice">공지사항</Link></Button>
     
      <Button><Link to="/notice">공지사항</Link></Button>
      </Col>
      <Col span = {6}>
      </Col>
     
      <Col span = {6}>
      <Button><Link to="/notice">공지사항</Link></Button>
      <Button><Link to="/notice">공지사항</Link></Button>
      </Col> */}

    </>
    {/* </Header> */}
    </div>
    : null}
    
    </>
  
  )

}
export default Menus;