import React, { useEffect, useState } from 'react'
import { Row, Col, Space, Table, Button, notification, Modal, Input, Select, Card, Descriptions, Form, Typography } from 'antd';
import { apiServer } from '../../utils/custom-axios';
import 'moment/locale/ko';
import {     
    columns_users,
    continents_pagination,
    option_orNotUse,
    // option_division,
    option_division2,
    option_auth,
   } from '../.././common/datas/Datas';
import SelectBox from '../.././common/SelectBox';
import DeleteButton from '../../utils/modal-delbutton';
//import DownloadExcel from '../.././common/DownloadExcel';

import { useLocation } from 'react-router-dom';

const { Search } = Input;
const { TextArea } = Input;
const { Option } = Select;

const { Title } = Typography;

function Users(props) {
    
    const [Division, setDivision] = useState('');

    const [Data, setData] = useState([]);
    const [SelectLowData, setSelectLowData] = useState({});
    const [SelectIndex, setSelectIndex] =  useState();
    const [PageSize, setPageSize] = useState(10);
    const [Modalonoff, setModalonoff] = useState(false);
    const [WModalonoff, setWModalonoff] = useState(false);
    const [ModalMode, setModalMode] = useState('읽기');
    const [filename, setFilename] = useState('');
    const [ModalTitle, setModalTitle] = useState('');
    const [codelist, setcodelist] = useState([]);
    const [nowselect, setnowselect] = useState('');
    const [inputOnOff, setInputOnOff] = useState(false);
    const [inputOnOff2, setInputOnOff2] = useState(false);

    const [form] = Form.useForm();

    // searh params parse
    let location = useLocation();
   
    let division = sessionStorage.getItem('division');
    let ssid = sessionStorage.getItem('SSID');
    let userid = sessionStorage.getItem('id');
    let userClass = sessionStorage.getItem('userClass');

    useEffect(() => {
        
        setFilename('사용자관리');
        setDivision(division);
        getUsers();
        
    }, [])

    const runSerch = (value) => {
        // console.log('value',value)
        getUsers(value, 1);
    }
    const getUsers = async (value, getMode = 0) => {

        let data = await apiServer.get('/users',{ params : {'search' : value }}).then (response => response.data)
        // console.log('data',data);
        if(data.msg !== 'Failed') {
            setData(data);
        }
        else if(data.recCount === 0) {
            setData([]);
            if(getMode !== 0) {
                Modal.info({
                    title : '검색된 사용자가 없습니다.',
                    content : (
                        <><br />
                          검색 조건에 맞는 사용자를 찾을 수 없습니다.
                        </>
                    ),
                    onOk() {},
                });
            }
        }
    }

    const UpdatePagination = (option) =>{
        setPageSize(option);
    }

    const clickselectRow = (record ,index) => {
        
        setSelectLowData(record);
        setSelectIndex(index);
        //console.log('record',record);
        showModal();
        //console.log('counter api', cdata);
        
    }
    const  WriteModal = () => {
        let record = {}
        record.division = '협력사';
        record.orNotUse = '정상';
        record.auth = '일반사용자';
        setnowselect('');
        setSelectLowData(record);
        setSelectIndex();
        setModalMode('쓰기');
        setModalTitle('사용자 등록');
        setWModalonoff(true);
        setInputOnOff(false);
        setInputOnOff2(false);
    };

    const  showModal = () => {
        //console.log(userClass)
        if (Division === '본사' && userClass === '관리자') {
            setWModalonoff(true);
            setModalMode('수정');
            setModalTitle('사용자 수정');
            setInputOnOff(true);
            setInputOnOff2(true);
        }
        if (Division !== '본사' || userClass === '일반사용자') {
            setModalonoff(true);
            setModalMode('읽기');
            setModalTitle('사용자 상세보기');
        }
    };
    const  handleCancel = () => {
        getUsers();
        setModalonoff(false);
        setWModalonoff(false);
        form.resetFields();
    };

    const  handleOk = () => {
        
        if (ModalMode === '수정') {
            update_user();
        }
        if (ModalMode === '쓰기') {
            insert_user();
        }
        setModalonoff(false);
        setWModalonoff(false);
        
    };

    const handleDelete = async (key) => {
        // 그룹 레코드 실제 DB 삭제
        let response =  await apiServer.delete(`/users/${key}`).then ( res => res.data);

        if(response === "OK") {
            setModalonoff(false);
            setWModalonoff(false);
            getUsers();
        }
    }

    const handleSubmit = (values) => {
        console.log(values)

        if (ModalMode === '수정'){
            update_user(values);
        }
        if (ModalMode === '쓰기'){
            insert_user(values);
        }
        setModalonoff(false);
        setWModalonoff(false);
    }

    const insert_user = async (values) => {
        
        let record = values;
        
        record.firstWriter = userid;

        let cdata = await apiServer.post('/users',{record}).then ( res => res.data);
        if(cdata == "OK") {
            openNotificationWithIcon('success', '사용자 등록', '새로운 사용자를 등록했습니다.', 'topRight');
            getUsers();
        }
        else if(cdata === 'Failed') {
            openNotificationWithIcon('error', '사용자 등록 실패', '새로운 사용자를 등록하는데 실패했습니다.', 'topLeft');
        }
        else {
            Modal.info({
                title : '에러가 검출 되었습니다.',
                content : (
                    <><br />
                      {cdata}
                    </>
                ),
                onOk() {},
            });
        }
    }

    const update_user = async (values) => {
        
        let data = Data;
        let record = values;

        record._id = SelectLowData._id;
        record.updateWriter = userid;

        let cdata = await apiServer.put(`/users/${record._id}`,{record}).then ( res => res.data);
        console.log('cdata',cdata,userid); 
        if(cdata == "OK") {
            openNotificationWithIcon('success', '사용자 수정', '기존 사용자를 수정하였습니다.', 'topRight');
            setSelectLowData({});
            getUsers();
            setSelectLowData({...record});
        }
        else if(cdata === 'Failed') {
            openNotificationWithIcon('error', '사용자 수정 실패', '새로운 사용자를 수정하는데 실패했습니다.', 'topLeft');
        }
        else {
            Modal.info({
                title : '에러가 검출 되었습니다.',
                content : (
                    <><br />
                      {cdata}
                    </>
                ),
                onOk() {},
            });
        }
    }

    const inputHandler = (name,event) => {
       
        let record = SelectLowData;

        if(event.currentTarget.value === ''){
            record[name] = event.currentTarget.value;
        } else 
        { 
            record[name] = event.currentTarget.value;
        }

        setSelectLowData({...record});
    }
    const selectHandler = (name,event) => {
       
        let record = SelectLowData;
        record[name] = event;

        if(event === '본사' ) {
            record.divisionId = '0';
        } 
        else if (event ==='협력사' || event === '가맹점') {
            record.divisionId = '';
        }
       
        setSelectLowData({...record});
        console.log('record',record);

        if(name === "division"  && event === "본사") {
            setInputOnOff(true);
        } else if (name === "division" && event !== "본사") {
            setInputOnOff(false);
        }
        
    }

    const optionRander = (name) =>{

        console.log('optionname',name);

    }
    const renderOption = (selectoption) => (
        selectoption && selectoption.map((value,index) => (
            <Option key={value._id} value={value.name} label={value.name} > {value.name} </Option>
        ))
    )
    const renderOption_code = (selectoption) => (
        selectoption && selectoption.map((value,index) => (
            <Option key={value._id} value={value._id} label={value.shopName} > {value.shopName} </Option>
        ))
    )
    const codeserch = async () => {

        console.log('division', SelectLowData.division)
        let cdata = await apiServer.get('/user-codesearch',{ params : {'division' : SelectLowData.division , 'search' : SelectLowData._serch}}).then ( res => res.data);
        console.log('code',cdata);
        
        if (cdata !== 'Failed'){
            let record = SelectLowData;
            setcodelist(cdata);
            setnowselect(cdata[0].shopName) 
            record.divisionId = cdata[0]._id;
            setSelectLowData({...record});
            openNotificationWithIcon('success', '업체 검색 완료', "'검색된 업체:'  를 클릭하시면 업체 리스트를 볼 수 있습니다.", 'topRight');
        }
        else {
            openNotificationWithIcon('error', '업체검색 실패', '업체를 검색하지 못했습니다. 업체명을 바꿔서 검색해보세요.', 'topLeft');
        }

    }

    const openNotificationWithIcon = (type, title, message, placement = null ) => {
        notification[type]({
          message: title,
          description: message,
          placement,
        });
    };

   return(
    <div style={{ width: '95%'}}>
    <div style={{ textAlign: 'center' }}>
    <img src="images/title_head_users_01.png" /><br /><br />
        {/* <DownloadExcel dataSource={Data} columns={columns_users} filename={filename} ></DownloadExcel>    */}
        <Row justify="center" >
          <Space size={[200, 10]} wrap>
            {Division === '본사' && userClass === '관리자' ? <Button onClick = {WriteModal} type='primary' >사용자 추가</Button> : null}
                <Search
                        placeholder="이름, 업체명으로 검색"
                        enterButton="검색"
                        // size="large"
                        onSearch={value => runSerch(value)}
                        allowClear
                        style={{width:400}} />
            <SelectBox
                list = {continents_pagination}
                focus_list = {PageSize}
                refreshFunction={UpdatePagination}
                >
            </SelectBox>
          </Space>
        </Row>
        <Row>
            <Col> <p> </p></Col>
        </Row>
        <Row justify="center">
        <Table style={{width:1100, cursor: 'pointer'}}
            dataSource={Data} 
            pagination={{ pageSize: PageSize, position: ['bottomCenter'] }}
            columns={columns_users} 
            //pagination = {10}
            rowKey={Data => Data._id}
            onRow={(record,index) => ({
                onClick: () => {
                clickselectRow(record,index)
                },})}
            scroll={{ x: 1000 }}
            size="small" >
        </Table>
        </Row>

        <Modal
            width={640}
            visible={Modalonoff}
            title={ModalTitle}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={[
                        <Button key="back" onClick={handleCancel}>닫 기</Button>,
                   ]} >
            <Row justify='center' type="flex">
                <Card style={{ width:600, borderRadius:6 }}>
                    <Title level={4} type='success'>사용자 상세정보</Title><p />
                    <Descriptions bordered column={{xxl:2, xl:2, lg:2, md:2, sm:2, xs:1}}>
                        <Descriptions.Item label='거래처구분'>{SelectLowData.division}</Descriptions.Item>
                        <Descriptions.Item label='구분코드'>{SelectLowData.divisionId}</Descriptions.Item>
                        <Descriptions.Item label='거래처명'>{SelectLowData.shopName}</Descriptions.Item>
                        <Descriptions.Item label='사용자 이름'>{SelectLowData.userName}</Descriptions.Item>
                        <Descriptions.Item label='사용자 아이디'>{SelectLowData.userId}</Descriptions.Item>
                        <Descriptions.Item label='사용자 등급'>{SelectLowData.auth}</Descriptions.Item>
                        <Descriptions.Item label='사용자 상태'>{SelectLowData.orNotUse}</Descriptions.Item>
                    </Descriptions>
                </Card>
            </Row>
        </Modal>
        
        {/* 추가 및 수정 삭제 */}
        <Modal
            width={640}
            visible={WModalonoff}
            destroyOnClose={true}
            title={ModalTitle}
            onOk={form.submit}
            onCancel={handleCancel}
            footer={[
                <DeleteButton isAdmin={userClass} onDelete={handleDelete} deleteKey={SelectLowData._id} />,
                <Button key="back" onClick={handleCancel}>닫 기</Button>,
                <Button key="submit" type="primary" onClick={form.submit} style={{width:140}}>저  장</Button>
            ]}
            >
            <Row justify='center'>
                <Form form={form} onFinish={handleSubmit} style={{width:'80%'}} labelCol={{span:6}}>
                    <Form.Item label="거래처구분"
                               name="division"
                               rules={[{ required: true, message: '거래처구분을 선택해주세요.'}]}>
                        <Select onSelect={(e) => selectHandler('division', e)}
                               disabled={inputOnOff2}
                               defaultValue={form.setFieldsValue({ division : SelectLowData.division })}>
                            {renderOption(option_division2)}
                        </Select>
                    </Form.Item>
                    <Form.Item label="업체명검색"
                               name="_serch">
                        <span>
                        <Input placeholder="업체명으로 검색. 없으면 전체 검색입니다."
                               onChange={(e) => inputHandler('_serch', e)}
                               value= {SelectLowData._serch}
                               disabled={inputOnOff}
                               allowClear  style={{width:290}} />
                        <Button onClick = {codeserch}
                                type="primary"
                                disabled={inputOnOff}>검 색</Button>
                        </span>
                    </Form.Item>
                    <Form.Item label="검색된 업체"
                               name="divisonId">
                        <Select onSelect={(e) => selectHandler('divisionId', e)}
                                defaultValue={nowselect}
                                style={{ width: '100%' }}
                                value={nowselect}
                                disabled={inputOnOff} >
                            {renderOption_code(codelist)}
                        </Select>
                    </Form.Item>
                    <Form.Item label="구분코드"
                               name="divisionId"
                               rules={[{ required: true, message: '구분코드가 반드시 필요합니다'}]}>
                        <Input onChange={(e) => inputHandler('divisionId', e)}
                               defaultValue={form.setFieldsValue({ divisionId : SelectLowData.divisionId })}
                               value={SelectLowData.divisionId}
                               disabled={true}
                               style={{ width: '100%' }} />
                    </Form.Item>
                    <Form.Item label="사용자이름"
                               name="userName"
                               rules={[{ required: true, message: '사용자이름은 반드시 입력하세요'}]}>
                        <Input onChange={(e) => inputHandler('userName', e)}
                               defaultValue={form.setFieldsValue({ userName : SelectLowData.userName })}
                               value={SelectLowData.userName}
                               disabled={false} />
                    </Form.Item>
                    <Form.Item label="사용자 아이디"
                               name="userId"
                               rules={[{ required: true, message: '아이디는 반드시 입력하세요'}]}>
                        <Input onChange={(e) => inputHandler('userId', e)}
                               defaultValue={form.setFieldsValue({ userId : SelectLowData.userId })}
                               value={SelectLowData.userId}
                               disabled={false} />
                    </Form.Item>
                    <Form.Item label="비밀번호"
                               name="passwd"
                               rules={[{ required: true, message: '비밀번호는 반드시 입력하세요'}]}>
                        <Input onChange={(e) => inputHandler('passwd', e)}
                               defaultValue={form.setFieldsValue({ passwd : SelectLowData.passwd })}
                               value={SelectLowData.passwd}
                               disabled={false} />
                    </Form.Item>
                    <Form.Item label="사용자 등급"
                               name="auth"
                               rules={[{ required: true, message: '등급을 반드시 선택하세요'}]}>
                        <Select onSelect={(e) => selectHandler('auth', e)}
                                defaultValue={form.setFieldsValue({ auth : SelectLowData.auth })}
                                style={{ width: '100%' }}>
                            {renderOption(option_auth)}
                        </Select>
                    </Form.Item>
                    <Form.Item label="사용자 상태"
                               name="orNotUse"
                               rules={[{ required: true, message: '상태를 반드시 선택하세요'}]}>
                        <Select onSelect={(e) => selectHandler('orNotUse', e)}
                                defaultValue={form.setFieldsValue({ orNotUse : SelectLowData.orNotUse })}
                                style={{ width: '100%' }}>
                            {renderOption(option_orNotUse)}
                        </Select>
                    </Form.Item>
                </Form>                
            </Row>
        </Modal>
   </div>
   </div>
   ) 
}
export default Users;