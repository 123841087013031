import React from 'react';
import { Typography, Tabs, Row, Image, Avatar, BackTop } from 'antd';

const { TabPane } = Tabs;
const { Title } = Typography;

function help2() {

    return(
        <>
        <Row justify='center'>
            <div style={{marginTop:30, color:'#fa541c'}}>* 원하는 설명을 클릭하세요. 모든 이미지는 마우스를 클릭하면 크게 확대됩니다.<p /></div>
        </Row>
        <Row justify='center'>
            <Tabs defaultActiveKey="0" style={{width:1200}}>
            <TabPane tab='가맹점 작업메뉴' key='0'>
                    <p><br /><br />
                        각 제조업체나 공급업체로부터 발행된 청구서를 GND 본사에서 일괄 합산하여 보여주는 프로그램입니다.
                        <br /><br />
                        <center><Image src='/manual/screenshot/가맹점-기본메뉴-번호.png' /></center>
                        <br /><br />
                        <Row>
                            <Avatar style={{ backgroundColor: '#fa8c16', verticalAlign: 'bottom' }} size={24}>1</Avatar> &nbsp;&nbsp;
                            <Title level={4}> GND 본사에서 올리는 각종 공지사항이 올라옵니다. 항상 주의깊게 살펴봐주세요.</Title>
                        </Row>
                        <br /><br />
                        <Row>
                            <Avatar style={{ backgroundColor: '#fa8c16', verticalAlign: 'bottom' }} size={24}>2</Avatar> &nbsp;&nbsp;
                            <Title level={4}> 각 공급업체로부터 발행된 청구서를 조회할 수 있습니다.</Title>
                        </Row>
                        <br /><br />
                        <Row>
                            <Avatar style={{ backgroundColor: '#fa8c16', verticalAlign: 'bottom' }} size={24}>3</Avatar> &nbsp;&nbsp;
                            <Title level={4}> 사용자의 비밀번호 변경, 로그아웃이 있습니다.</Title>
                        </Row>
                        <br /><br />
                        <Row>
                            <Avatar style={{ backgroundColor: '#fa8c16', verticalAlign: 'bottom' }} size={24}>4</Avatar> &nbsp;&nbsp;
                            <Title level={4}> 현재 보고 있는 메뉴로 사용자설명서 입니다.</Title>
                        </Row>
                    </p>
                    <BackTop />

                </TabPane>
                <TabPane tab='청구서 조회' key='1'>
                    <p><br /><br />
                        각 제조업체나 공급업체로부터 발행된 계산서를 GND 본사에서 일괄 합산하여 보여주는 프로그램입니다.<p />
                        <br /><br />
                        <Row>
                            <Avatar style={{ backgroundColor: '#fa8c16', verticalAlign: 'bottom' }} size={24}>1</Avatar> &nbsp;&nbsp;
                            <Title level={4}> 청구서의 기본화면은 다음과 같습니다.</Title>
                        </Row>
                        <center><Image src='/manual/screenshot/가맹점-청구서.png' /></center>
                        <br /><br />
                        <Row>
                            <Avatar style={{ backgroundColor: '#fa8c16', verticalAlign: 'bottom' }} size={24}>2</Avatar> &nbsp;&nbsp;
                            <Title level={4}> 검색 버튼 옆의 날짜창을 눌러 청구서를 조회하고 싶은 년-월 을 선택하고 검색을 눌러줍니다.</Title>
                        </Row>
                        <center><Image src='/manual/screenshot/가맹점-청구서-년월.png' /></center>
                        <br /><br />
                        <Row>
                            <Avatar style={{ backgroundColor: '#fa8c16', verticalAlign: 'bottom' }} size={24}>3</Avatar> &nbsp;&nbsp;
                            <Title level={4}> 청구서가 조회되면 다음과 같은 화면이 나타납니다.</Title>
                        </Row>
                        <center><Image src='/manual/screenshot/가맹점-청구서-조회화면.png' /></center>
                        <br /><br />
                        <Row>
                            <Avatar style={{ backgroundColor: '#fa8c16', verticalAlign: 'bottom' }} size={24}>4</Avatar> &nbsp;&nbsp;
                            <Title level={4}> 각 공급업체별로 청구서를 따로 보고 싶다면 해당 공급업체를 선택하면 됩니다.</Title>
                        </Row>
                        <center><Image src='/manual/screenshot/가맹점-청구서-개별화면1.png' /></center>
                        <center><Image src='/manual/screenshot/가맹점-청구서-개별화면2.png' /></center>
                        <br /><br />
                        <Row>
                            <Avatar style={{ backgroundColor: '#fa8c16', verticalAlign: 'bottom' }} size={24}>5</Avatar> &nbsp;&nbsp;
                            <Title level={4}> 다시 종합청구서를 보고 싶으시면 '합계' 항목을 눌러주세요.</Title>
                        </Row>
                        <center><Image src='/manual/screenshot/가맹점-청구서-합계화면.png' /></center>
                        <br /><br />
                        <Row>
                            <Avatar style={{ backgroundColor: '#fa8c16', verticalAlign: 'bottom' }} size={24}>6</Avatar> &nbsp;&nbsp;
                            <Title level={4}> 조회 시 청구서가 없는 달은 '메세지'로 화면에 표시됩니다.</Title>
                        </Row>
                    </p>
                    <BackTop />
                </TabPane>
                <TabPane tab='엑셀파일 다운로드' key='2'>
                    <br /><br />
                        간편하고 보기 좋게 엑셀파일로 청구서를 받아볼수 있는 기능입니다.<p />
                    <br /><br />
                    <Row>
                            <Avatar style={{ backgroundColor: '#fa8c16', verticalAlign: 'bottom' }} size={24}>1</Avatar> &nbsp;&nbsp;
                            <Title level={4}> 조회가 된 상태에서 'Excel파일로 다운' 버튼을 누릅니다.</Title>
                    </Row>
                    <center><Image src='/manual/screenshot/가맹점-청구서-다운로드1.png' /></center>
                    <br /><br />
                    <Row>
                            <Avatar style={{ backgroundColor: '#fa8c16', verticalAlign: 'bottom' }} size={24}>2</Avatar> &nbsp;&nbsp;
                            <Title level={4}> 크롬 브라우저 하단에 보시면 파일이 다운되어 있습니다. 해당 파일을 클릭하시면 엑셀이 자동 실행되면서 문서가 보입니다.</Title>
                    </Row>
                    <center><Image src='/manual/screenshot/가맹점-청구서-다운로드2.png' /></center>
                    <br /><br />
                    <Row>
                            <Avatar style={{ backgroundColor: '#fa8c16', verticalAlign: 'bottom' }} size={24}>3</Avatar> &nbsp;&nbsp;
                            <Title level={4}> 다음과 같은 화면이 보이게 됩니다.</Title>
                    </Row>
                    <center><Image src='/manual/screenshot/가맹점-청구서-엑셀파일다운받기.png' /></center>
                    <br /><br />
                    <BackTop />
                </TabPane>

            </Tabs>
        </Row>
        </>
    )
}
export default help2;