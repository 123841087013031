import React, { useEffect, useState } from 'react'
import axios from "axios";
import { Row, Col, Form, Table, Button, notification, Space, Modal, Input, Select, Card, DatePicker, Descriptions } from 'antd';
import Uploads from '../../common/upload/uploads';
import moment from 'moment';
import { apiServer } from '../../utils/custom-axios';
import {     
    columns_franchisee,
    continents_pagination,
    option_orNotUse2,
    option_category,
   } from '../.././common/datas/Datas';
import SelectBox from '../.././common/SelectBox';
import DownloadExcel from '../.././common/DownloadExcel';
import DaumPostcode from 'react-daum-postcode';
import { useLocation } from 'react-router-dom';
import 'moment/locale/ko';
import locale from 'antd/es/date-picker/locale/ko_KR'

import DeleteButton from '../../utils/modal-delbutton';
import InputMask from 'react-input-mask';


const { Search } = Input;
const { TextArea } = Input;
const { Option } = Select;
const { MonthPicker, RangePicker } = DatePicker;
const dateFormat = 'YYYY-MM-DD';

function Franchisee(props) {
    
    const [Division, setDivision] = useState('');

    const [Data, setData] = useState([]);
    const [SelectLowData, setSelectLowData] = useState({});
    const [tmpSelectLowData, setTmpSelectLowData] = useState({});
    const [SelectIndex, setSelectIndex] =  useState();
    const [PageSize, setPageSize] = useState(10);
    const [Modalonoff, setModalonoff] = useState(false);
    const [WModalonoff, setWModalonoff] = useState(false);
    const [ModalMode, setModalMode] = useState('읽기');
    const [filename, setFilename] = useState('');
    const [GroupData, setGroupData] = useState([]);
    const [Searchtext, setSearchtext] = useState({});
    const [ModalTitle, setModalTitle] = useState('');

    const [address, setAddress] = useState(''); // 주소
    const [addressDetail, setAddressDetail] = useState(''); // 상세주소
    const [isOpenPost, setIsOpenPost] = useState(false);

    const [form] = Form.useForm();

    // searh params parse
    let location = useLocation();
   
    let division = sessionStorage.getItem('division');
    let ssid = sessionStorage.getItem('SSID');
    let userid = sessionStorage.getItem('id');
    let userClass = sessionStorage.getItem('userClass');

    useEffect(() => {
        let data = {'search' : '' ,'orNotUse' : '','groupName' : ''};
        setSearchtext(data);
        setFilename('가맹점');
        setDivision(division);
        getGroupData();
        getFranchisee();
        
    }, [])

    const runSerch = (value) => {
        let body = Searchtext;
        
        body['search'] = value;

        // console.log('value',value)
        setSearchtext(body);
        getFranchisee(1);
    }

    const getFranchisee = async (getMode = 0) => {

        let data = await apiServer.get('/franchisee',{ params : {'search' : Searchtext.search ,'orNotUse' : Searchtext.orNotUse,'groupName' : Searchtext.groupName }}).then (response => response.data)

        console.log(data)
        if(data.msg === 'OK') {
            setData(data.data);
        }
        else if(data.msg === 'Failed') {
            setData([]);
            if(getMode !== 0) {
                Modal.info({
                    title : '검색된 가맹점이 없습니다.',
                    content : (
                        <><br />
                          검색 조건에 맞는 가맹점을 찾을 수 없습니다.
                        </>
                    ),
                    onOk() {},
                });
            }
        }
        else {
            setData([]);
            Modal.info({
                title : '에러가 검출 되었습니다.',
                content : (
                    <><br />
                      {data}
                    </>
                ),
                width: 800,
                onOk() {},
            });
        }
    }

    const getGroupData = async (value) => {

        let data = await apiServer.get('/group-all-code').then (response => response.data)
        //console.log('그룹데이터',data);
        setGroupData(data);
    }

    const UpdatePagination = (option) =>{
        setPageSize(option);
    }

    const clickselectRow = (record ,index) => {
        setTmpSelectLowData(record);
        setSelectLowData(record);
        setSelectIndex(index);
        showModal();
    }

    const  WriteModal = () => {
        setSelectLowData({});
        setSelectIndex();
        setModalMode('쓰기');
        setModalTitle('가맹점 등록');
        setWModalonoff(true);
    };

    const  showModal = () => {
        if(Division === '본사' && userClass === '관리자') {
            setWModalonoff(true);
            setModalMode('수정');
            setModalTitle('가맹점 수정');
        }
        if(Division !== '본사' || userClass === '일반사용자') {
            setModalonoff(true);
            setModalMode('읽기');
        }
    };

    const  handleCancel = () => {
        getFranchisee();
        setModalonoff(false);
        setWModalonoff(false);
        form.resetFields();
    };

    const insert_franchisee = async (values) => {
        
        let record = values;
        record.firstWriter = userid;

        let cdata = await apiServer.post('/franchisee',{record}).then ( res => res.data);

        if(cdata === "OK") {
            openNotificationWithIcon('success', '가맹점 등록', '새로운 가맹점을 등록했습니다.', 'topRight');
            getFranchisee();
        }
        else if(cdata === 'bad data') {
            openNotificationWithIcon('error', '가맹점 등록실패 !', '잘못된 데이터로 인해 등록에 실패했습니다. 다시 시도해주세요.', 'topLeft');
        }
        else if(cdata === 'Failed') {
            openNotificationWithIcon('error', '가맹점 등록실패', '새로운 가맹점을 등록하는데 실패했습니다.', 'topLeft');
        }
        else if(cdata === 'Duplicate Key') {
            openNotificationWithIcon('error', '가맹점 등록실패', '코드값 혹은 별칭이 중복되어 새로운 가맹점을 등록하는데 실패했습니다.', 'topLeft');
        }
        else {
            Modal.info({
                title : '에러가 검출 되었습니다.',
                content : (
                    <><br />
                      {cdata}
                    </>
                ),
                onOk() {},
            });
        }
    }

    const update_franchisee = async (values) => {
        
        let record = values;

        record._id = SelectLowData._id;
        record.updateWriter = userid;

        let cdata = await apiServer.put(`/franchisee/${record._id}`,{record}).then ( res => res.data);
        console.log('cdata',cdata,userid); 
        if(cdata === "OK") {
            openNotificationWithIcon('success', '가맹점 수정', '기존 가맹점을 수정하였습니다.', 'topRight');
            setSelectLowData({});
            getFranchisee();
        }
        else if(cdata === 'Failed') {
            openNotificationWithIcon('error', '가맹점 수정실패', '기존 가맹점을 수정하는데 실패했습니다.', 'topLeft');
        }
        else {
            Modal.info({
                title : '에러가 검출 되었습니다.',
                content : (
                    <><br />
                      {cdata}
                    </>
                ),
                onOk() {},
            });
        }
    }

    const inputHandler = (name,event) => {

        let record = SelectLowData;

        if(event.currentTarget.value === '')
            record[name] = event.currentTarget.value;
        else 
            record[name] = event.currentTarget.value;

        setSelectLowData({...record});
    }

    const selectHandler = (name,event) => {

        let body = Searchtext;
    
        body[name] = event;
        setSearchtext(body);
    }

    const selectHandler_modal = (name,event) => {
       
        let record = SelectLowData;

        record[name] = event;
        setSelectLowData({...record});
    }

    const renderOption = (selectoption) => {

        if(Array.isArray(selectoption)) {
            return (
                selectoption && selectoption.map((value,index) => (
                    <Option key={value._id} value={value.name} label={value.name} > {value.name} </Option> ))
            )
        }
    }

    const clearHandler = ( name ) => {
        let body = Searchtext;
        body[name] = '';

        setSearchtext(body);
    }

    const clearHandler_modal = ( name ) => {
        let record = SelectLowData;
        console.log('event',name);
        record[name] = '';

        setSelectLowData({...record});
    }

    const codeCheck = async (value) => {

        let cdata = await apiServer.get('/franchisee-codesearch',{ params : {'code' : value}}).then ( res => res.data);

        if (cdata === 'Overlap'){
            openNotificationWithIcon('error', '중복코드', '해당코드는 사용중입니다.', 'topLeft');
            return('Failed');
        } 
        else if( cdata === 'OK') {
            openNotificationWithIcon('success', '사용가능 코드', '사용할 수 있는 코드입니다.', 'topRight');
            return('Success');
        }    
        else if( cdata === 'Failed') {
            openNotificationWithIcon('error', '코드를 입력하세요', '4자리 코드가 입력되지 않았습니다.', 'topLeft');
            return('Failed');
        }
    }

    const onChangeOpenPost = () => {
        setIsOpenPost(!isOpenPost);
      };
    
    const onCompletePost = (data) => {
        let record = SelectLowData;
        let fullAddr = data.address;
        let extraAddr = '';
        //console.log('zipcode',data);
        if (data.addressType === 'R') {
            if (data.bname !== '') {
            extraAddr += data.bname;
            }
            if (data.buildingName !== '') {
            extraAddr += extraAddr !== '' ? `, ${data.buildingName}` : data.buildingName;
            }
            fullAddr += extraAddr !== '' ? ` (${extraAddr})` : '';
        }
        record.zipcode = data.zonecode;
        record.address1 = fullAddr;
        setSelectLowData({...record});
        setAddress(data.zonecode);
        setAddressDetail(fullAddr);
        setIsOpenPost(false);
    };
    
    const openNotificationWithIcon = (type, title, message, placement = null ) => {
        notification[type]({
          message: title,
          description: message,
          placement,
        });
    };
     
    const postCodeStyle = {
        position: "absolute",
        top: "0px",
        left: "0px",
        zIndex: "100",
        border: "1px solid #000000",
        overflow: "hidden",
        width: "595px",
        height : "450px"

    };

    const openZipCode = () => {
        // console.log('열림')
        setIsOpenPost(true);
    }

    const handleDelete = async (key) => {
        let response =  await apiServer.delete(`/franchisee/${key}`).then ( res => res.data);

        if(response === "OK") {
            setModalonoff(false);
            setWModalonoff(false);
            getFranchisee();
        }
    }

    const handleSubmit = async (values) => {

        console.log(values); 
        if (ModalMode === '수정') {
            update_franchisee(values);
        }
        if (ModalMode === '쓰기') {
            insert_franchisee(values);
        }
        setModalonoff(false);
        setWModalonoff(false);
    }

    const postModalCancel = () => {
        setIsOpenPost(false);
    }

    return(
    <div style={{ width: '100%'}}>
    <div style={{ textAlign: 'center' }}>
    <img src="images/title_head_franchisee_01.png" /><br /><br />
        <Row justify='center'>
            <Space size={[140, 10]} wrap>
                {Division === '본사' && userClass === '관리자' ? 
                    <Button type='primary'
                            onClick = {WriteModal}> 가맹점등록</Button>
                : null}
                <Select placeholder="회원상태" style={{width:100}}
                    onSelect={(e) => selectHandler('orNotUse', e)}
                    onClear={() => clearHandler('orNotUse') }
                    allowClear>
                        {renderOption(option_orNotUse2)}
                </Select>
                <Select  style={{width:140}}
                    placeholder="그룹분류"
                    // size="large"
                    onSelect={(e) => selectHandler('groupName', e)}
                    onClear={() => clearHandler('groupName') }
                    allowClear>
                        {renderOption(GroupData)}
                </Select>
                <Search  style={{width:400}}
                    allowClear
                    placeholder="상호, 별칭 혹은 대표자이름 으로 검색해주세요."
                    enterButton="검 색"
                    onSearch={value => runSerch(value)}/>
                <SelectBox
                    list = {continents_pagination}
                    focus_list = {PageSize}
                    refreshFunction={UpdatePagination}>
                </SelectBox>
                {Division === '본사' && userClass === '관리자' ? 
                    <DownloadExcel dataSource={Data} columns={columns_franchisee} filename={filename} /> 
                : null}
            </Space>
        </Row>
        <Row>
        <Col span={24}> <span> <p> </p> </span></Col>
        </Row>
        <Row justify='center'>
            <Table  style={{cursor : 'pointer', width:1800}}
                dataSource={Data} 
                pagination={{ pageSize: PageSize, position: ['bottomCenter'] }}
                columns={columns_franchisee} 
                // pagination = {10}
                rowKey={Data => Data._id}
                onRow={(record,index) => ({
                    onClick: () => {
                      setTmpSelectLowData(record);
                      console.log(tmpSelectLowData)
                      clickselectRow(record,index);
                    },})}
                scroll={{ x: 1000 }}  size="small">
            </Table>
        </Row>

        <Modal
            width={1200}
            visible={Modalonoff}
            title='가맹점 상세 정보'
            onCancel={handleCancel}
            footer={[
                <Button key="back" onClick={handleCancel}>닫 기</Button>,
            ]}
            >
            <Row justify='center' sytle={{width:'100%'}}>
                <Descriptions bordered style={{width:'100%'}}>
                    <Descriptions.Item label='가맹점코드' labelStyle={{'text-align':'right'}}>{SelectLowData.code}</Descriptions.Item>
                    <Descriptions.Item label='상호' labelStyle={{'text-align':'right'}}>{SelectLowData.shopName}</Descriptions.Item>
                    <Descriptions.Item label='별칭' labelStyle={{'text-align':'right'}}>{SelectLowData.nickName}</Descriptions.Item>
                    <Descriptions.Item label='가맹점구분' labelStyle={{'text-align':'right'}}>{SelectLowData.category}</Descriptions.Item>
                    <Descriptions.Item label='그룹분류' labelStyle={{'text-align':'right'}}>{SelectLowData.groupName}</Descriptions.Item>
                    <Descriptions.Item label='회원상태' labelStyle={{'text-align':'right'}}>{SelectLowData.orNotUse}</Descriptions.Item>
                    <Descriptions.Item label='사업자번호' labelStyle={{'text-align':'right'}}>{SelectLowData.businessNumber}</Descriptions.Item>
                    <Descriptions.Item label='대표자' labelStyle={{'text-align':'right'}}>{SelectLowData.CEO}</Descriptions.Item>
                    <Descriptions.Item label='팩스번호' labelStyle={{'text-align':'right'}}>{SelectLowData.FAX}</Descriptions.Item>
                    <Descriptions.Item label='우편번호' labelStyle={{'text-align':'right'}}>{SelectLowData.zipcode}</Descriptions.Item>
                    <Descriptions.Item label='주소' labelStyle={{'text-align':'right'}}>{SelectLowData.address1}</Descriptions.Item>
                    <Descriptions.Item label='상세주소' labelStyle={{'text-align':'right'}}>{SelectLowData.address2}</Descriptions.Item>
                    <Descriptions.Item label='업태' labelStyle={{'text-align':'right'}}>{SelectLowData.businessCondition}</Descriptions.Item>
                    <Descriptions.Item label='종목' labelStyle={{'text-align':'right'}}>{SelectLowData.businessType}</Descriptions.Item>
                    <Descriptions.Item label='전화번호' labelStyle={{'text-align':'right'}}>{SelectLowData.telNumber}</Descriptions.Item>
                    <Descriptions.Item label='휴대전화' labelStyle={{'text-align':'right'}}>{SelectLowData.mobile}</Descriptions.Item>
                    <Descriptions.Item label='담당자' labelStyle={{'text-align':'right'}}>{SelectLowData.contactMan}</Descriptions.Item>
                    <Descriptions.Item label='담당자 연락처' labelStyle={{'text-align':'right'}}>{SelectLowData.contactPhone}</Descriptions.Item>
                    <Descriptions.Item label='정보공개서 No' labelStyle={{'text-align':'right'}}>{SelectLowData.infoDocument}</Descriptions.Item>
                    <Descriptions.Item label='가맹계약서 No' labelStyle={{'text-align':'right'}}>{SelectLowData.franchiseeAgreement}</Descriptions.Item>
                    <Descriptions.Item label='이메일' labelStyle={{'text-align':'right'}}>{SelectLowData.email}</Descriptions.Item>
                    <Descriptions.Item label='면적' labelStyle={{'text-align':'right'}}>{SelectLowData.area}</Descriptions.Item>
                    <Descriptions.Item label='양수도' labelStyle={{'text-align':'right'}}>{SelectLowData.transferContract}</Descriptions.Item>
                    <Descriptions.Item label='개업년월일' labelStyle={{'text-align':'right'}}>{SelectLowData.openDate}</Descriptions.Item>
                    <Descriptions.Item label='계약체결일' labelStyle={{'text-align':'right'}}>{SelectLowData.agreementDate}</Descriptions.Item>
                    <Descriptions.Item label='거래종료일' labelStyle={{'text-align':'right'}}>{SelectLowData.closeDate}</Descriptions.Item>
                    <Descriptions.Item label='정보공개서일자' labelStyle={{'text-align':'right'}}>{SelectLowData.infoDocDate}</Descriptions.Item>
                    <Descriptions.Item label='가맹계약체결일자' labelStyle={{'text-align':'right'}}>{SelectLowData.contractDate}</Descriptions.Item>
                    <Descriptions.Item label='가맹계약종료일자' labelStyle={{'text-align':'right'}}>{SelectLowData.terminateContractDate}</Descriptions.Item>
                </Descriptions>
                <Descriptions bordered column={{xxl:1, xl:1, lg:1, md:1, sm:1, xs:1}} style={{width:'100%'}}>
                    <Descriptions.Item label='관리메모' labelStyle={{'text-align':'right'}}>{SelectLowData.managementMemo && SelectLowData.managementMemo.split('\n').map((item, idx) => { return( <span key={idx}>{item}<br /></span>)})}</Descriptions.Item>
                </Descriptions>
            </Row>
        </Modal>
        
        {/* 추 가 / 등 록 */}
        <Modal
            width={1200}
            visible={WModalonoff}
            title={ModalTitle}
            onOk={form.submit}
            onCancel={handleCancel}
            footer={[
                <DeleteButton isAdmin={userClass} onDelete={handleDelete} deleteKey={SelectLowData._id} />,
                <Button key="back" onClick={handleCancel}>닫 기</Button>,
                <Button key="submit" type="primary" onClick={form.submit} style={{width:150}}>저 장</Button>
            ]}
            >
            {/* <p>모달테스트</p> */}
            <Row justify='center'>
                <Form form={form} onFinish={handleSubmit} style={{width:'100%'}} labelCol={{span:8}}>
                <Card style={{padding:0, width:'100%',borderRadius:5, borderColor:'#999999'}}>
                    <Row>
                        <Col span={8}>
                            <Form.Item label="가맹점코드"
                                   name="code"
                                   rules={[{ required: true, message: '가맹점 구분코드가 반드시 필요합니다'}]}>
                            <span>
                            <Input onChange={(e) => inputHandler('code', e)}
                                   defaultValue={form.setFieldsValue({ code : SelectLowData.code })}
                                   value={SelectLowData.code}
                                   maxlength={4}
                                   placeholder='4자리 코드'
                                   allowClear
                                   style={{width:150}} />
                                   <Button
                                        onClick = {(e) => codeCheck(form.getFieldValue('code'))}
                                        type="primary"
                                        disabled={false}
                                        // disabled={inputOnOff}
                                        >중복확인</Button></span>                                       
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="상 호"
                                   name="shopName"
                                   rules={[{ required: true, message: '상호명이 반드시 필요합니다'}]}>
                            <Input onBlur={(e) => inputHandler('shopName', e)}
                                   defaultValue={form.setFieldsValue({ shopName : SelectLowData.shopName })}
                                   value={SelectLowData.shopName}
                                   style={{ width: '100%' }} />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="별칭"
                                   name="nickName"
                                   rules={[{ required: true, message: '별칭이 반드시 필요합니다'}]}>
                            <Input onBlur={(e) => inputHandler('nickName', e)}
                                   defaultValue={form.setFieldsValue({ nickName : SelectLowData.nickName })}
                                   value={SelectLowData.nickName}
                                   style={{ width: '100%' }} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={8}>
                            <Form.Item label="가맹점구분"
                                       name="category"
                                       rules={[{ required: true, message: '가맹점 구분을 선택해주세요.'}]}>
                                <Select
                                    placeholder="가맹점구분"
                                    style={{width:120}}
                                    onSelect={(e) => selectHandler_modal('category', e)}
                                    onClear={() => clearHandler_modal('category') }
                                    defaultValue={form.setFieldsValue({ category : SelectLowData.category })}
                                    value={SelectLowData.category}
                                    allowClear>
                                        {renderOption(option_category)}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="그룹분류"
                                       name="groupName"
                                       rules={[{ required: true, message: '그룹분류를 선택해주세요.'}]}>
                                <Select
                                    placeholder="그룹분류"
                                    style={{width:120}}
                                    onSelect={(e) => selectHandler_modal('groupName', e)}
                                    onClear={() => clearHandler_modal('groupName') }
                                    defaultValue={form.setFieldsValue({ groupName : SelectLowData.groupName })}
                                    value={SelectLowData.groupName}
                                    allowClear>
                                        {renderOption(GroupData)}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="회원상태"
                                       name="orNotUse"
                                       rules={[{ required: true, message: '회원상태를 선택해주세요.'}]}>
                                <Select placeholder="회원상태" 
                                        style={{width:120}}
                                        onSelect={(e) => selectHandler_modal('orNotUse', e)}
                                        onClear={() => clearHandler_modal('orNotUse') }
                                        defaultValue={form.setFieldsValue({ orNotUse : SelectLowData.orNotUse })}
                                        value={SelectLowData.orNotUse}
                                        allowClear>
                                        {renderOption(option_orNotUse2)}</Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </Card>
                <Card style={{ maxHeight: 400, 
                           overflow: "auto", 
                           borderColor:'white'}}>
                    <Row>
                        <Col span={8}>
                            <Form.Item label="사업자번호"
                                       name="businessNumber">
                                <Input onBlur={(e) => inputHandler('businessNumber', e)}
                                       defaultValue={form.setFieldsValue({ businessNumber : SelectLowData.businessNumber })}
                                       value= {SelectLowData.businessNumber}
                                       disabled={false} />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="대표자"
                                       name="CEO">
                                <Input onBlur={(e) => inputHandler('CEO', e)}
                                       defaultValue={form.setFieldsValue({ CEO : SelectLowData.CEO })}
                                       value= {SelectLowData.CEO}
                                       disabled={false} />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="팩스번호"
                                       name="FAX">
                                <Input onBlur={(e) => inputHandler('FAX', e)}
                                       defaultValue={form.setFieldsValue({ FAX : SelectLowData.FAX })}
                                       value= {SelectLowData.FAX}
                                       disabled={false} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={8}>
                            <Form.Item label="우편번호"
                                       name="zipcode">
                                <Input onBlur={(e) => inputHandler('zipcode', e)}
                                       style={{width:120}}
                                       defaultValue={form.setFieldsValue({ zipcode : SelectLowData.zipcode })}
                                       value= {SelectLowData.zipcode}
                                       disabled={false} />
                                <Button type='primary'
                                       onClick={ (e)=> openZipCode(e) }>
                                       우편번호검색</Button>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="주소"
                                       name="address1">
                                <Input onBlur={(e) => inputHandler('address1', e)}
                                       defaultValue={form.setFieldsValue({ address1 : SelectLowData.address1 })}
                                       value= {SelectLowData.address1}
                                       disabled={false} />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="상세주소"
                                       name="address2">
                                <Input onBlur={(e) => inputHandler('address2', e)}
                                       defaultValue={form.setFieldsValue({ address2 : SelectLowData.address2 })}
                                       value= {SelectLowData.address2}
                                       disabled={false} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={8}>
                            <Form.Item label="업태"
                                       name="businessCondition">
                                <Input onBlur={(e) => inputHandler('businessCondition', e)}
                                       defaultValue={form.setFieldsValue({ businessCondition : SelectLowData.businessCondition })}
                                       value= {SelectLowData.businessCondition}
                                       disabled={false} />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="종목"
                                       name="businessType">
                                <Input onBlur={(e) => inputHandler('businessType', e)}
                                       defaultValue={form.setFieldsValue({ businessType : SelectLowData.businessType })}
                                       value= {SelectLowData.businessType}
                                       disabled={false} />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="전화번호"
                                       name="telNumber">
                                <Input onBlur={(e) => inputHandler('telNumber', e)}
                                       defaultValue={form.setFieldsValue({ telNumber : SelectLowData.telNumber })}
                                       value= {SelectLowData.telNumber}
                                       disabled={false} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={8}>
                            <Form.Item label="휴대전화"
                                       name="mobile">
                                <Input onBlur={(e) => inputHandler('mobile', e)}
                                       defaultValue={form.setFieldsValue({ mobile : SelectLowData.mobile })}
                                       value= {SelectLowData.mobile}
                                       disabled={false} />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="담당자"
                                       name="contactMan">
                                <Input onBlur={(e) => inputHandler('contactMan', e)}
                                       defaultValue={form.setFieldsValue({ contactMan : SelectLowData.contactMan })}
                                       value= {SelectLowData.contactMan}
                                       disabled={false} />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="담당자연락처"
                                       name="contactPhone">
                                <Input onBlur={(e) => inputHandler('contactPhone', e)}
                                       defaultValue={form.setFieldsValue({ contactPhone : SelectLowData.contactPhone })}
                                       value= {SelectLowData.contactPhone}
                                       disabled={false} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={8}>
                            <Form.Item label="정보공개서 No"
                                       name="infoDocument">
                                <Input onBlur={(e) => inputHandler('infoDocument', e)}
                                       defaultValue={form.setFieldsValue({ infoDocument : SelectLowData.infoDocument })}
                                       value= {SelectLowData.infoDocument}
                                       disabled={false} />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="가맹계약서 No"
                                       name="franchiseeAgreement">
                                <Input onBlur={(e) => inputHandler('franchiseeAgreement', e)}
                                       defaultValue={form.setFieldsValue({ franchiseeAgreement : SelectLowData.franchiseeAgreement })}
                                       value= {SelectLowData.franchiseeAgreement}
                                       disabled={false} />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="이메일"
                                       name="email">
                                <Input onBlur={(e) => inputHandler('email', e)}
                                       defaultValue={form.setFieldsValue({ email : SelectLowData.email })}
                                       value= {SelectLowData.email}
                                       disabled={false} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={8}>
                            <Form.Item label="면적"
                                       name="area">
                                <Input onBlur={(e) => inputHandler('area', e)}
                                       defaultValue={form.setFieldsValue({ area : SelectLowData.area })}
                                       value= {SelectLowData.area}
                                       disabled={false} />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="양수도"
                                       name="transferContract">
                                <Input onBlur={(e) => inputHandler('transferContract', e)}
                                       defaultValue={form.setFieldsValue({ transferContract : SelectLowData.transferContract })}
                                       value= {SelectLowData.transferContract}
                                       disabled={false} />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={8}>
                            <Form.Item label="개업년월일"
                                       name="openDate">
                                <InputMask mask='9999-99-99' maskChar='_' style={{'border': '1px solid #cccccc', height:30, padding:10}}
                                   defaultValue={form.setFieldsValue({ openDate : SelectLowData.openDate })}
                                   value={SelectLowData.openDate } />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="계약체결일"
                                       name="agreementDate">
                                <InputMask mask='9999-99-99' maskChar='_' style={{'border': '1px solid #cccccc', height:30, padding:10}}
                                   defaultValue={form.setFieldsValue({ agreementDate : SelectLowData.agreementDate })}
                                   value={SelectLowData.agreementDate } />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="거래종료일"
                                       name="closeDate">
                                <InputMask mask='9999-99-99' maskChar='_' style={{'border': '1px solid #cccccc', height:30, padding:10}}
                                   defaultValue={form.setFieldsValue({ closeDate : SelectLowData.closeDate })}
                                   value={SelectLowData.closeDate } />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={8}>
                            <Form.Item label="정보공개서일자"
                                       name="infoDocDate">
                                <InputMask mask='9999-99-99' maskChar='_' style={{'border': '1px solid #cccccc', height:30, padding:10}}
                                   defaultValue={form.setFieldsValue({ infoDocDate : SelectLowData.infoDocDate })}
                                   value={SelectLowData.infoDocDate } />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="가맹계약체결일자"
                                       name="contractDate">
                                <InputMask mask='9999-99-99' maskChar='_' style={{'border': '1px solid #cccccc', height:30, padding:10}}
                                   defaultValue={form.setFieldsValue({ contractDate : SelectLowData.contractDate })}
                                   value={SelectLowData.contractDate } />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="가맹계약종료일자"
                                       name="terminateContractDate">
                                <InputMask mask='9999-99-99' maskChar='_' style={{'border': '1px solid #cccccc', height:30, padding:10}}
                                   defaultValue={form.setFieldsValue({ terminateContractDate : SelectLowData.terminateContractDate })}
                                   value={SelectLowData.terminateContractDate } />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row style={{width:'100%'}}>
                        <Col span={24}>
                            <Form.Item label="관리메모"
                                       name="managementMemo">
                                <TextArea style={{width:'100%'}}
                                    onBlur={(e) => inputHandler('managementMemo', e)}
                                    rows={5}
                                    defaultValue={form.setFieldsValue({ managementMemo : SelectLowData.managementMemo })}
                                    value= {SelectLowData.managementMemo}
                                    disabled={false} />
                            </Form.Item>
                        </Col>
                    </Row>
                </Card>
                </Form>
            </Row>
            
            {isOpenPost  ? (
                <Modal 
                    visible={isOpenPost}
                    width={200}
                    onCancel={postModalCancel}>
                    <DaumPostcode 
                        style={postCodeStyle} 
                        autoClose 
                        onComplete={onCompletePost} 
                        {...props} />
                    </Modal>
                ) : null}
            
        </Modal>
       
   </div>
   </div>
   ) 
}
export default Franchisee;