import React, { useEffect } from 'react'
import {Select } from 'antd';

const { Option } = Select;

function Selectbox(props) {

    let select_option;

    useEffect(() => {
        select_option=props.focus_list;
      }, [props.focus_list])

    const optionHandler = (event) => {
        props.refreshFunction(event);
    }

    const renderOption = () => (
        props.list && props.list.map(value => (
            <Option key={value._id} value={value.data}> {value.name} </Option>
        ))
    )

    return (
        <div>
                        <Select style={props.style} defaultValue={props.focus_list} onSelect= {optionHandler}>
                            {renderOption()}
                        </Select>

        </div>
    )
}
export default Selectbox
