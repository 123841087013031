import React, { useEffect, useState } from 'react'
import { Row, Col, Table, Button, notification, Typography, Space, Modal, Input, Select, DatePicker, Form } from 'antd';
import moment from 'moment';
import { apiServer } from '../../utils/custom-axios';
import 'moment/locale/ko';
import {     
    columns_partners,
    continents_pagination,
    option_orNotUse2,
    option_category,
    option_businessClass,
    option_country,
    columns_sales,
   } from '../.././common/datas/Datas';
import SelectBox from '../.././common/SelectBox';
import DownloadExcel from '../.././common/DownloadExcel';
import { useLocation } from 'react-router-dom';
import 'moment/locale/ko';
import locale from 'antd/es/date-picker/locale/ko_KR';

const { Search } = Input;
const { TextArea } = Input;
const { Option } = Select;
const { MonthPicker, RangePicker } = DatePicker;
const dateFormat = 'YYYY-MM-DD';
const { Title } = Typography;

function Sales(props) {

    const [form] = Form.useForm();

    const [Division, setDivision] = useState('');

    const [Data, setData] = useState([]);
    const [Codes, setCodes] = useState([]);
    const [SelectLowData, setSelectLowData] = useState({});
    const [SelectIndex, setSelectIndex] =  useState();
    const [PageSize, setPageSize] = useState(10);
    const [Modalonoff, setModalonoff] = useState(false);
    const [WModalonoff, setWModalonoff] = useState(false);
    const [ModalMode, setModalMode] = useState('읽기');
    const [filename, setFilename] = useState('');
    const [GroupData, setGroupData] = useState([]);
    const [Searchtext, setSearchtext] = useState({});
    const [ModalTitle, setModalTitle] = useState('');

    const [address, setAddress] = useState(''); // 주소
    const [addressDetail, setAddressDetail] = useState(''); // 상세주소
    const [isOpenPost, setIsOpenPost] = useState(false);
    const [Dates,setDates] = useState([]);
    const [selectedDelKeys, setSelectedDelKeys] = useState({});

    const [OldClose_date,setOldClose_date] = useState('');
    const [Totalgndco, setTotalgndco] = useState(0);
    const [Totalfranchisee, setTotalfranchisee] = useState(0);

    // searh params parse
    let location = useLocation();
   
    let division = sessionStorage.getItem('division');
    let ssid = sessionStorage.getItem('SSID');
    let userid = sessionStorage.getItem('id');
    let userClass = sessionStorage.getItem('userClass');
    let divisionId = sessionStorage.getItem('divisionId');

    useEffect(() => {
        getDate();
        getDeadline();
        setFilename('매출');
        setDivision(division);
    }, [])

    const getDeadline = async () => {

        // 세션이 있을때만 가져오도록 한다.
        if(ssid) {
            let data = await apiServer.get('/deadline').then (response => response.data);
            // console.log('deadline date',data.deadline);
            setOldClose_date(data.deadline);
        };
     };
     const caculrate = (datas) => {
        
        console.log('caculratedata',datas)
        let _totalfranchisee = 0;
        let _totalgndco = 0;
        datas && datas.map((data,index) => {
            _totalfranchisee = _totalfranchisee + Number(data['franchiseePrice']);
            _totalgndco = _totalgndco + Number(data['gndcoPrice']);
        })
        setTotalfranchisee(_totalfranchisee);
        setTotalgndco(_totalgndco);
    }
    
    const getDate = async () => {
        let cdata = await apiServer.get('/deadline').then (response => response.data.deadline);
        // console.log('cdata',new Date(cdata));
        let startdate = moment(new Date(cdata));
        let enddate = moment();
        let dates = [startdate,enddate];
        setDates(dates);
        // console.log('dates',dates);
    }

    const runSerch = () => {
        // setTotalgndco(0);
        // setTotalfranchisee(0);
        getSalesData(1);
    }

    const runSearch = (value) => {
        getSalesData(2, value);
    }

    const getSalesData = async ( getmode = 0, value = '') => {

        let data = await apiServer.get('/sales',{ params : {'startDate' : Dates[0].format('YYYY-MM-DD') ,'endDate' : Dates[1].format('YYYY-MM-DD'), 'divisionId' : divisionId, 'q' : value }}).then (response => response.data)

        if(data.msg === 'OK') {
            if(value !== '') value = '검색어 ' + value + '에 대한';
            openNotificationWithIcon('success', '매출조회 완료', value + '매출조회가 완료되었습니다.', 'topRight');
            setData(data.sales);
            caculrate(data.sales);
        }
        else if(data.msg === 'Failed') {
            setData([]);

            if(getmode !== 0) {
                Modal.info({
                    title : '입력된 데이터가 없습니다.',
                    content : (
                        <><br />
                          기간 혹은 검색어에 맞게 입력된 데이터가 조회되지 않습니다.<br />
                          기간이나 검색어를 다시 확인해주세요.
                        </>
                    ),
                    onOk() {},
                });
            }
        }
    }

    const UpdatePagination = (option) =>{

        setPageSize(option);
    }
    const clickselectRow = (record ,index) => {
        setSelectLowData(record);
        setSelectIndex(index);
        //console.log('record',record);
        if(record.closeCheck !== 'Y')
            showModal();
        else {
            openNotificationWithIcon('error', '매출 수정/삭제 불가', '마감이 완료된 데이터는 조회만 가능하며, 수정, 삭제 등은 불가능합니다.', 'topCenter');
        }
    }

    const  showModal = () => {
       
        if (userClass === '관리자'){
            setWModalonoff(true);
            setModalMode('수정');
            setModalTitle('매출 수정');
        }
        if (userClass === '일반사용자'){
            setModalonoff(true);
            setModalMode('읽기');
        }
    };

    const  handleCancel = () => {
        setModalonoff(false);
        setWModalonoff(false);
        if(ModalMode === '읽기' ){
           // count_plus();
        }
        // form.resetFields();
    };

    const  handleOk = () => {
        if (ModalMode === '수정'){
            update_data();
            
            }
        setModalonoff(false);
        setWModalonoff(false);
        // count_plus();
        
    };

    const update_data = async () => {
        
        let data = Data;
        let record = SelectLowData;
        // const index = SelectIndex;
        // console.log('record', record);

        record.updateWriter = userid;

        let cdata = await apiServer.put(`/sales/${record._id}`,{record}).then ( res => res.data);
        // console.log('cdata',cdata,userid); 
        if(cdata == "OK") {
            openNotificationWithIcon('success', '매출 등록 수정', '기존 매출 데이터를 수정하였습니다.', 'topRight');
            // record = {};
            setSelectLowData({});
            getSalesData();
        }
        else {
            openNotificationWithIcon('error', '매출 수정 실패', '기존 매출 데이터를 수정하는데 실패하였습니다.');
        }
    }

    const inputHandler = (name,event) => {

        let record = SelectLowData;

        if(event.currentTarget.value === ''){
            record[name] = event.currentTarget.value;
        } 
        else { 
            record[name] = event.currentTarget.value;
        }

        setSelectLowData({...record});
    }

    const selectHandler = (name,event) => {
       let body = Searchtext;
    
        body[name] = event;
        setSearchtext(body);
    }

    const selectHandler_modal = (name,event) => {
       
        let record = SelectLowData;

        record[name] = event;

        setSelectLowData({...record});
    }

    const datepickerhandler = (name,_date,event) =>{
        let record = SelectLowData;

        record[name] = event;
        setSelectLowData({...record});
    }

    const rangedatepickerhandler = (name,_date,event) =>{
        setDates(_date);
    }

    const optionRander = (name) =>{
        // console.log('optionname',name);
    }

    const renderOption = (selectoption) => (
        selectoption && selectoption.map((value,index) => (
            <Option key={value._id} value={value.name} label={value.name} > {value.name} </Option>
        ))
    )

    const clearHandler = ( name ) => {
        let body = Searchtext;
        body[name] = '';
        setSearchtext(body);
        // console.log('name : ', name, body[name])
    }

    const clearHandler_modal = ( name ) => {
        let record = SelectLowData;
        // console.log('event',name);
        record[name] = '';

        setSelectLowData({...record});
    }

    const openNotificationWithIcon = (type, title, message, placement = null ) => {
        notification[type]({
          message: title,
          description: message,
          placement,
        });
    };
     
    const DeleteAll = async () => {
        // console.log(selectedDelKeys);
        let cdata = await apiServer.delete(`/salesall`,{data : {selectedDelKeys}}).then ( res => res.data);

        if(cdata.msg === "OK") {
            openNotificationWithIcon('success', '체크 후 삭제 성공', `선택된 매출자료 ${cdata.recCount}개를 삭제처리 하였습니다.`);
            setSelectLowData({});
            setSelectedDelKeys({});
            getSalesData(1);
        }
        else {
            openNotificationWithIcon('error', '체크 후 삭제 실패', '선택된 삭제 작업에 실패했습니다.');
        }
    }

    const changeselectlist = (selectedRowKeys, selectedRows) => {
        setSelectedDelKeys(selectedRowKeys);
    }

    const onSelectSearch = (val) => {
        let codes = Codes;
        let cdata = codes && codes.filter(it => it.nickName.includes(val))

        OptionRander_franchisee (cdata);
    }

    const onSelectChange = (val) => {
        let franchiseeCode = val;
        let record = SelectLowData;
        let codes = Codes;
        let cdata = codes && codes.filter(code => code.code.includes(val))
        record.nickName = cdata[0].nickName;
        record.franchiseeCode = franchiseeCode;
        setSelectLowData({...record});
    }

    const OptionRander_franchisee = (codes) => (
        codes && codes.map((value,index) => (
            <Option key={value.code} value={value.code} label={value.nickName} > {value.nickName} </Option>
        ))
    )

    const DownloadUrl = async () => {
        if(SelectLowData.keySearch == null) {
            let record = SelectLowData;
            record.keySearch = '';
            setSelectLowData({...record})
        }

        let response = await apiServer.get('/sales', {  responseType:'blob', 
            params : {  'type': 'xlsx', 
                        'divisionId' : divisionId, 
                        'startDate' : Dates[0].format('YYYY-MM-DD') ,
                        'endDate' : Dates[1].format('YYYY-MM-DD'),
                        'q' : SelectLowData.keySearch
                     } } );

        if(response.data.size < 800) {
            openNotificationWithIcon('error', '청구서 엑셀 다운로드', '검색조건에 맞는 등록된 청구서가 없어서 엑셀 파일로 다운로드 받기를 할 수 없습니다.');
        }
        else {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", '매출처별(' + Dates[0].format('YYYY-MM-DD') + ' - ' + Dates[1].format('YYYY-MM-DD') + ').xlsx');
            link.style.cssText = "display:none";
            document.body.appendChild(link);
            link.click();
            link.remove();
        }
    }
    

   return(
    <div style={{ width: '100%' }}>
    <div style={{ textAlign: 'center' }}>
    <img src="images/title_head_partners_salessearch_01.png" /><br /><br />
        {/* 테이블 상단 검색 및 관련 메뉴버튼들 */}
        <Row justify='center'>
          <table style={{width:800, borderColor:'#EAEAEA'}} border="1">
              <tr style={{'background-color': '#40a9ff', height: 30, color: 'white', fontStyle:'normal' }}>
                <td width="25%">마감일</td>
                <td width="25%">건수 </td>
                <td width="25%">가맹점공급금액 합계</td>
                <td width="25%">본사공급금액 합계</td>
              </tr>
              <tr style={{color: 'blue'}}>
                <td>{OldClose_date}</td>
                <td>{Data.length} 건</td>
                <td>{Totalfranchisee.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} 원</td>
                <td>{Totalgndco.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} 원</td>
              </tr>
          </table>
        </Row>
        <Row>
            &nbsp;
        </Row>
        <Row justify='center'>
            <Space size={[180,10]} wrap>
                {userClass === '관리자' ? <Button type='primary' onClick ={DeleteAll}>체크 후 삭제</Button> : null}
                <span>
                <RangePicker
                    value = {Dates}
                    defaultValue={Dates} 
                    format={dateFormat}
                    locale={locale}
                    onChange={(e,f) => rangedatepickerhandler('openDate,endDate',e,f)} />
                <Button 
                    type="primary"
                    onClick = {value => runSerch(value)}>검 색</Button>
                </span>
                <Search style={{width: 280}}
                    allowClear
                    placeholder="가맹점 별칭 검색..."
                    enterButton="검색"
                    onChange={(e) => inputHandler('keySearch', e)}
                    onSearch={value => runSearch(value)} />
                {/* <DownloadExcel dataSource={Data} columns={columns_sales} filename={filename} />  */}
                <Button onClick={() => DownloadUrl()} type='primary'>Excel파일로 다운</Button>
                <SelectBox
                    list = {continents_pagination}
                    focus_list = {PageSize}
                    refreshFunction={UpdatePagination}>
                </SelectBox>
            </Space>
        </Row>
        <br />
        <Row justify='center'>
        <Table  style={{cursor : 'pointer', width:1800}}
            dataSource={Data} 
            pagination={{ pageSize: PageSize, position: ['bottomCenter'] }}
            columns={columns_sales} 
            rowSelection={{
                type: 'checkbox',
                onChange: (selectedRowKeys, selectedRows) => {
                    setSelectedDelKeys(selectedRowKeys);
                    changeselectlist(selectedRowKeys, selectedRows);
              },
              }}
            // pagination = {10}
            rowKey={Data => Data._id}
            onRow={(record,index) => ({
                onClick: () => {
                  clickselectRow(record,index)
                },})}
            scroll={{ x: 1000 }} size="small">
        </Table>
        </Row>

        <Modal
            width={'70%'}
            visible={Modalonoff}
            title={SelectLowData.title}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={[
                <Button key="back" onClick={handleCancel}>
                닫기
                </Button>,
            ]} >
            <p>모달테스트</p>

            <Row>
                <Col span={4}> 등록일</Col>
                <Col span={20}> 
                <Input 
                onChange={(e) => inputHandler('writeDate', e)}
                value= {SelectLowData.writeDate}
                disabled={true}
                >
                </Input>
               </Col>
            </Row>
            <Row>
                <Col span={4}> 작성자</Col>
                <Col span={20}> 
                <Input 
                onChange={(e) => inputHandler('writer', e)}
                value= {SelectLowData.writer}
                disabled={true}
                >
                </Input>
                </Col>
            </Row>
            <Row>
                <Col span={4}> 카테고리</Col>
                <Col span={20}>
                <Input 
                onChange={(e) => inputHandler('titleHead', e)}
                value= {SelectLowData.titleHead}
                disabled={true}
                >
                </Input>
                </Col>
            </Row>
            {/* <Row>
                <Col span={4}> 공지제목</Col>
                <Col span={20}> {SelectLowData.title}</Col>
            </Row> */}
            <Row>
                <Col span={4}> 첨부파일1</Col>
                <Col span={16}> 
                <Input 
                onChange={(e) => inputHandler('attchFile1', e)}
                value= {SelectLowData.attchFile1}
                disabled={true}
                >
                </Input>
                </Col>
                <Col span={4}> <Button>다운로드</Button></Col>
            </Row>
            <Row>
                <Col span={4}> 첨부파일2</Col>
                <Col span={16}> 
                <Input 
                onChange={(e) => inputHandler('attchFile2', e)}
                value= {SelectLowData.attchFile2}
                disabled={true}
                >
                </Input>
                </Col>
                <Col span={4}> <Button>다운로드</Button></Col>
            </Row>
            <Row>
                <Col span={24}>
                <TextArea onChange={(e) => inputHandler('bodyText', e)}
                   value={SelectLowData.bodyText}
                   rows={10}
                   disabled={true}
                   >
                 </TextArea> 
                </Col>
            </Row>
        </Modal>

        {/* 추 가 / 등 록 */}
        <Modal
            width={1000}
            visible={WModalonoff}
            title={ModalTitle}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={[
                        // <DeleteButton isAdmin={userClass} onDelete={handleDelete} deleteKey={SelectLowData._id} />,
                        <Button key="back" onClick={handleCancel}>닫 기</Button>,
                        <Button key="submit" type="primary" onClick={handleOk} style={{width:150}}>저 장</Button>
                   ]} >
              <Space direction="vertical" size="small" style={{width:'100%'}}>
                <Row>
                    <Col span={3}>매출일자</Col>
                    <Col span={21}><DatePicker 
                    defaultValue={ SelectLowData.salesDate ? moment(SelectLowData.salesDate, dateFormat) : moment(moment(), dateFormat)} 
                    value = { SelectLowData.salesDate ? moment(SelectLowData.salesDate, dateFormat) : moment(moment(), dateFormat)} 
                    format={dateFormat}
                    locale={locale}
                    onChange={(e,f) => datepickerhandler('salesDate',e,f)}

                    /></Col>
                </Row>
                <Row>
                    <Col span={3}>가맹점별칭</Col>
                    <Col span={21}>
                <Select
                    showSearch
                    placeholder="가맹점별칭"
                    optionFilterProp="children"
                    style={{ width: '100%' }}
                    // size="large"
                    onSelect={(e) => selectHandler('nickName', e)}
                    onClear={() => clearHandler('nickName') }
                    allowClear
                    onChange={onSelectChange}
                    onSearch={onSelectSearch}
                    value = {SelectLowData.nickName}>
                        {OptionRander_franchisee(Codes)}
                </Select>
                </Col>
                </Row>
                <Row>
                    <Col span={3}>가맹점코드</Col>
                    <Col span={21}><Input 
                    onChange={(e) => inputHandler('franchiseeCode', e)}
                    value= {SelectLowData.franchiseeCode}
                    disabled={true}
                    />
                    </Col>
                </Row>
                <Row>
                    <Col span={3}>브랜드</Col>
                    <Col span={21}><Input
                    onChange={(e) => inputHandler('brand', e)}
                    value= {SelectLowData.brand}
                    disabled={false}
                     /></Col>
                </Row>
                <Row>
                    <Col span={3}>상품명</Col>
                    <Col span={21}><Input
                    onChange={(e) => inputHandler('goodsName', e)}
                    value= {SelectLowData.goodsName}
                    disabled={false}
                     /></Col>
                </Row>
                <Row>
                    <Col span={3}>수량</Col>
                    <Col span={21}><Input
                    type={'number'}
                    onChange={(e) => inputHandler('quantity', e)}
                    value= {SelectLowData.quantity}
                    disabled={false}
                     /></Col>
                </Row>
                <Row>
                    <Col span={3}>가맹점공급단가</Col>
                    <Col span={21}><Input
                    type={'number'}
                    onChange={(e) => inputHandler('franchiseeUnitprice', e)}
                    value= {SelectLowData.franchiseeUnitprice}
                    disabled={false}
                     /></Col>
                </Row>
                <Row>
                    <Col span={3}>본사공급단가</Col>
                    <Col span={21}><Input
                    type={'number'}
                    onChange={(e) => inputHandler('gndcoUnitprice', e)}
                    value= {SelectLowData.gndcoUnitprice}
                    disabled={false}
                     /></Col>
                </Row>
                <Row>
                    <Col span={3}>가맹점공급금액</Col>
                    <Col span={21}><Input
                    type={'number'}
                    // onChange={(e) => inputHandler('franchiseeprice', e)}
                    value= {SelectLowData.franchiseePrice}
                    disabled={true}
                     /></Col>
                </Row>
                <Row>
                    <Col span={3}>본사공급금액</Col>
                    <Col span={21}><Input
                    type={'number'}
                    // onChange={(e) => inputHandler('gndcoprice', e)}
                    value = {SelectLowData.gndcoPrice}
                    disabled={true}
                     /></Col>
                </Row>
                <Row>
                    <Col span={3}>본사할인율</Col>
                    <Col span={21}><Input
                    type={'number'}
                    // onChange={(e) => inputHandler('gndcoDiscountrate', e)}
                    value = {SelectLowData.gndcoDiscountrate}
                    disabled={true}
                     /></Col>
                </Row>
                <Row>
                    <Col span={3}>비고</Col>
                    <Col span={21}><Input
                    type={'textarea'}
                    onChange={(e) => inputHandler('memo', e)}
                    value= {SelectLowData.memo}
                    disabled={false}
                     /></Col>
                </Row>
              </Space>
        </Modal>
   </div>
   </div>
   ) 
}
export default Sales;