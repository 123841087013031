import {
    LOGIN_USER,
    LOGOUT_USER,
    AUTH_USER
} from '../_actions/types';

export default function (state = {}, action) {
    
    switch (action.type) {
        case LOGIN_USER :
            return { ...state, loginInfo: action.payload }
            break;

        case LOGOUT_USER :
            return { ...state, userInfo: action.payload }
            break;

        case AUTH_USER :
            return { ...state, userInfo: action.payload }
            break;
    
        default:
            return state;
            break;
    }
}