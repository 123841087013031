import React, { useState } from "react";
// import "./App.css";
import * as XLSX from "xlsx";
import { Drawer, Button, Icon, Col, Row, Menu, Layout,Input } from 'antd';

class ExcelToJson extends React.Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.state = {
      file: "",
    };

    //console.log(props)
  }

  handleClick(e) {
    this.refs.fileUploader.click();
  }

  filePathset(e) {
    // e.stopPropagation();
    // e.preventDefault();
    var file = e.target.files[0];
    // console.log(file);
    this.setState({ file });

    // console.log(this.state.file);
  }

  readFile() {
    var f = this.state.file;
    var name = f.name;
    const reader = new FileReader();
    reader.onload = (evt) => {
      // evt = on_file_select event
      /* Parse data */
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: "binary" });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      // const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
      const data = XLSX.utils.sheet_to_json(ws);
      
      console.log(data);
      /* Update state */
      // console.log("Data>>>" + data);// shows that excel data is read
      // console.log(this.convertToJson(data)); // shows data in json format
      // this.props.refreshFunction(this.convertToJson(data));
      // this.props.refreshFunction(JSON.stringify(data));
      this.props.refreshFunction(data);
    };
    reader.readAsBinaryString(f);
  }

  convertToJson(csv) {
    var lines = csv.split("\n");

    var result = [];

    var headers = lines[0].split(",");

    for (var i = 1; i < lines.length; i++) {
      var obj = {};
      var currentline = lines[i].split(",");

      for (var j = 0; j < headers.length; j++) {
        obj[headers[j]] = currentline[j];
      }

      result.push(obj);
    }

    //return result; //JavaScript object
    // return JSON.stringify(result); //JSON
    return (result); //JSON
  }

  initTable(e) {
    e.stopPropagation();
    e.preventDefault();

    this.props.setData([]);
    this.filePathset(e)
  }

  loadingToggle()  {

    //console.log(this.state.file.length)

    if(this.state.file.length === 0) {
      alert('파일이 선택되지 않았습니다. 파일을 선택하고 다시 시도해주세요.');
    }
    else {
      this.props.setLoading(true);
      this.readFile(this);
    }
  }

  render() {
    return (
      <div>
        <Input
          style={{width:400}}          
          type="file"
          id="file"
          ref="fileUploader"
          onChange={this.initTable.bind(this)}
        />
        <Button
          type="primary"
          onClick={() => {this.loadingToggle(this)}}
        >
          선택파일적용
        </Button >
      </div>
    );
  }
}

export default ExcelToJson;