import {
    LOGIN_USER,
    LOGOUT_USER,
    AUTH_USER,
} from "./types";
import { apiServer2 } from "../utils/custom-axios";
import axios from 'axios';
// SSID와 로그인 성공여부만 가져온다.
export const loginUser = (dataToSubmit) => { 

    //console.log(dataToSubmit);
    const request = apiServer2.post('/login', dataToSubmit)
                               .then(res => res.data);

    return {
        type: LOGIN_USER,
        payload: request
    }
}

// SSID를 이용해서 세션을 삭제하고 로그아웃한다.
export const logoutUser = () => {  

    //const SSID = localStorage.getItem('SSID');
    const SSID = sessionStorage.getItem('SSID');
    //console.log(SSID);
    const request = apiServer2.delete('/logout/' + SSID )
                               .then(res => res.data);

    //console.log(request);

    return {
        type: LOGOUT_USER,
        payload: request
    }
}

// 사용자 정보를 가져온다.
export const auth = () => {  

    //const SSID = localStorage.getItem('SSID');
    const SSID = sessionStorage.getItem('SSID');
    //console.log(SSID);
//    console.log('user Action', SSID);
    const request = apiServer2.get('/auth' + '/' + SSID).then(res => res.data);
    //console.log(request);

    return {
        type : AUTH_USER,
        payload: request
    }
}