import React, { useEffect, useState,} from 'react'
import { Row, Col, message, Table,Button, Modal, Input, 
    Select, Card, Descriptions, notification, Space, Form } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
// import ReactQuill from 'react-quill';
// import "react-quill/dist/quill.snow.css";
import Uploads from '../../common/upload/uploads';
import moment from 'moment';
import { apiServer } from '../../utils/custom-axios';
import 'moment/locale/ko';
import {     
    columns_notice,
    columns_notice2,
    continents_pagination,
    option_division,
    option_titleHead,
   } from '../.././common/datas/Datas';
import SelectBox from '../.././common/SelectBox';
import Editbox from '../../common/Editbox';
import DeleteButton from '../../utils/modal-delbutton';

import { useLocation } from 'react-router-dom';

import './Notice.css';

const { Search } = Input;
const { TextArea } = Input;
const { Option } = Select;

function Notice(props) {

    const [Division, setDivision] = useState('');

    const [Data, setData] = useState([]);
    const [SelectLowData, setSelectLowData] = useState({});
    // const [SelectIndex, setSelectIndex] =  useState();
    const [PageSize, setPageSize] = useState(10);
    const [Modalonoff, setModalonoff] = useState(false);
    const [WModalonoff, setWModalonoff] = useState(false);
    const [ModalMode, setModalMode] = useState('읽기');
    const [ModalTitle, setModalTitle] = useState('');
    const [inputOnOff, setInputOnOff] = useState(false);

    const [form] = Form.useForm();

    // searh params parse
    let location = useLocation();
  
    let division = sessionStorage.getItem('division');
    let ssid = sessionStorage.getItem('SSID');
    let userid = sessionStorage.getItem('id');
    let userClass = sessionStorage.getItem('userClass');

    useEffect(() => {
        setDivision(division);
        getNotice();
    },[])

    const runSerch = (value) => {
        getNotice(value, 1);
    }

    const getNotice = async (value, getMode = 0) => {

        if(sessionStorage.getItem('SSID')) {
            let data = await apiServer.get('/notice',{params : { 'search' : value ,'division' : division }}).then (response => response.data)

            if(data.msg !== 'Failed') {
                setData(data);
            }
            else if(data.recCount === 0) {
                setData([]);
                if(getMode !== 0) {
                    Modal.info({
                        title : '검색된 공지사항이 없습니다.',
                        content : (
                            <><br />
                              검색 조건에 맞는 공지사항이 없습니다.
                            </>
                        ),
                        onOk() {},
                    });
                }
            }
        }
    }

    const UpdatePagination = (option) =>{

        setPageSize(option);
    }
    const clickselectRow = (record ,index) => {
        setSelectLowData(record);

        console.log(record)
        showModal();
    }

    const  WriteModal = () => {
        let record = {}
        record.division = '전체';
        record.titleHead = '일반공지';
        record.bodyText = '';
        setSelectLowData(record);
        setModalMode('쓰기');
        setModalTitle('공지사항 작성');
        setWModalonoff(true);
        setInputOnOff(false);
    };

    const  showModal = () => {
        
        if (Division === '본사' && userClass === '관리자') {
            setWModalonoff(true);
            setModalMode('수정');
            setModalTitle('공지사항 수정');
            //setInputOnOff(true);
        }
        if (Division !== '본사' || userClass === '일반사용자') {
            setModalonoff(true);
            setModalMode('읽기');
        }
    };
    const  handleCancel = () => {
        setModalonoff(false);
        setWModalonoff(false);
        if(ModalMode === '읽기' ) {
            count_plus();
        }
        form.resetFields();
    };

    // const  handleOk = () => {
        
    //     let record = SelectLowData;
    //     if (ModalMode === '수정') update_notice();
    //     if (ModalMode === '쓰기') {
    //         if (record.titleHead === '' || record.writer === '' || record.bodyText === '')
    //                                message.info('필수 정보가 없습니다.');
    //         else 
    //                 insert_notice();
    //     }
    //     setModalonoff(false);
    //     setWModalonoff(false);
    // };

    const handleDelete = () => delete_notice();

    const handleSubmit = (values) => {

        values.bodyText = SelectLowData.bodyText;
        let record = values;

        if (ModalMode === '수정') {
            update_notice();
        }
        if (ModalMode === '쓰기') {
            insert_notice();
        }
        setModalonoff(false);
        setWModalonoff(false);
    }

    const insert_notice = async () => {
        
        let record = SelectLowData;
        record.firstWriter = userid;
        record.counter = 1;
        
        let cdata = await apiServer.post('/notice',{record}).then ( res => res.data);

        if(cdata === "OK") {
            openNotificationWithIcon('success', '공지사항 등록', '새로운 공지사항을 등록했습니다.');
            getNotice();
        }
        else if(cdata === 'Failed') {
            openNotificationWithIcon('error', '공지사항 등록실패', '새로운 공지사항을 등록하는데 실패했습니다.');
        }
        else {
            Modal.info({
                title : '에러가 검출 되었습니다.',
                content : (
                    <><br />
                      {cdata}
                    </>
                ),
                onOk() {},
            });
        }
  
    }

    const update_notice = async () => {
        
        let data = Data;
        let record = SelectLowData;
        console.log('record',record.savedFile1, record.savedFile2)

        record.updateWriter = userid;

        let cdata = await apiServer.put(`/notice/${record._id}`,{record}).then ( res => res.data);
        console.log('cdata', cdata)

        if(cdata == "OK") {
            openNotificationWithIcon('success', '공지사항 수정', '기존 공지사항을 수정하였습니다.');
            setSelectLowData({});
            getNotice();
        }
        else if(cdata === 'Failed') {
            openNotificationWithIcon('error', '공지사항 수정실패', '새로운 공지사항을 등록하는데 실패했습니다.');
        }
        else {
            Modal.info({
                title : '에러가 검출 되었습니다.',
                content : (
                    <><br />
                      {cdata}
                    </>
                ),
                onOk() {},
            });
        }
    }

    const delete_notice = async () => {
        let data = Data;
        let record = SelectLowData;
        const id = record._id;
        
        let cdata =  await apiServer.delete(`/notice/${record._id}`,{data:{record}}).then ( res => res.data);
        // axios 에서 delete에 대한 body 값은 data 속성을 붙여야 한다 -_-;;; 젠장
        if(cdata == "OK") {
            openNotificationWithIcon('success', '공지사항 삭제', '선택한 공지사항을 삭제하였습니다.');
            setModalonoff(false);
            setWModalonoff(false);
            setData(data.filter(data => data._id !== id));
            getNotice(); 
        }
    }

    const count_plus = () => {
        let data = Data;
        let record = SelectLowData;

        let cdata = apiServer.put(`/noticecounter/${record._id}`).then ( res => res.data);
        if(cdata == "OK") {
            let _data = data.map((_record) => _record._id === record._id 
            ? {..._record, counter:_record.couter+1} 
            : {..._record});

            getNotice();        
        }
    }

    const inputHandler = (name,event) => {
       
        let record = SelectLowData;

        if(event.currentTarget.value === '') 
            record[name] = event.currentTarget.value;
        else 
            record[name] = event.currentTarget.value;

        setSelectLowData({...record});
    }

    const selectHandler = (name,event) => {
      
        let record = SelectLowData;
        record[name] = event;
        setSelectLowData(record);
    }

    const handleupload = (filenumber, filename) => {

        let record = SelectLowData;

        if(filenumber === '')
            record[filename] = filenumber;
        else
            record[filename] = filenumber;
        setSelectLowData({...record});
    }

    const downloadHandler = (name,e) => {
        console.log('download',name,e)
    }

    const renderOption = (selectoption) => (
        selectoption && selectoption.map((value,index) => (
            <Option key={value._id} value={value.name} label={value.name} > {value.name} </Option>
        ))
    )

    const openNotificationWithIcon = (type, title, message) => {
        notification[type]({
          message: title,
          description: message,
        });
    };
    const changeEditorbox = (value) => {
        let record = SelectLowData;
        record.bodyText = value;
        setSelectLowData({...record});
    }

    const setrowClassname = (record, index) => {
          return null;
    }

    const DownloadUrl = async (wdate, orgfile, savedfile) => {

        let response = await apiServer.get('/downloads', {responseType:'blob', params : {'wdate': wdate, 'orgfile':orgfile, 'savedfile': savedfile}});

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", orgfile);
        link.style.cssText = "display:none";
        document.body.appendChild(link);
        link.click();
        link.remove();
    }

   return(
    <div style={{ width: '100%'}}>
    <div style={{ textAlign: 'center' }}>
    <img src="images/title_head_notice_01.png" width="160" /><br /><br />
        <Row justify='center'>
            <Space size={[200, 10]} wrap>
                {Division === '본사' && userClass === '관리자' ? 
                    <Button type="primary"
                    onClick = {WriteModal}
                     > 공지사항 작성</Button>
                : null}
                <Search style={{width:400}}
                    placeholder="공지종류나 제목으로 검색해주세요."
                    allowClear
                    enterButton="검 색"
                    // size="large"
                    onSearch={value => runSerch(value)} />
                <SelectBox style={{ width: 150 }}
                    list = {continents_pagination}
                    focus_list = {PageSize}
                    refreshFunction={UpdatePagination}></SelectBox>
            </Space>
        </Row>
        <Row justify='center'>
            <Col><p /></Col>
        </Row>
        <Row justify='center'>
            <Table style={{width:1200, cursor : 'pointer'}}
                dataSource={Data} 
                rowClassName={setrowClassname}
                pagination={{ pageSize: PageSize, position: ['bottomCenter'] }}
                columns={Division === '본사' && userClass === '관리자' ? columns_notice : columns_notice2} 
                //pagination={10}
                rowKey={Data => Data._id}
                onRow={(record,index) => ({
                    onClick: () => {
                      clickselectRow(record,index)
                    },})}
                scroll={{ x: 1000 }} size="small" />
        </Row>

        <Modal
            width={800}
            visible={Modalonoff}
            destroyOnClose={true}
            title="공지사항 보기"
            //onOk={handleOk}
            onCancel={handleCancel}
            footer={[
                <Button key="back" onClick={handleCancel}>닫 기</Button>,
            ]}
            >
            {/* <p>모달테스트</p> */}
            <Row justify='center' sytle={{width:'100%'}}>
                <Descriptions title={<span style={{color:'#108EE9'}}>공지사항 제목 : {SelectLowData.title}</span>} bordered column={{xxl:2, xl:2, lg:2, md:2, sm:2, xs:1}} style={{width:'100%'}}>
                    <Descriptions.Item label='등록일'>{SelectLowData.writeDate}</Descriptions.Item>
                    <Descriptions.Item label='작성자'>{SelectLowData.writer}</Descriptions.Item>
                    <Descriptions.Item label='공지사항 분류' span={3}>{SelectLowData.titleHead}</Descriptions.Item>
                    <Descriptions.Item label='첨부파일1'>{SelectLowData.attchFile1} &nbsp; 
                                                            {SelectLowData.attchFile1 ? <Button onClick={() => DownloadUrl(SelectLowData.writeDate, SelectLowData.attchFile1, SelectLowData.savedFile1)} 
                                                                                                 type='primary' icon={<DownloadOutlined />} shape="round">다운받기</Button>:null}
                    </Descriptions.Item>
                    <Descriptions.Item label='첨부파일2'>{SelectLowData.attchFile2} &nbsp; 
                                                            {SelectLowData.attchFile2 ? <Button onClick={() => DownloadUrl(SelectLowData.writeDate, SelectLowData.attchFile2, SelectLowData.savedFile2)} 
                                                                                                 type='primary' icon={<DownloadOutlined />} shape="round">다운받기</Button>:null}
                    </Descriptions.Item>
                </Descriptions>
                <Descriptions bordered column={{xxl:2, xl:2, lg:2, md:2, sm:2, xs:1}} style={{width:'100%'}} layout='vertical'>
                        <Descriptions.Item label='공지내용'><Editbox 
                                                                value={SelectLowData.bodyText}
                                                                readOnly={true}
                                                                refreshFunction={changeEditorbox}>
                                                            </Editbox> 
                        </Descriptions.Item>
                </Descriptions>
            </Row>
        </Modal>
        
        <Modal
            width={800}
            visible={WModalonoff}
            destroyOnClose={true}
            title={ModalTitle}
            onOk={form.submit}
            onCancel={handleCancel}
            footer={[
                <DeleteButton isAdmin={userClass} onDelete={handleDelete} deleteKey={SelectLowData._id} />,
                <Button key="back" onClick={handleCancel}>닫 기</Button> ,
                <Button style={{width:120}} key="submit" type="primary" onClick={form.submit}>저 장</Button>
            ]} >

            <Row justify='center' type="flex">
            <Form form={form} onFinish={handleSubmit} style={{width:'85%'}} labelCol={{span:6}}>
                    <Form.Item label="공지제목"
                               name="title"
                               rules={[{ required: true, message: '공지사항 제목을 입력해주세요'}]}>
                        <Input placeholder="제목을 입력해주세요."
                               onChange={(e) => inputHandler('title', e)}
                               defaultValue={form.setFieldsValue({ title : SelectLowData.title })}
                               value={SelectLowData.title}
                               disabled={inputOnOff} />
                    </Form.Item>
                    <Form.Item label="공지대상 그룹"
                               name="division"
                               rules={[{ required: true, message: '공지 대상 그룹을 정해주세요'}]}>
                        <Select onSelect={(e) => selectHandler('division', e)}
                                defaultValue={form.setFieldsValue({ division : SelectLowData.division })}
                                style={{ width: '100%' }}>
                            {renderOption(option_division)}
                        </Select>
                    </Form.Item>
                    <Form.Item label="작성자"
                               name="writer"
                               rules={[{ required: true, message: '작성자는 반드시 입력하세요'}]}>
                        <Input onChange={(e) => inputHandler('writer', e)}
                               defaultValue={form.setFieldsValue({ writer : SelectLowData.writer })}
                               value={SelectLowData.writer} />
                    </Form.Item>
                    <Form.Item label="공지사항 분류"
                               name="titleHead"
                               rules={[{ required: true, message: '공지사항 분류를 정해주세요'}]}>
                        <Select onSelect={(e) => selectHandler('titleHead', e)}
                                defaultValue={form.setFieldsValue({ titleHead : SelectLowData.titleHead })}
                                style={{ width: '100%' }}>
                            {renderOption(option_titleHead)}
                        </Select>
                    </Form.Item>
                    <Form.Item label="첨부파일 1"
                               name="attchFile1">
                        <Input onChange={(e) => inputHandler('attchFile1', e)}
                               defaultValue={form.setFieldsValue({ attchFile1 : SelectLowData.attchFile1 })}
                               value={SelectLowData.attchFile1}
                               disabled={true}
                               style={{width:350}} />
                        <Uploads name = 'attchFile1' handleupload={handleupload} />
                    </Form.Item>
                    <Form.Item label="첨부파일 2"
                               name="attchFile2">
                        <span>
                        <Input onChange={(e) => inputHandler('attchFile2', e)}
                               defaultValue={form.setFieldsValue({ attchFile2 : SelectLowData.attchFile2 })}
                               value={SelectLowData.attchFile2}
                               disabled={true} 
                               style={{width:350}}/>
                        <Uploads name='attchFile2' handleupload={handleupload} />
                        </span>
                    </Form.Item>
                    <Editbox value={SelectLowData.bodyText}
                             readOnly={false}
                             refreshFunction={changeEditorbox}
                             placeholderCheck={WModalonoff}/>
                </Form>                                
            </Row>                
        </Modal>
   </div>
   </div>
   ) 
}
export default Notice;