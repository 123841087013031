import axios from 'axios';
import { AXIOS_HEADERS, API_URL } from '../config';
import history from './history';
import 'antd/dist/antd.css';
import '../index.css';
import { message, Button } from 'antd';

message.config({
    duration: 5,
});
  
const success = () => {
    message.success({
      content: '서버와의 접속유지시간이 만료되어 접속을 종료합니다. 다시 접속해주세요.',
      className: 'custom-class',
      style: {
        marginTop: '20vh',
        height: '64px',
      },
    });
};

export const apiServer = axios.create({
    baseURL : `${API_URL}`,
    headers : AXIOS_HEADERS,
});

export const apiServer2 = axios.create({
    baseURL : `${API_URL}`,
    headers : AXIOS_HEADERS,
});

// 요청 전에...
apiServer.interceptors.request.use(
    async function (config) {

        let SSID = sessionStorage.getItem('SSID');

        let auth = await apiServer2.get('/auth/' + SSID).then( res => res.data );

    //    console.log('auth',auth)
        if(!auth.isAuth)
        {
            // 실패시에 주소를 변조해서 무조건 실패를 반환하는 api를 가르키게 한다.
            config.url = '/failed';
            success();
            // 메세지를 띄운다.
            sessionStorage.clear();
            localStorage.clear();
            history.replace("/");
        }
        // console.log('history',history)
        return config;
    }
)

// 응답 전에...
apiServer.interceptors.response.use(
    function (res) {
        return res;
    }
)