import React from 'react';
import 'antd/dist/antd.css';
import { Upload, message, Button } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { API_URL } from '../../config';

function Uploads(_props) {

  const name=_props.name;

message.config({
  top: 200,
  duration: 2,
  maxCount: 1,
});

const props = {
  showUploadList:false,
  name: 'file',
  multiple: false,
  action: `${API_URL}/uploads.php`,
  onPreview(file) {
    const { size } = {width:'200', height:'200'}
  },
  onChange(info) {
    const { status } = info.file;
    if (status !== 'uploading') {
      console.log(info.file, info.fileList);
      // _props.handleupload (info.file);
    }
    if (status === 'done') {
      message.success(`${info.file.name} 파일 업로드에 성공하였습니다.`);
      _props.handleupload (info.file.name, name);
    } else if (status === 'error') {
      message.error(`${info.file.name} 파일 업로드 실패.`);
    }
  },
};

  return(
          <Upload {...props}>
            <Button icon={<UploadOutlined />} type='primary'>업로드 파일</Button>
          </Upload>
  );
}

export default Uploads;


// axios.post('http://localhost:5000/api/board', formData, {
//    	header: { 'Content-Type': 'multipart/form-data'}    });