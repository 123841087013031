import React, { useState, useEffect } from 'react';
import './App.css';

import { Route, Routes, Outlet } from "react-router-dom";
import 'antd/dist/antd.css';
import './index.css';
import { Layout, Row } from 'antd';

import Login from './login';
import Auth from './hoc/auth';

import  Notice  from "./view/notice/Notice";
import  Franchisee  from "./view/franchisee/franchisee";
import  Partners  from "./view/partners/partners";
import  Users  from "./view/users/users";
import  Groups  from "./view/groups/groups";
import  Error404  from "./view/404/404";
import  Deadline  from "./view/deadline/deadline";
import  MyInfo from "./view/myinfo/myinfo";

import Salesupload from "./view/salesupload/salesupload";
import Sales from "./view/sales/sales";
import help1 from "./view/help/help1";

import Invoices from "./view/invoices/invoices";
import help2 from "./view/help/help2";

import  Menus from "./common/menu/Menus";
//import  NavBar from "./common/NavBar/NavBar";

const { Header, Footer, Sider, Content } = Layout;

function App(props) {

  const [Division, setDivision] = useState('');
  const [SSID, setSSID] = useState('');
 
  let division = sessionStorage.getItem('division');
  let ssid = sessionStorage.getItem('SSID');
  let l_ssid = sessionStorage.getItem('SSID');

  useEffect(() => {
    setSSID(ssid);
    setDivision(division);
  },[ssid, Division])

  //  division == 본사일때면 true, null
  return (
    <>
      <Routes>
        <Route path="/" element={Auth(screenLayout, true, 'all')}> 
          <Route index element={Auth(atHome, true, 'all')} />
          <Route path="/notice" element={Auth(Notice, true, 'all')} />
          <Route path="/franchisee" element={Auth(Franchisee, true, '본사')} />
          <Route path="/partners" element={Auth(Partners, true, '본사')} />
          <Route path="/deadline" element={Auth(Deadline, true, '본사')} />
          <Route path="/groups" element={Auth(Groups, true, '본사')} />
          <Route path="/users" element={Auth(Users, true, '본사')} />
          <Route path="/myinfo" element={Auth(MyInfo, true, 'all')} />

          <Route path="/salesupload" element={Auth(Salesupload, true, '협력사')} />
          <Route path="/sales" element={Auth(Sales, true, '협력사')} />
          <Route path="/help1" element={Auth(help1, true, '협력사')} />

          <Route path="/invoices" element={Auth(Invoices, true, '가맹점')} />
          <Route path="/help2" element={Auth(help2, true, '가맹점')} />

          <Route path="*" element={Auth(Error404, false, 'all')} />
        </Route>
        <Route path="/login" element={Auth(Login, false, 'all')} />
      </Routes>
    </>
    );
}

export default App;

function screenLayout() {
  let ssid = sessionStorage.getItem('SSID');

  if(ssid === null) {
    return (
      <></>
    )
  }
  else {
    return (
      <Layout style={{background: '#fff'}}>
        
        <Header className="site-layout-background" style={{ position: 'fixed', zIndex: 1, width: '100%', left: 0, padding: 0 }}>      
          <Menus></Menus>
        </Header>

        <Content  style={{ background: '#fff', margin: '0px 16px 0', overflow: 'initial'}}>
          <div className="site-layout-background" style={{  width: '100%', background: '#fff', padding: 24, marginTop: 50 }}>
            <Outlet />          
          </div>
        </Content>

        <Footer style={{ background: '#fff', margin: '24px 16px 0', overflow: 'initial'}}>
        </Footer>
      </Layout>
    );
  }
}

function atHome () {
  return (
    <>
      <div style={{paddingTop:120}}>
        <Row justify='center'>
          <img src="/images/logo.jpg" align="center" valign="middle" />
        </Row>
      </div>
    </>
  )
}
