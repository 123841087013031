import React from 'react';
import { Button } from 'antd';

import { useNavigate } from 'react-router-dom';

function Error404(props) {

   let navigate = useNavigate();

   return(
    <div style={{ width: '95%', margin: '3rem auto' }}>
    <div style={{ textAlign: 'center'}}>
      <span style={{fontFamily: "Arial Black", fontSize:200, color: '#555', 'letter-spacing':'-15px'}}>Oops!</span>
      <br />
      <span style={{fontFamily: "Arial Black", fontSize:40, color: '#aaa'}}>
      404 Error - Page Not Found</span>
      <br /><br />
      <span style={{fontFamily: "맑은 고딕", fontSize:14, color: '#999'}}>
         없는 페이지이거나 사라진 페이지 입니다.<br />
         혹은 권한없이 잘못된 페이지로 접근하셨습니다.<br />
         아래의 버튼을 눌러 정상적인 페이지로 이동해주세요.</span>
         
      <br /><br />
      <span>
            <Button type="primary" shape="round" size="Large" onClick={(e) => navigate("/")}>홈으로</Button>
      </span>
   </div>
   </div>
   ) 
}
export default Error404;