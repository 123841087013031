import React, { useState } from 'react'
import { Button, Col, Row } from 'antd';
import ExportJsonExcel from 'js-export-excel';
import moment from 'moment';

const dateFormat = 'YYYY-MM-DD';

function DownloadExcel(props) {

    const downloadExcel = () => {
        const data = props.dataSource ? props.dataSource : '';//tabular data
        const columns = props.columns ? props.columns : '';
        
         var option = {};
         let Filter = [];
         let Header = [];
         
         if (columns) {
           for (let i in columns) {
             if(columns){
                //  console.log(columns[i].title);
                //  Filter[i] = columns[i].title;
                //  Header[i] = columns[i].dataIndex;

             }
             Header.push(columns[i].title);
             Filter.push(columns[i].dataIndex);
           }
         }

        //   console.log(Header,Filter);
            option.fileName = props.filename.concat(moment().format(dateFormat));
         option.datas=[
           {
             sheetData: data,
             sheetName:'sheet',
                    sheetFilter:Filter,
                    sheetHeader:Header,
           }
         ];
        
         var toExcel = new ExportJsonExcel(option); 
         toExcel.saveExcel();        
       }

    return (
        // <div style={{float: 'right'}}>
        <div>
            {/* <Row justify="end"> */}
                {/* <Col span={20}></Col> */}
              {/* <Col span={4}> */}
                <Button type="primary" onClick={downloadExcel}>액셀로 내려받기</Button>
                {/* </Col> */}
            {/* </Row>     */}
        </div>

    )
}

export default DownloadExcel
