import React from 'react';
import moment from 'moment';
import 'moment/locale/ko';
import { FileDoneOutlined, CloseCircleTwoTone, CheckCircleFilled, CloseOutlined } from '@ant-design/icons';
import { Typography } from 'antd';

const { Text } = Typography;

const dateFormat = 'YYYY-MM-DD';
const add_commer = (data) => {

  let _data = ''+ data;
  _data = _data.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return(_data);
}

const columns_notice = [
  {
    'title': 'SQ',
    'dataIndex': 'SQ',
    'key': 'SQ',
    'align' : 'center',
    'ellipsis' : true,
    'width' : 60,
  },
  {
    'title': '공지대상',
    'dataIndex': 'division',
    'key': 'division',
    'align' : 'center',
    'width' : 100,
    filters: [
      {
        text: '전체',
        value: '전체',
      },
      {
        text: '가맹점',
        value: '가맹점',
      },
      {
        text: '협력사',
        value: '협력사',
      },
    ],
    onFilter: (value, record) => record.division.indexOf(value) === 0,
  },
  {
    'title': '공지종류',
    'dataIndex': 'titleHead',
    'key': 'titleHead',
    'align' : 'center',
    'width' : 100,
    filters: [
      {
        text: '긴급공지',
        value: '긴급공지',
      },
      {
        text: '자료제출공지',
        value: '자료제출공지',
      },
      {
        text: '행사공지',
        value: '행사공지',
      },
      {
        text: '일반공지',
        value: '일반공지',
      },
    ],
    onFilter: (value, record) => record.titleHead.indexOf(value) === 0,
    render(text, row) {
      let style = '';
      if (text === '긴급공지') {style = 'danger'}
      if (text === '자료제출공지') {style = 'warning'}
      if (text === '행사공지') {style = 'success'}
      // if (text === '일반공지') {style = 'black'}
       return(
        <>
          {/* <Tag color={style}>{text}</Tag> */}
          <Text type={style}>{text}</Text>
        </>)
    }

  },

  {
    'title': '제목',
    'dataIndex': 'title',
    'key': 'title',
    'align' : 'left',
    'width' : 400,
  },
  {
    'title': '파일1',
    'dataIndex': 'attchFile1',
    'key': 'attchFile1',
    'align' : 'center',
    'width' : 60,
    // render:(text, row) => <FileDoneOutlined style={{ fontSize: '24px'}} />,
    render(text, row) {
      if(row.attchFile1) 
      return(
        <>
            <FileDoneOutlined style={{ fontSize: '24px', color: '#666'}} />
        </>)
    }
  },
  {
    'title': '파일2',
    'dataIndex': 'attchFile2',
    'key': 'attchFile2',
    'align' : 'center',
    'width' : 60,
    // render:(text, row) => <FileDoneOutlined style={{ fontSize: '24px'}} />,
    render(text, row) {
      if(row.attchFile2) 
      return(
        <>
          <FileDoneOutlined style={{ fontSize: '24px', color: '#666'}} />
        </>)
    }
  },
  {
    'title': '작성자',
    'dataIndex': 'writer',
    'key': 'writer',
    'align' : 'center',
    'width' : 100,
    
  },
  {
    'title': '조회수',
    'dataIndex': 'counter',
    'key': 'counter',
    'align' : 'center',
    'width' : 70,
  },
  {
    'title': '등록일자',
    'dataIndex': 'writeDate',
    'key': 'writeDate',
    'align' : 'center',
    'width' : 100,
  },
]
const columns_notice2 = [
  {
    'title': 'SQ',
    'dataIndex': 'SQ',
    'key': 'SQ',
    'align' : 'center',
    'ellipsis' : true,
    'width' : 60,
  },
  {
    'title': '공지대상',
    'dataIndex': 'division',
    'key': 'division',
    'align' : 'center',
    'width' : 100,
  },
  {
    'title': '공지종류',
    'dataIndex': 'titleHead',
    'key': 'titleHead',
    'align' : 'center',
    'width' : 100,
    render(text, row) {
      let style = '';
      if (text === '긴급공지') {style = 'danger'}
      if (text === '자료제출공지') {style = 'warning'}
      if (text === '행사공지') {style = 'success'}
      // if (text === '일반공지') {style = 'black'}
       return(
        <>
          {/* <Tag color={style}>{text}</Tag> */}
          <Text type={style}>{text}</Text>
        </>)
    }

  },

  {
    'title': '제목',
    'dataIndex': 'title',
    'key': 'title',
    'align' : 'left',
    'width' : 400,
  },
  {
    'title': '파일1',
    'dataIndex': 'attchFile1',
    'key': 'attchFile1',
    'align' : 'center',
    'width' : 60,
    // render:(text, row) => <FileDoneOutlined style={{ fontSize: '24px'}} />,
    render(text, row) {
      if(row.attchFile1) 
      return(
        <>
            <FileDoneOutlined style={{ fontSize: '24px', color: '#666'}} />
        </>)
    }
  },
  {
    'title': '파일2',
    'dataIndex': 'attchFile2',
    'key': 'attchFile2',
    'align' : 'center',
    'width' : 60,
    // render:(text, row) => <FileDoneOutlined style={{ fontSize: '24px'}} />,
    render(text, row) {
      if(row.attchFile2) 
      return(
        <>
          <FileDoneOutlined style={{ fontSize: '24px', color: '#666'}} />
        </>)
    }
  },
  {
    'title': '작성자',
    'dataIndex': 'writer',
    'key': 'writer',
    'align' : 'center',
    'width' : 100,
    
  },
  {
    'title': '조회수',
    'dataIndex': 'counter',
    'key': 'counter',
    'align' : 'center',
    'width' : 70,
  },
  {
    'title': '등록일자',
    'dataIndex': 'writeDate',
    'key': 'writeDate',
    'align' : 'center',
    'width' : 100,
  },
]
const columns_franchisee = [
  {
    'title': 'SQ',
    'dataIndex': 'SQ',
    'key': 'SQ',
    'align' : 'center',
    'ellipsis' : true,
    'width' : 40,
  },
  {
    'title': 'D. NO',
    'dataIndex': '_id',
    'key': '_id',
    'align' : 'center',
    'width' : 40,
  },
  {
    'title': '회원상태',
    'dataIndex': 'orNotUse',
    'key': 'orNotUse',
    'align' : 'center',
    'width' : 40,
  },
  {
    'title': '가맹점구분',
    'dataIndex': 'category',
    'key': 'category',
    'align' : 'center',
    'width' : 50,
  },
  {
    'title': '그룹구분',
    'dataIndex': 'groupName',
    'key': 'groupName',
    'align' : 'center',
    'width' : 50,
    'ellipsis' : 'true',
  },
  {
    'title': '가맹점코드',
    'dataIndex': 'code',
    'key': 'code',
    'align' : 'center',
    'width' : 40,
  },
  {
    'title': '상 호',
    'dataIndex': 'shopName',
    'key': 'shopName',
    'align' : 'center',
    'width' : 80,
    'ellipsis' : 'true',
  },
  {
    'title': '별 칭',
    'dataIndex': 'nickName',
    'key': 'nickName',
    'align' : 'center',
    'width' : 80,
    
  },
  {
    'title': '사업자번호',
    'dataIndex': 'businessNumber',
    'key': 'businessNumber',
    'align' : 'center',
    'width' : 70,
    
  },
  {
    'title': '대표자명',
    'dataIndex': 'CEO',
    'key': 'CEO',
    'align' : 'center',
    'width' : 40,
  },
  {
    'title': '전화번호',
    'dataIndex': 'telNumber',
    'key': 'telNumber',
    'align' : 'center',
    'width' : 70,
  },
  // {
  //   'title': '팩스',
  //   'dataIndex': 'FAX',
  //   'key': 'FAX',
  //   'align' : 'center',
  //   'width' : 60,
  // },
  // {
  //   'title': '업태',
  //   'dataIndex': 'businessCondition',
  //   'key': 'businessCondition',
  //   'align' : 'center',
  //   'width' : 60,
  // },
  // {
  //   'title': '종목',
  //   'dataIndex': 'businessType',
  //   'key': 'businessType',
  //   'align' : 'center',
  //   'width' : 60,
  // },
  // {
  //   'title': '우편번호',
  //   'dataIndex': 'zipcode',
  //   'key': 'zipcode',
  //   'align' : 'center',
  //   'width' : 50,
  // },
  {
    'title': '사업장주소',
    'dataIndex': 'address1',
    'key': 'address1',
    'align' : 'right',
    'width' : 130,
    'ellipsis' : 'true',
  },
  {
    'title': '세부주소',
    'dataIndex': 'address2',
    'key': 'address2',
    'align' : 'left',
    'width' : 60,
    'ellipsis' : 'true',
  },
]
const columns_partners = [
  {
    'title': 'SQ',
    'dataIndex': 'SQ',
    'key': 'SQ',
    'align' : 'center',
    // 'ellipsis' : true,
    'width' : 40,
  },
  // {
  //   'title': 'DATANO',
  //   'dataIndex': '_id',
  //   'key': '_id',
  //   'align' : 'center',
  //   'width' : 40,
  // },
  {
    'title': '회원상태',
    'dataIndex': 'orNotUse',
    'key': 'orNotUse',
    'align' : 'center',
    'width' : 50,
  },
  {
    'title': '회원코드',
    'dataIndex': 'code',
    'key': 'code',
    'align' : 'center',
    'width' : 40,
  },
  {
    'title': '제조국가',
    'dataIndex': 'country',
    'key': 'country',
    'align' : 'center',
    'width' : 40,
  },
  {
    'title': '사업자구분',
    'dataIndex': 'businessClass',
    'key': 'businessClass',
    'align' : 'center',
    'width' : 40,
  },
  {
    'title': '상 호',
    'dataIndex': 'shopName',
    'key': 'shopName',
    'align' : 'center',
    'width' : 80,
    
  },
  {
    'title': '사업자번호',
    'dataIndex': 'businessNumber',
    'key': 'businessNumber',
    'align' : 'center',
    'width' : 60,
    
  },
  {
    'title': '대표자',
    'dataIndex': 'CEO',
    'key': 'CEO',
    'align' : 'center',
    'width' : 40,
  },
  // {
  //   'title': '업태',
  //   'dataIndex': 'businessCondition',
  //   'key': 'businessCondition',
  //   'align' : 'center',
  //   'width' : 40,
  // },
  // {
  //   'title': '종목',
  //   'dataIndex': 'businessType',
  //   'key': 'businessType',
  //   'align' : 'center',
  //   'width' : 40,
  // },
  {
    'title': '우편번호',
    'dataIndex': 'zipcode',
    'key': 'zipcode',
    'align' : 'center',
    'width' : 40,
  },
  {
    'title': '사업장주소',
    'dataIndex': 'address1',
    'key': 'address1',
    'align' : 'right',
    'width' : 120,
    'ellipsis' : 'true',
  },
  {
    'title': '세부주소',
    'dataIndex': 'address2',
    'key': 'address2',
    'align' : 'left',
    'width' : 80,
    'ellipsis' : 'true',
  },
]
const columns_users = [
  {
    'title': 'SQ',
    'dataIndex': 'SQ',
    'key': 'SQ',
    'align' : 'center',
    'width' : 40,
  },
  {
    'title': 'DATA NO',
    'dataIndex': 'divisionId',
    'key': 'divisionId',
    'align' : 'right',
//    'ellipsis' : true,
    'width' : 40,
  },
  {
    'title': '거래처구분',
    'dataIndex': 'division',
    'key': 'division',
    'align' : 'center',
    'width' : 50,
  },
  {
    'title': '거래처명',
    'dataIndex': 'shopName',
    'key': 'shopName',
    'align' : 'left',
    'width' : 80,
  },
  {
    'title': '사용자명',
    'dataIndex': 'userName',
    'key': 'userName',
    'align' : 'center',
    'width' : 40,
  },
  {
    'title': '아이디',
    'dataIndex': 'userId',
    'key': 'userId',
    'align' : 'center',
    'width' : 40,
  },
  {
    'title': '등급',
    'dataIndex': 'auth',
    'key': 'auth',
    'align' : 'center',
    'width' : 50,
  },
  // {
  //   'title': '등록일',
  //   'dataIndex': 'writeDate',
  //   'key': 'writeDate',
  //   'align' : 'center',
  //   'width' : 40,
  // },
  {
    'title': '상태',
    'dataIndex': 'orNotUse',
    'key': 'orNotUse',
    'align' : 'center',
    'width' : 30,
    filters: [
      {
        text: '정상',
        value: '정상',
      },
      {
        text: '정지',
        value: '정지',
      },
    ],
    onFilter: (value, record) => record.orNotUse.indexOf(value) === 0,
    
  },
  
]
const columns_salesupload = [
  {
    'title': 'SQ',
    'dataIndex': 'SQ',
    'key': 'SQ',
    'align' : 'center',
    'ellipsis' : true,
    'width' : 30,
  },
//   {
//     'title': 'DATA NO',
//     'dataIndex': '_id',
//     'key': '_id',
//     'align' : 'right',
// //    'ellipsis' : true,
//     'width' : 40,
//   },
  {
    'title': '매출일자',
    'dataIndex': 'salesDate',
    'key': 'salesDate',
    'align' : 'center',
    'width' : 50,
    render(text, row) {
      let style = '';
      if (row.check) {style = 'danger'} else {style = 'black'}

       return(
        <>
          {/* <Tag color={style}>{text}</Tag> */}
          <Text type={style}>{text}</Text>
        </>)
    }
  },
  {
    'title': '가맹점코드',
    'dataIndex': 'franchiseeCode',
    'key': 'franchiseeCode',
    'align' : 'center',
    'width' : 60,
    render(text, row) {
      let style = '';
      if (text === 'No Code') {
        style = <CloseCircleTwoTone twoToneColor='#eb2f96' />
      }
      else { 
        style = text 
      }
      
      return(
        <>
          {style}
        </>
      )
    }
  },
  {
    'title': '가맹점명(별칭)',
    'dataIndex': 'nickName',
    'key': 'nickName',
    'align' : 'center',
    'width' : 70,
    render(text, row) {
      let style = '';
      if (typeof text === 'undefined') {
        style = <CloseCircleTwoTone twoToneColor='#eb2f96' />
      }
      else { 
        style = text 
      }
      
      return(
        <>
          {style}
        </>
      )
    }
  },
  {
    'title': '브랜드',
    'dataIndex': 'brand',
    'key': 'brand',
    'align' : 'center',
    'width' : 30,
  },
  
  {
    'title': '상품명',
    'dataIndex': 'goodsName',
    'key': 'goodsName',
    'align' : 'left',
    'width' : 90,
    render(text, row) {
      let style = '';
      if (typeof text === 'undefined') {
        style = <CloseCircleTwoTone twoToneColor='#eb2f96' />
      }
      else { 
        style = text 
      }
      
      return(
        <>
          {style}
        </>
      )
    }
  },
  {
    'title': '수량',
    'dataIndex': 'quantity',
    'key': 'quantity',
    'align' : 'right',
    'width' : 30,
    render(text, row) {
      let _text = '';
      if(!isNaN(text)) {
        _text = add_commer(text);
      }
      else {
        _text = <CloseCircleTwoTone twoToneColor='#eb2f96' />
      }
       return(
        <>
          {/* <Tag color={style}>{text}</Tag> */}
          <Text>{_text}</Text>
        </>)
    }
  },
  {
    'title': '가맹점공급단가',
    'dataIndex': 'franchiseeUnitprice',
    'key': 'franchiseeUnitprice',
    'align' : 'right',
    'width' : 70,
    render(text, row) {
      let _text = '';
      if(!isNaN(text)) {
        _text = add_commer(text);
      }
      else {
        _text = <CloseCircleTwoTone twoToneColor='#eb2f96' />
      }
       return(
        <>
          {/* <Tag color={style}>{text}</Tag> */}
          <Text >{_text}</Text>
        </>)
    }
  },
  {
    'title': '본사공급단가',
    'dataIndex': 'gndcoUnitprice',
    'key': 'gndcoUnitprice',
    'align' : 'right',
    'width' : 60,
    render(text, row) {
      let _text = '';
      if(!isNaN(text)) {
        _text = add_commer(text);
      }
      else {
        _text = <CloseCircleTwoTone twoToneColor='#eb2f96' />
      }

       return(
        <>
          {/* <Tag color={style}>{text}</Tag> */}
          <Text >{_text}</Text>
        </>)
    }
  },
  {
    'title': '가맹점공급가액',
    'dataIndex': 'franchiseePrice',
    'key': 'franchiseePrice',
    'align' : 'right',
    'width' : 60,
    render(text, row) {
      let _text = '';
      _text = add_commer(text);
       return(
        <>
          {/* <Tag color={style}>{text}</Tag> */}
          <Text >{_text}</Text>
        </>)
    }
  },
  {
    'title': '본사공급가액',
    'dataIndex': 'gndcoPrice',
    'key': 'gndcoPrice',
    'align' : 'right',
    'width' : 50,
    render(text, row) {
      let _text = '';
      _text = add_commer(text);
       return(
        <>
          {/* <Tag color={style}>{text}</Tag> */}
          <Text >{_text}</Text>
        </>)
    }
  },
  {
    'title': '본사할인율',
    'dataIndex': 'gndcoDiscountrate',
    'key': 'gndcoDiscountrate',
    'align' : 'center',
    'width' : 50,
    render(text, row) {
      let _text = text + '%';
   
       return(
        <>
          {/* <Tag color={style}>{text}</Tag> */}
          <Text >{_text}</Text>
        </>)
    }
  },
  {
    'title': '비고',
    'dataIndex': 'memo',
    'key': 'memo',
    'align' : 'center',
    'width' : 50,
  },
  // {
  //   'title': '등록일시',
  //   'dataIndex': 'writeDate',
  //   'key': 'writeDate',
  //   'align' : 'center',
  //   'width' : 100,
  // },
]
const columns_sales = [
  {
    'title': 'SQ',
    'dataIndex': 'SQ',
    'key': 'SQ',
    'align' : 'center',
    'ellipsis' : true,
    'width' : 30,
  },
  {
    'title': '마감',
    'dataIndex': 'closeCheck',
    'key': 'closeCheck',
    'align' : 'center',
    'width' : 20,
    render(text, row) {
      let style = '';
      if(text === 'Y') 
        style = <CheckCircleFilled style={{color:'#73d13d'}} />
      else
        style = <CloseOutlined style={{color:'#f759ab'}}  />

      return(
        <>
          {style}
        </>
      )
    }
  },
  {
    'title': '매출일자',
    'dataIndex': 'salesDate',
    'key': 'salesDate',
    'align' : 'center',
    'width' : 50,
    
  },
  {
    'title': '가맹점코드',
    'dataIndex': 'franchiseeCode',
    'key': 'franchiseeCode',
    'align' : 'center',
    'width' : 60,
    render(text, row) {
      let style = '';
      if (text === 'No Code') {
        style = <CloseCircleTwoTone twoToneColor='#eb2f96' />
      }
      else { 
        style = text 
      }
      
      return(
        <>
          {style}
        </>
      )
    }
  },
  {
    'title': '가맹점명(별칭)',
    'dataIndex': 'nickName',
    'key': 'nickName',
    'align' : 'center',
    'width' : 70,
    render(text, row) {
      let style = '';
      if (typeof text === 'undefined') {
        style = <CloseCircleTwoTone twoToneColor='#eb2f96' />
      }
      else { 
        style = text 
      }
      
      return(
        <>
          {style}
        </>
      )
    }
  },
  {
    'title': '브랜드',
    'dataIndex': 'brand',
    'key': 'brand',
    'align' : 'center',
    'width' : 30,
  },
  
  {
    'title': '상품명',
    'dataIndex': 'goodsName',
    'key': 'goodsName',
    'align' : 'left',
    'width' : 90,
    render(text, row) {
      let style = '';
      if (typeof text === 'undefined') {
        style = <CloseCircleTwoTone twoToneColor='#eb2f96' />
      }
      else { 
        style = text 
      }
      
      return(
        <>
          {style}
        </>
      )
    }
  },
  {
    'title': '수량',
    'dataIndex': 'quantity',
    'key': 'quantity',
    'align' : 'right',
    'width' : 30,
    render(text, row) {
      let _text = '';
      if(!isNaN(text)) {
        _text = add_commer(text);
      }
      else {
        _text = <CloseCircleTwoTone twoToneColor='#eb2f96' />
      }
       return(
        <>
          {/* <Tag color={style}>{text}</Tag> */}
          <Text>{_text}</Text>
        </>)
    }
  },
  {
    'title': '가맹점공급단가',
    'dataIndex': 'franchiseeUnitprice',
    'key': 'franchiseeUnitprice',
    'align' : 'right',
    'width' : 70,
    render(text, row) {
      let _text = '';
      if(!isNaN(text)) {
        _text = add_commer(text);
      }
      else {
        _text = <CloseCircleTwoTone twoToneColor='#eb2f96' />
      }
       return(
        <>
          {/* <Tag color={style}>{text}</Tag> */}
          <Text >{_text}</Text>
        </>)
    }
  },
  {
    'title': '본사공급단가',
    'dataIndex': 'gndcoUnitprice',
    'key': 'gndcoUnitprice',
    'align' : 'right',
    'width' : 60,
    render(text, row) {
      let _text = '';
      if(!isNaN(text)) {
        _text = add_commer(text);
      }
      else {
        _text = <CloseCircleTwoTone twoToneColor='#eb2f96' />
      }

       return(
        <>
          {/* <Tag color={style}>{text}</Tag> */}
          <Text >{_text}</Text>
        </>)
    }
  },
  {
    'title': '가맹점공급가액',
    'dataIndex': 'franchiseePrice',
    'key': 'franchiseePrice',
    'align' : 'right',
    'width' : 60,
    render(text, row) {
      let _text = '';
      _text = add_commer(text);
       return(
        <>
          {/* <Tag color={style}>{text}</Tag> */}
          <Text >{_text}</Text>
        </>)
    }
  },
  {
    'title': '본사공급가액',
    'dataIndex': 'gndcoPrice',
    'key': 'gndcoPrice',
    'align' : 'right',
    'width' : 50,
    render(text, row) {
      let _text = '';
      _text = add_commer(text);
       return(
        <>
          {/* <Tag color={style}>{text}</Tag> */}
          <Text >{_text}</Text>
        </>)
    }
  },
  {
    'title': '본사할인율',
    'dataIndex': 'gndcoDiscountrate',
    'key': 'gndcoDiscountrate',
    'align' : 'center',
    'width' : 50,
    render(text, row) {
      let _text = text + '%';
   
       return(
        <>
          {/* <Tag color={style}>{text}</Tag> */}
          <Text >{_text}</Text>
        </>)
    }
  },
  {
    'title': '비고',
    'dataIndex': 'memo',
    'key': 'memo',
    'align' : 'center',
    'width' : 50,
  },
  // {
  //   'title': '등록일시',
  //   'dataIndex': 'writeDate',
  //   'key': 'writeDate',
  //   'align' : 'center',
  //   'width' : 100,
  // },
]
const columns_invoices = [
  {
    'title': '청구월',
    'dataIndex': 'invociesMonth',
    'key': 'invociesMonth',
    'align' : 'center',
//    'ellipsis' : true,
    'width' : 60,
  },
  {
    'title': '매입처',
    'dataIndex': 'partnersName',
    'key': 'partnersName',
    'align' : 'center',
    'width' : 80,
  },
  {
    'title': '금액',
    'dataIndex': 'summary',
    'key': 'summary',
    'align' : 'right',
    'width' : 80,
    render(text, row) {
      let _text = '';
      _text = add_commer(text);
       return(
        <>
          {/* <Tag color={style}>{text}</Tag> */}
          <Text >{_text}</Text>
        </>)
    }
  },
]
const columns_invoices2 = [
  {
    'title': '일자',
    'dataIndex': 'salesDate',
    'key': 'salesDate',
    'align' : 'center',
//    'ellipsis' : true,
    'width' : 50,
  },
  {
    'title': '매입처',
    'dataIndex': 'partnersName',
    'key': 'partnersName',
    'align' : 'center',
    'width' : 80,
  },
  {
    'title': '브랜드',
    'dataIndex': 'brand',
    'key': 'brand',
    'align' : 'center',
    'width' : 40,
  },
  {
    'title': '상품명',
    'dataIndex': 'goodsName',
    'key': 'goodsName',
    'align' : 'left',
    'width' : 80,
  },
  {
    'title': '수량',
    'dataIndex': 'quantity',
    'key': 'quantity',
    'align' : 'right',
    'width' : 40,
    render(text, row) {
      let _text = '';
      _text = add_commer(text);
       return(
        <>
          {/* <Tag color={style}>{text}</Tag> */}
          <Text >{_text}</Text>
        </>)
    }
  },
  {
    'title': '단가',
    'dataIndex': 'franchiseeUnitprice',
    'key': 'franchiseeUnitprice',
    'align' : 'right',
    'width' : 60,
    render(text, row) {
      let _text = '';
      _text = add_commer(text);
       return(
        <>
          {/* <Tag color={style}>{text}</Tag> */}
          <Text >{_text}</Text>
        </>)
    }
  },
  {
    'title': '금액',
    'dataIndex': 'franchiseePrice',
    'key': 'franchiseePrice',
    'align' : 'right',
    'width' : 60,
    render(text, row) {
      let _text = '';
      _text = add_commer(text);
       return(
        <>
          {/* <Tag color={style}>{text}</Tag> */}
          <Text >{_text}</Text>
        </>)
    }
  },
  {
    'title': '비고',
    'dataIndex': 'memo',
    'key': 'memo',
    'align' : 'center',
    'width' : 100,
  },
]
const columns_invoicestotal = [
  {
    'title': '전월 미결액',
    'dataIndex': 'misoo',
    'key': 'misoo',
    'align' : 'right',
    'width' : 60,
    render(text, row) {
      let _text = '';
      _text = add_commer(text);
       return(
        <>
          {/* <Tag color={style}>{text}</Tag> */}
          <Text >{_text}</Text>
        </>)
    }
  },
  {
    'title': '당월청구액',
    'dataIndex': 'misoo2',
    'key': 'misoo2',
    'align' : 'right',
    'width' : 60,
    render(text, row) {
      let _text = '';
      _text = add_commer(text);
       return(
        <>
          {/* <Tag color={style}>{text}</Tag> */}
          <Text >{_text}</Text>
        </>)
    }
  },
  {
    'title': '총청구액',
    'dataIndex': 'misoo3',
    'key': 'misoo3',
    'align' : 'right',
    'width' : 60,
    render(text, row) {
      let _text = '';
      _text = add_commer(text);
       return(
        <>
          {/* <Tag color={style}>{text}</Tag> */}
          <Text >{_text}</Text>
        </>)
    }
  },

]
const columns_groups = [
  {
    'title': 'SQ',
    'dataIndex': 'SQ',
    'key': 'SQ',
    'align' : 'center',
    'ellipsis' : true,
    'width' : 40,
  },
  {
    'title': '그룹코드',
    'dataIndex': 'groupCode',
    'key': 'groupCode',
    'align' : 'center',
    'width' : 40,
  },
  {
    'title': '가맹점 그룹명',
    'dataIndex': 'groupName',
    'key': 'groupName',
    'align' : 'center',
    'width' : 100,
  },
]
const continents_pagination = [
  {
    "_id": 0,
    "name": "10건씩 보기",
    "data": 10
  },
  {
    "_id": 1,
    "name": "20건씩 보기",
    "data": 20
  },
  {
    "_id": 2,
    "name": "30건씩 보기",
    "data": 30
  },
  {
    "_id": 3,
    "name": "40건씩 보기",
    "data": 40
  },
  {
    "_id": 4,
    "name": "50건씩 보기",
    "data": 50
  },
  {
    "_id": 5,
    "name": "100건씩 보기",
    "data": 100
  },
]
const option_division = [
  {
      "_id": '1',
      "name": "협력사",
      "data": "partners"
  },
  {
      "_id": '2',
      "name": "가맹점",
      "data": "franchisee"
  },
  {
      "_id": '3',
      "name": "전체",
      "data": "all"
  },
]
const option_division2 = [
  {
      "_id": '1',
      "name": "협력사",
      "data": "partners"
  },
  {
      "_id": '2',
      "name": "가맹점",
      "data": "franchisee"
  },
  {
      "_id": '3',
      "name": "본사",
      "data": "gndco"
  },
]
const option_titleHead = [
  {
      "_id": '1',
      "name": "긴급공지",
      "data": "#ff0000"
  },
  {
      "_id": '2',
      "name": "자료제출공지",
      "data": "#ff0000"
  },
  {
      "_id": '3',
      "name": "행사공지",
      "data": "#0000ff"
  },
  {
      "_id": '4',
      "name": "일반공지",
      "data": "#000000"
  },
]
const option_orNotUse = [
  {
      "_id": '1',
      "name": "정상",
      "data": "1"
  },
  {
      "_id": '2',
      "name": "정지",
      "data": "0"
  },
]
const option_orNotUse2 = [
  {
      "_id": '1',
      "name": "거래중",
      "data": "1"
  },
  {
      "_id": '2',
      "name": "정지",
      "data": "0"
  },
    {
      "_id": '3',
      "name": "휴업",
      "data": "2"
  },
  {
      "_id": '4',
      "name": "폐업",
      "data": "3"
  },
]
const option_auth = [
  {
      "_id": '1',
      "name": "관리자",
      "data": "0"
  },
  {
      "_id": '2',
      "name": "일반사용자",
      "data": "1"
  },
]
const option_category = [
  {
      "_id": '1',
      "name": "체인가맹점",
      "data": "1"
  },
  {
      "_id": '2',
      "name": "일반점",
      "data": "2"
  },
  {
      "_id": '3',
      "name": "무약정점",
      "data": "3"
  },
  {
      "_id": '4',
      "name": "기타",
      "data": "9"
  },
]
const option_businessClass = [
  {
      "_id": '1',
      "name": "법인",
      "data": "1"
  },
  {
      "_id": '2',
      "name": "개인",
      "data": "2"
  },
  {
      "_id": '3',
      "name": "기타",
      "data": "3"
  },

]
const option_country = [
  {
      "_id": '1',
      "name": "국내",
      "data": "1"
  },
  {
      "_id": '2',
      "name": "국외",
      "data": "2"
  },
,
]

export {
    columns_notice,
    columns_notice2,
    columns_franchisee,
    columns_partners,
    columns_users,
    columns_groups,
    columns_salesupload,
    columns_sales,
    columns_invoices,
    columns_invoices2,
    columns_invoicestotal,

    continents_pagination,
    option_division,
    option_division2,
    option_titleHead,
    option_orNotUse,
    option_orNotUse2,
    option_auth,
    option_category,
    option_businessClass,
    option_country,
}
