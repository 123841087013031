import React, { useEffect, useState } from 'react'
import { Row, Col, Table, Button, notification, Typography, Space, Modal, Input, Select, Card, DatePicker } from 'antd';
import moment from 'moment';
import { apiServer } from '../../utils/custom-axios';
import {     
    columns_partners,
    continents_pagination,
    option_orNotUse2,
    option_category,
    option_businessClass,
    option_country,
    columns_salesupload,
   } from '../.././common/datas/Datas';
import SelectBox from '../.././common/SelectBox';
import DownloadExcel from '../.././common/DownloadExcel';
import { useLocation } from 'react-router-dom';
import ExcelToJson from '../.././common//UploadExcel2';
// import ExcelToJson from '../.././common//ReadExcel';
import { CloseCircleTwoTone, FastBackwardFilled } from '@ant-design/icons';
import 'moment/locale/ko';
import locale from 'antd/es/date-picker/locale/ko_KR'

const { Search } = Input;
const { TextArea } = Input;
const { Option } = Select;
const { MonthPicker, RangePicker } = DatePicker;

const dateFormat = 'YYYY-MM-DD';
const { Title } = Typography;

function Salesupload(props) {

    const [Division, setDivision] = useState('');
    const [UserClass, setUserClass] = useState('');

    const [Data, setData] = useState([]);
    const [Codes, setCodes] = useState([]);
    const [SelectLowData, setSelectLowData] = useState({});
    const [SelectIndex, setSelectIndex] =  useState();
    const [PageSize, setPageSize] = useState(10);
    const [Modalonoff, setModalonoff] = useState(false);
    const [WModalonoff, setWModalonoff] = useState(false);
    const [ModalMode, setModalMode] = useState('읽기');
    const [filename, setFilename] = useState('');
    const [GroupData, setGroupData] = useState([]);
    const [Searchtext, setSearchtext] = useState({});
    const [ModalTitle, setModalTitle] = useState('');
    const [Totalgndco, setTotalgndco] = useState(0);
    const [Totalfranchisee, setTotalfranchisee] = useState(0);

    const [isOpenPost, setIsOpenPost] = useState(false);
    const [OldClose_date,setOldClose_date] = useState('');
    const [Divstyle, setDivstyle] = useState({});
    const [SQ,setSQ] = useState(0);
    const [CheckError, setCheckError] = useState(true);

    const [loading, setLoading] = useState(false);

    const div_insert = {'float' : 'left' , 'display':'none',  'visibility':'hidden'};
    const div_updata = {'float' : 'left'};

    // searh params parse
    let location = useLocation();
   
    let division = sessionStorage.getItem('division');
    let ssid = sessionStorage.getItem('SSID');
    let userid = sessionStorage.getItem('id');
    let userclass = sessionStorage.getItem('userClass');

    useEffect(() => {
        let data = {'search' : '' ,'orNotUse' : '','groupName' : ''};
        setSearchtext(data);
        setFilename('협력사');
        setDivision(division);
        setUserClass(userclass);
        getDeadline();
        getCodes();
        // getGroupData();
        // getPartners();
    }, [])

    useEffect(() => {
        if(Data) {
            console.log(Data);
        }
    }, [Data])

    const getDeadline = async () => {

        // 세션이 있을때만 가져오도록 한다.
        if(ssid) {
            let data = await apiServer.get('/deadline').then (response => response.data);
            // console.log('deadline date',data.deadline);
            setOldClose_date(data.deadline);
        };
     };
    const getCodes = async () =>{
        let cdata = [];
        cdata = await apiServer.get('/sales-fran-code-get').then ( res => res.data);
        // console.log('codes',cdata);
        setCodes(cdata);
    }

    const runSerch = (value) => {
        let body = Searchtext;
        
        body['search'] = value;

        // console.log('value',value)
        setSearchtext(body);
        getPartners(value);
    }
    const getPartners = async (value) => {

        let data = await apiServer.get('/partners',{ params : {'search' : value ,'division' : division }}).then (response => response.data)
       // console.log('data',data);
        setData(data);
    }
    const getGroupData = async (value) => {

        let data = await apiServer.get('/group-all-code').then (response => response.data)
        console.log('그룹데이터',data);
        setGroupData(data);
    }

    const UpdatePagination = (option) =>{

        setPageSize(option);
    }
    const clickselectRow = (record ,index) => {
        
        setSelectLowData(record);
        setSelectIndex(index);
        console.log('record',record);
        showModal();
        //console.log('counter api', cdata);
        
    }
    const  WriteModal = () => {
        let date = moment().format(dateFormat);
        let record = {'salesDate' : date };
        setSelectLowData(record);
        setSelectIndex();
        setModalMode('쓰기');
        setModalTitle('매출 등록');
        setDivstyle(div_insert);
        setWModalonoff(true);
    };
    const  showModal = () => {
        
        if (userclass === '관리자'){
        setWModalonoff(true);
        setModalMode('수정');
        setModalTitle('매출 수정');
        setDivstyle(div_updata);
        }
        if (userclass === '일반사용자'){
        setModalonoff(true);
        setModalMode('읽기');
        // setWModalonoff(true);
        // setModalMode('수정');
        setModalTitle('협력사');
        setDivstyle(div_updata);
        }
    };

    const  handleCancel = () => {
        setModalonoff(false);
        setWModalonoff(false);
        if(ModalMode === '읽기' ){
            count_plus();}
        // form.resetFields();
    };

    const  handleOk = () => {
        if (ModalMode === '수정'){
            update_data();
        }
        if (ModalMode === '쓰기'){
            insert_data();
        }
        setModalonoff(false);
        setWModalonoff(false);
        // count_plus();
        
    };

    const handleDelete = () => {
        // 삭제 api 넣고...
        // form 값을 초기화 한다.
        setModalonoff(false);
        setWModalonoff(false);
    };

    const insert_data = () => {
        
        let record = SelectLowData;
        let data = [];
        let sq = SQ;

        if (check_recordset(record)) {
            sq = sq + 1
            record.SQ = sq;
            if(Data) data = Data;
            // const index = SelectIndex;
            data.push(record);
           
            console.log('insert',data,record);
            setData([...data]);
            caculrate();
            setSQ(sq);
            
        }
        else {
            openNotificationWithIcon('error', '등록 불가', '필수 데이터가 빈곳이 있습니다.');
        }
            check_dataset(data);
    }

    const update_data = () => {
        
        let data = Data;
        let record = SelectLowData;
        // let sq = SQ;

        if (check_recordset(record)) {
            // sq = sq + 1
            // record.SQ = sq;

            data[record.SQ - 1] = record;
            
            setSelectLowData({});
            setData([...data]);
            caculrate();
        } 
        else {
            openNotificationWithIcon('error', '등록 불가', '필수 데이터가 빈곳이 있습니다.');
        }
            // console.log('update_data',data);
            check_dataset(data);
    }

    const check_recordset = (record) => {
        if(
            !record.salesDate || 
            !record.franchiseeCode ||
            !record.nickName ||
            !record.brand ||
            !record.goodsName ||
            !record.quantity ||
            !record.franchiseeUnitprice ||
            !record.gndcoUnitprice ) {
           return false  
        }
        else {
           return true
        }
    }

    const check_dataset = (datas) => {
        // let datas = Data;
        // console.log('check_datas',datas);

        let error_count = 0;
        for(let record in datas){
            if(check_recordset(datas[record]) === false){
                error_count = error_count+1;
            }
            // console.log('record.check',datas[record].check);
            if(datas[record].check) {
                
                error_count = error_count+1;
            }
        }
        if(error_count > 0 ){
            openNotificationWithIcon('error', '등록 불가', error_count+'개의 데이터 에러가 있습니다.');
            setCheckError(true);
            // console.log('CheckError_true',CheckError);
            return false;
        } else {
            setCheckError(false);
            // console.log('CheckError_false',CheckError);
            return true;
        }
    }

    const count_plus = () => {
        let data = Data;
        const index = SelectIndex;
        console.log(index);

        let cdata = apiServer.put(`/noticecounter/${data[index]._id}`).then ( res => res.data);
        
        data[index].counter = parseInt(data[index].counter) + 1;
        setData(data);
        
        
    }

    const getfranchiseeCode = async (name) => {
        // let data = '';
        let cdata = '';
        return await apiServer.get('/sales-fran-code-get',{ params : {'nickName' : name}}).then ( res => res.data);
        // console.log('cdata',cdata);
        // if( cdata === 'Failed') {
        //     // openNotificationWithIcon('error', '코드를 입력하세요', '4자리 코드가 입력되지 않았습니다.', 'topLeft');
            
        //     return('가맹점확인바람');
        // } else {
        //     return('cdata');
        // }
        // return await (data);
    }

    const inputHandler = (name,event) => {

        let record = SelectLowData;
        let gndcoDiscountrate = '';



        if(event.currentTarget.value === ''){
            
            record[name] = event.currentTarget.value;
        } 
        else { 
            // console.log('test',name,event.currentTarget.value);
            record[name] = event.currentTarget.value;
        }

         console.log(name,event.currentTarget.value);

         switch(name){
            case 'quantity':
                record[name] = Number(event.currentTarget.value);
                record.franchiseePrice = Number(record.franchiseeUnitprice * record.quantity);
                record.gndcoPrice = Number(record.gndcoUnitprice * record.quantity);
                gndcoDiscountrate = (record.franchiseePrice-record.gndcoPrice)/record.franchiseePrice*100;
                record.gndcoDiscountrate = gndcoDiscountrate.toFixed(2);
                break;
            case 'franchiseeUnitprice':
                record[name] = Number(event.currentTarget.value);
                record.franchiseePrice = Number(record.franchiseeUnitprice * record.quantity);
                gndcoDiscountrate = (record.franchiseePrice-record.gndcoPrice)/record.franchiseePrice*100;
                record.gndcoDiscountrate = gndcoDiscountrate.toFixed(2);
                break;
            case 'gndcoUnitprice':
                record[name] = Number(event.currentTarget.value);
                record.gndcoPrice = Number(record.gndcoUnitprice * record.quantity);
                gndcoDiscountrate = (record.franchiseePrice-record.gndcoPrice)/record.franchiseePrice*100;
                record.gndcoDiscountrate = gndcoDiscountrate.toFixed(2);
                break;
       
            default:

            break;
        }

        console.log('record',record);
        
        setSelectLowData({...record});
        console.log('SelectLowData',SelectLowData);
       
    }
    
    const selectHandler = (name,event) => {
        // console.log('test',name,event);
       let body = Searchtext;
    
        //console.log('event',name, event);
        body[name] = event;
        setSearchtext(body);

    }
    const selectHandler_modal = (name,event) => {
        // console.log('test',name,event);
       
        let record = SelectLowData;
        console.log('event',name, event);
        record[name] = event;

        setSelectLowData({...record});
        // console.log('record',record);
       
    }
    const datepickerhandler = (name,_date,event) =>{
        //console.log('date',name,_date,event)
        let end_date = OldClose_date;
        let record = SelectLowData;
        record[name] = event;

        switch(name){
            case 'salesDate':
                if(record['salesDate'] > end_date) {
                    record['check'] = true;
                } else {
                    record['check'] = false;
                }
                
                break;
       
            default:

            break;

        }

        setSelectLowData({...record});
        //console.log(record);
        //console.log(SelectLowData)
     }

    const optionRander = (name) =>{
        console.log('optionname',name);
    }
    const OptionRander_franchisee = (codes) => (
        // console.log('ranser_codes',codes);
        codes && codes.map((value,index) => (
            <Option key={value.code} value={value.code} label={value.nickName} > {value.nickName} </Option>
            // console.log('value',value)
        ))
    )
    const renderOption = (selectoption) => (
        selectoption && selectoption.map((value,index) => (
            <Option key={value._id} value={value.name} label={value.name} > {value.name} </Option>
        ))
    )
    const clearHandler = ( name ) => {
        let body = Searchtext;
        body[name] = '';
        setSearchtext(body);
    }

    const clearHandler_modal = ( name ) => {
        let record = SelectLowData;
        console.log('event',name);
        record[name] = '';

        setSelectLowData({...record});
    }
    const codeCheck = async (nickName) => {

        const codes = Codes;
        let record = SelectLowData;
        let result = codes.filter(code => code.nickName === nickName);
            // console.log('resule',result);
            if(result.length === 1){
            record['franchiseeCode'] = result[0].code;
            } else if(result.length > 1){
                record['franchiseeCode'] = 'Overlap Code'
            } else {
                record['franchiseeCode'] = 'No Code'    
            }
        setSelectLowData({...record});
    }

    // const onChangeOpenPost = () => {
    //     setIsOpenPost(!isOpenPost);
    //   };
    //   const onCompletePost = (data) => {
    //     let record = SelectLowData;
    //     let fullAddr = data.address;
    //     let extraAddr = '';
    //     console.log('zipcode',data);
    //     if (data.addressType === 'R') {
    //         if (data.bname !== '') {
    //         extraAddr += data.bname;
    //         }
    //         if (data.buildingName !== '') {
    //         extraAddr += extraAddr !== '' ? `, ${data.buildingName}` : data.buildingName;
    //         }
    //         fullAddr += extraAddr !== '' ? ` (${extraAddr})` : '';
    //     }
    //     record.zipcode = data.zonecode;
    //     record.address1 = fullAddr;
    //     setSelectLowData({...record});
    //     setAddress(data.zonecode);
    //     setAddressDetail(fullAddr);
    //     setIsOpenPost(false);
    // };
    
    const openNotificationWithIcon = (type, title, message, placement = null ) => {
        notification[type]({
          message: title,
          description: message,
          placement,
        });
    };
     
    
    const postCodeStyle = {
        position: "absolute",
        top: "100px",
        left: "178px",
        zIndex: "100",
        border: "1px solid #000000",
        overflow: "hidden",
        width: "595px",
        height : "450px"

      };

    const openZipCode = () => {
        // console.log('열림')
        setIsOpenPost(true);
    }

    const handleSubmit = (record) => {
        console.log(record)
    }

    const sales_upload = (datas) => {

        let data = Data;
        let end_date = OldClose_date;
        // let sq = SQ;
        let sq = 0;
        let _datas = [];
        let _totalfranchisee = 0;
        let _totalgndco = 0;
        // let lastsq = SQ;
        let lastsq = 0;
        const codes = Codes;

        // setLoading(true);
        //console.log('sales_upload',datas);
        _datas = datas && datas.map((data,index) => {
            let _data = {};
            _data['SQ'] = index + 1 + sq;
           
            lastsq = _data['SQ'];
            
            //let date = (data['매출일자'] - 25569) * 86400
            _data['salesDate'] = moment(data['매출일자']).format(dateFormat);//moment.unix(date).format(dateFormat);
            // console.log('date',date,_data['salesDate']);
            // _data['salesDate'] = moment.unix((data['매출일자'] - 25569) * 86400).format(dateFormat);
            if(_data['salesDate'] > end_date) {
                _data['check'] = true;
            } else {
                _data['check'] = false;
            }

            _data['nickName'] = data['가맹점명(별칭)'];
            // let cdata = apiServer.get('/franchisee-codesearch',{ params : {'nickName' : data['가맹점명(별칭)']}}).then ( res => res.data);
            
            // let franchiseeCode = getfranchiseeCode(data['가맹점명(별칭)']);
            
            // console.log('franchiseeCode',franchiseeCode);
            // _data['franchiseeCode'] = franchiseeCode;
            // apiServer.get('/sales-fran-code-get',{ params : {'nickName' : data['가맹점명(별칭)']}}).then ( res => {
            //     _data['franchiseeCode'] = res.data});
            // _data['franchiseeCode'] = getfranchiseeCode(data['가맹점명(별칭)']);
            
            let result = codes.filter(code => code.nickName === data['가맹점명(별칭)']);
            // console.log('resule',result);
            if(result.length === 1){
            _data['franchiseeCode'] = result[0].code;
            } else if(result.length > 1){
                _data['franchiseeCode'] = 'Overlap Code'
            } else {
                _data['franchiseeCode'] = 'No Code'    
            }

            _data['brand'] = data['브랜드'];
            _data['goodsName'] = data['상품명'];
            _data['quantity'] = parseInt(data['수량']);
            // if(data['가맹점공급단가']) {
            //     let _franchiseeUnitprice = data['가맹점공급단가']
                //_data['franchiseeUnitprice'] = data['가맹점공급단가'];
                _data['franchiseeUnitprice'] = parseInt(data['가맹점공급단가'].replace(/,/g, ''), 10);

            // }
            // if(data['본사공급단가']) {
            //     let _gndcoUnitprice = data['본사공급단가']
                //_data['gndcoUnitprice'] = data['본사공급단가'];
                _data['gndcoUnitprice'] = parseInt(data['본사공급단가'].replace(/,/g, ''), 10);

            // }
            
            _data['memo'] = data['비고'];

            _data['franchiseePrice'] = _data['franchiseeUnitprice'] * _data['quantity'];
            _totalfranchisee = _totalfranchisee + _data['franchiseePrice'];

            _data['gndcoPrice'] = _data['gndcoUnitprice'] * _data['quantity'];
            _totalgndco = _totalgndco + _data['gndcoPrice']


            let gndcoDiscountrate = (_data['franchiseePrice'] - _data['gndcoPrice'] )/_data['franchiseePrice']*100
            
            // _data['gndcoDiscountrate'] = ((_data['gndcoPrice']/_data['franchiseePrice'])*100).toFixed(2);
            _data['gndcoDiscountrate'] = gndcoDiscountrate.toFixed(2);
            // _data['salesDate'] = data['매출일자'];
            return (_data)
        }) 
        setTotalfranchisee(_totalfranchisee);
        setTotalgndco(_totalgndco)

        // 합하기 데이터 줄
        // _datas = data.concat(_datas);
        // console.log('_datas',_datas);
        setSQ(lastsq);
        setData(_datas);
        check_dataset(_datas);
        setLoading(false);

    }
    const caculrate = () => {
        let datas = Data;
        console.log('caculratedata',datas)
        let _totalfranchisee = 0;
        let _totalgndco = 0;
        datas && datas.map((data,index) => {
            _totalfranchisee = _totalfranchisee + data['franchiseePrice'];
            _totalgndco = _totalgndco + data['gndcoPrice']
        })
        setTotalfranchisee(_totalfranchisee);
        setTotalgndco(_totalgndco);
    }
    
    const try_upload = async () => {

        let datas = Data;

        if(check_dataset(datas)){
            let data = await apiServer.post('/sales',{'userid' : userid, 'data' : Data}).then(response => response.data);
            console.log('Data',data);
            if(data !== 'OK') {
                openNotificationWithIcon('error', '등록 불가', data.error.count+' 개의 에러가 있습니다.');
            }
            else {
                openNotificationWithIcon('success', '등록 성공', '모든 매출정보를 서버에 등록하고 데이터를 초기화 했습니다.', 'topRight');
                setData([]);
            }
        } else {
            //openNotificationWithIcon('error', '등록 불가', '빈데이터가 있습니다.');  
        }

    }

    const onSelectSearch = (val) => {
        //console.log('search:', val);
        // let record = SelectLowData;
        let codes = Codes;
        let cdata = codes && codes.filter(it => it.nickName.includes(val))

        OptionRander_franchisee (cdata);
        // record.nickName = cdata[0].nickName;
        // record.franchiseeCode = cdata[0].franchiseeCode;
        // setSelectLowData({...record});
    }

    const onSelectChange = (val) => {
        // console.log('change:', val);
        let franchiseeCode = val;
        let record = SelectLowData;
        let codes = Codes;
        let cdata = codes && codes.filter(code => code.code.includes(val))
        // OptionRander_franchisee (cdata);
        record.nickName = cdata[0].nickName;
        record.franchiseeCode = franchiseeCode;
        setSelectLowData({...record});

    }

   return(
    <div style={{ width: '100%' }}>
    <div style={{ textAlign: 'center' }}>
    <img src="images/title_head_partners_salesinput_01.png" /><br /><br />
        {/* <DownloadExcel dataSource={Data} columns={columns_partners} filename={filename} ></DownloadExcel> */}
        {/* <Row>
        <Col span={24}> <span> <p> </p> </span></Col>
            
        </Row> */}
        <Row justify='center'>
          <table style={{width:800, borderColor:'#EAEAEA'}} border="1">
              <tr style={{'background-color': '#40a9ff', height: 30, color: 'white', fontStyle:'normal' }}>
                <td width="25%">마감일</td>
                <td width="25%">건수 </td>
                <td width="25%">가맹점공급금액 합계</td>
                <td width="25%">본사공급금액 합계</td>
              </tr>
              <tr style={{color: 'blue'}}>
                <td>{OldClose_date}</td>
                <td>{Data.length} 건</td>
                <td>{Totalfranchisee.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} 원</td>
                <td>{Totalgndco.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} 원</td>
              </tr>
          </table>
        </Row>
        <Row>
            &nbsp;
        </Row>
        <Row justify='center'>
          <Space size={[150,10]} wrap="true">
            {UserClass === '관리자' ?
            <div align="left">
                <Button type='primary' onClick = {WriteModal}>추가등록</Button>
            </div>
            : null }

            {UserClass === '관리자' ? <ExcelToJson refreshFunction={sales_upload} setLoading={setLoading} setData={setData}></ExcelToJson> : null}
                <Button type="primary" onClick={(e) => {
                    e.preventDefault();
                    window.location.href='/excel-sample/gnd-org-sample.xlsx';
                }}>
                    양식 다운로드
                </Button>

            <SelectBox
                list = {continents_pagination}
                focus_list = {PageSize}
                refreshFunction={UpdatePagination}
                >
            </SelectBox>
          </Space>
        </Row>
        <Row>
            <Col span={24}> <span> <p> </p> </span></Col>
        </Row>
        <Row justify='center'>
                <Table  style={{cursor : 'pointer', width:1800}}
                    dataSource={Data} 
                    loading={loading}
                    pagination={{ pageSize: PageSize, position: ['bottomCenter'] }}
                    columns={columns_salesupload} 
                    // pagination = {10}
                    rowKey={Data => Data._id}
                    onRow={(record,index) => ({
                        onClick: () => {
                          clickselectRow(record,index)
                        },})}
                    scroll={{ x: 1000 }} size="small">
                </Table>
        </Row>
        <Row align="middle">
            <CloseCircleTwoTone twoToneColor='#eb2f96' />&nbsp;&nbsp;<div style={{color:'red'}}>에러가 검출된 레코드가 있으면 서버로 저장되지 않습니다.</div>
        </Row>
        <Row align="middle">
            <div>코드가 나오지 않는 가맹점은 가맹점명칭(별칭)이 틀렸거나 가맹점이 없는 코드이오니 본사로 문의하시기 바랍니다.</div>
        </Row>
        <Row>
            <Col span={20}></Col>
            <Col span={4}>
                {UserClass === '관리자' ?    
                    <Button type="primary" disabled={CheckError} onClick = {try_upload}>서버로전송</Button>
                : null}
            </Col>
        </Row>

        <Modal
            width={900}
            visible={Modalonoff}
            title={SelectLowData.title}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={[
                <Button key="back" onClick={handleCancel}>닫 기</Button>,
            ]} >

            <Row>
                <Col span={4}> 등록일</Col>
                <Col span={20}> 
                <Input 
                onChange={(e) => inputHandler('writeDate', e)}
                value= {SelectLowData.writeDate}
                disabled={true}
                >
                </Input>
               </Col>
            </Row>
            <Row>
                <Col span={4}> 작성자</Col>
                <Col span={20}> 
                <Input 
                onChange={(e) => inputHandler('writer', e)}
                value= {SelectLowData.writer}
                disabled={true}
                >
                </Input>
                </Col>
            </Row>
            <Row>
                <Col span={4}> 카테고리</Col>
                <Col span={20}>
                <Input 
                onChange={(e) => inputHandler('titleHead', e)}
                value= {SelectLowData.titleHead}
                disabled={true}
                >
                </Input>
                </Col>
            </Row>
            {/* <Row>
                <Col span={4}> 공지제목</Col>
                <Col span={20}> {SelectLowData.title}</Col>
            </Row> */}
            <Row>
                <Col span={4}> 첨부파일1</Col>
                <Col span={16}> 
                <Input 
                onChange={(e) => inputHandler('attchFile1', e)}
                value= {SelectLowData.attchFile1}
                disabled={true}
                >
                </Input>
                </Col>
                <Col span={4}> <Button>다운로드</Button></Col>
            </Row>
            <Row>
                <Col span={4}> 첨부파일2</Col>
                <Col span={16}> 
                <Input 
                onChange={(e) => inputHandler('attchFile2', e)}
                value= {SelectLowData.attchFile2}
                disabled={true}
                >
                </Input>
                </Col>
                <Col span={4}> <Button>다운로드</Button></Col>
            </Row>
            <Row>
                <Col span={24}>
                <TextArea onChange={(e) => inputHandler('bodyText', e)}
                   value={SelectLowData.bodyText}
                   rows={10}
                   disabled={true}
                   >
                 </TextArea> 
                </Col>
            </Row>
        </Modal>
        
        {/* 추 가 / 등 록 */}
      
        <Modal
            width={900}
            visible={WModalonoff}
            title={ModalTitle}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={
                [
                    // <div style={Divstyle}>
                    // {/* <Popconfirm title='정말로 삭제하시겠습니까 ?' onConfirm={handleDelete} okText="삭제" cancelText='취소'> */}
                    // <Button key="back" type="primary" danger
                    // >
                    // 삭 제
                    // </Button>
                    // {/* </Popconfirm> */}
                    // </div>,
                    <Button key="back" onClick={handleCancel}>
                    닫기
                    </Button>,
                    
                    <Button key="submit" type="primary" onClick={handleOk} style={{width:150}}>
                    저 장
                    </Button>
                ]
            }
        >
        
            {/* <p>모달테스트</p> */}

            {/* <Form form={form} onFinish={handleSubmit} style={{width:'100%'}}> */}
            
              <Space direction="vertical" size="small" style={{width:'100%'}}>
                <Row>
                    <Col span={3}>매출일자</Col>
                    <Col span={21}><DatePicker 
                    defaultValue={ SelectLowData.salesDate ? moment(SelectLowData.salesDate, dateFormat) : moment(moment(), dateFormat)} 
                    value = { SelectLowData.salesDate ? moment(SelectLowData.salesDate, dateFormat) : moment(moment(), dateFormat)} 
                    format={dateFormat}
                    locale={locale}
                    onChange={(e,f) => datepickerhandler('salesDate',e,f)}

                    /></Col>
                </Row>
                <Row>
                    <Col span={3}>가맹점별칭</Col>
                    <Col span={21}>
                <Select
                    showSearch
                    placeholder="가맹점별칭"
                    optionFilterProp="children"
                    style={{ width: '100%' }}
                    // size="large"
                    onSelect={(e) => selectHandler('nickName', e)}
                    onClear={() => clearHandler('nickName') }
                    allowClear
                    onChange={onSelectChange}
                    onSearch={onSelectSearch}
                    value = {SelectLowData.nickName}
                    // filterOption={(input, option) =>
                    // option.children.includes(input) === true
                    // }
                    
                >
                    {OptionRander_franchisee(Codes)}

                </Select>
                </Col>
                </Row>
                <Row>
                    <Col span={3}>가맹점코드</Col>
                    <Col span={21}><Input 
                    onChange={(e) => inputHandler('franchiseeCode', e)}
                    value= {SelectLowData.franchiseeCode}
                    disabled={true}
                    />
                    </Col>
                </Row>
                <Row>
                    <Col span={3}>브랜드</Col>
                    <Col span={21}><Input
                    onChange={(e) => inputHandler('brand', e)}
                    value= {SelectLowData.brand}
                    disabled={false}
                     /></Col>
                </Row>
                <Row>
                    <Col span={3}>상품명</Col>
                    <Col span={21}><Input
                    onChange={(e) => inputHandler('goodsName', e)}
                    value= {SelectLowData.goodsName}
                    disabled={false}
                     /></Col>
                </Row>
                <Row>
                    <Col span={3}>수량</Col>
                    <Col span={21}><Input
                    type={'number'}
                    onChange={(e) => inputHandler('quantity', e)}
                    value= {SelectLowData.quantity}
                    disabled={false}
                     /></Col>
                </Row>
                <Row>
                    <Col span={3}>가맹점공급단가</Col>
                    <Col span={21}><Input
                    type={'number'}
                    onChange={(e) => inputHandler('franchiseeUnitprice', e)}
                    value= {SelectLowData.franchiseeUnitprice}
                    disabled={false}
                     /></Col>
                </Row>
                <Row>
                    <Col span={3}>본사공급단가</Col>
                    <Col span={21}><Input
                    type={'number'}
                    onChange={(e) => inputHandler('gndcoUnitprice', e)}
                    value= {SelectLowData.gndcoUnitprice}
                    disabled={false}
                     /></Col>
                </Row>
                <Row>
                    <Col span={3}>가맹점공급금액</Col>
                    <Col span={21}><Input
                    type={'number'}
                    // onChange={(e) => inputHandler('franchiseeprice', e)}
                    value= {SelectLowData.franchiseePrice}
                    disabled={true}
                     /></Col>
                </Row>
                {/* <Row>
                    <Col span={3}>본사공급금액</Col>
                    <Col span={21}><p>
                    {SelectLowData['gndcoprice']}
                    </p></Col>
                </Row> */}
                <Row>
                    <Col span={3}>본사공급금액</Col>
                    <Col span={21}><Input
                    type={'number'}
                    // onChange={(e) => inputHandler('gndcoprice', e)}
                    value = {SelectLowData.gndcoPrice}
                    disabled={true}
                     /></Col>
                </Row>
                <Row>
                    <Col span={3}>본사할인율</Col>
                    <Col span={21}><Input
                    type={'number'}
                    // onChange={(e) => inputHandler('gndcoDiscountrate', e)}
                    value = {SelectLowData.gndcoDiscountrate}
                    disabled={true}
                     /></Col>
                </Row>
                <Row>
                    <Col span={3}>비고</Col>
                    <Col span={21}><Input
                    type={'textarea'}
                    onChange={(e) => inputHandler('memo', e)}
                    value= {SelectLowData.memo}
                    disabled={false}
                     /></Col>
                </Row>
                {SelectLowData.gndcoprice}
                
              </Space>
        </Modal>
   </div>
   </div>
   ) 
}
export default Salesupload;