import React, { useEffect, useState, useRef, useLayoutEffect } from "react";
import { useDispatch } from "react-redux";
import { loginUser } from "./_actions/user_action";
import { useNavigate } from 'react-router-dom';
import { Card, Row, Typography, Form, Input, Button, Modal } from 'antd';
import { UserOutlined } from '@ant-design/icons';

import { BASE_URL } from "./config";
import './App.css';

const { Title } = Typography;

function Login () {

    let   navigate = useNavigate();
    const dispatch = useDispatch();

    const userInput = useRef();

    useLayoutEffect(() => {
        if(userInput.current !== null) userInput.current.focus();
    })

    const onFinish = (values) => {

        dispatch(loginUser(values))
            .then(res => {
                if(res.payload.loginSuccess) {
                    sessionStorage.setItem('SSID', res.payload.SSID);
                    sessionStorage.setItem('division', res.payload.division);
                    sessionStorage.setItem('userName', res.payload.userName);
                    sessionStorage.setItem('id', values.userid);
                    sessionStorage.setItem('userClass', res.payload.userClass);
                    sessionStorage.setItem('divisionId', res.payload.divisionId);
                    navigate("/");
                } else {
                    Modal.error({
                        title: '접속 실패',
                        content:(
                            <><br />
                                아이디나 패스워드가 틀려 접속에 실패하였습니다.<br />
                                접속중지로 인한 경우는 본사에 문의하시기 바랍니다.
                            </>
                        ),
                        onOk() {userInput.current.focus()},
                    });
                }
         })

    };

    const onFinishFailed = () => { };

    return(
        <div style={{marginTop: 30}}>
          <Row justify="center">
            <img src={`${BASE_URL}/images/logo.jpg`} align="center" 
                      valign="middle" />
          </Row>
          <Row><br />&nbsp;<br />&nbsp;<br /></Row>
          <Row justify="center">
            <Card style={{ width : 300, margin: 0, borderRadius: "10px", overflow: "hidden", borderColor: '#ccc' }}
                  title={<Title style={{color:'#666'}}>Welcome...</Title>} >
            <br />
            <Form
                    name="basic"
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off" >
                <Form.Item
                        label="아이디"
                        name="userid"
                        rules={[{ required: true, message: '반드시 입력해주세요' }]} >
                    <Input placeholder="아이디 입력"
                           suffix={<UserOutlined className="site-form-item-icon" style={{color:'#aaa'}} />}
                           ref={userInput} />
                </Form.Item>
                <Form.Item
                        label="비밀번호"
                        name="password"
                        rules={[{ required: true, message: '반드시 입력해주세요' }]} >
                    <Input.Password placeholder="비밀번호 입력" />
                </Form.Item>
                <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                    <Button type="primary" htmlType="submit">
                    로 그 인
                    </Button>
                </Form.Item>
            </Form>
            </Card>
          </Row>
        </div>
    )

}
export default Login;;