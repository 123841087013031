import React, { useEffect, useState, useRef } from 'react';
import { Row, Button, Card, Descriptions, Space, Modal, Input, Form, Divider, notification } from 'antd';
import { apiServer } from '../../utils/custom-axios';

function MyInfo() {

    const [Data, setData] = useState([]);
    const [isModal, setIsModal] = useState(false);

    const [form] = Form.useForm();

    let userid = sessionStorage.getItem('id');

    const newInput  = useRef();
    const new2Input = useRef();
    const oldInput  = useRef();

    useEffect(() => {
        getMyinfo();
    },[])

    const openNotificationWithIcon = (type, title, message, placement) => {
        notification[type]({
          message: title,
          description: message,
          placement,
        });
    };

    const getMyinfo = async (value) => {
        let data = await apiServer.get(`/myinfo/${userid}`, { params : {'mode': 'get'}}).then (response => response.data)
        //console.log('Myinfo data : ', data);
        setData(data);
    }

    const openModal = () => {
        setIsModal(true);
    }

    const handleCancel = () => {
        setIsModal(false);
        form.resetFields();
    }

    const handleSubmit = async (values) => {
        if(values.new_password !== values.comp_password) {
            openNotificationWithIcon('error', '비밀번호 오류', '두개의 새로운 비밀번호가 일치하지 않습니다.', 'topLeft');
            form.resetFields(['new_password']);
            form.resetFields(['comp_password']);
            newInput.current.focus();
        } else if(values.old_password !== Data.pswd) {
            form.resetFields(['old_password']);
            oldInput.current.focus();
            openNotificationWithIcon('error', '비밀번호 오류', '기존 비밀번호를 잘못 입력하셨습니다.', 'topLeft');
        } else if(values.new_password === values.old_password) {
            form.resetFields(['old_password']);
            oldInput.current.focus();
            openNotificationWithIcon('error', '비밀번호 오류', '기존 비밀번호와 동일한 비밀번호는 사용할 수 없습니다.', 'topLeft');
        } else {
            // password 갱신
            let data = await apiServer.post(`/myinfo/${userid}`, {values}).then( res => res.data);
            //console.log(data)
            if(data === 'OK') {
                openNotificationWithIcon('success', '비밀번호 갱신', '비밀번호를 바꾸는데 성공했습니다.', 'topRight');
                setIsModal(false);
                form.resetFields();
            }
            else {
                openNotificationWithIcon('error', '비밀번호 오류', '비밀번호를 갱신하는데 실패하였습니다. 다시 재시도 하십시요.', 'topLeft');
            }
        }
    }

    return (
        <div style={{width:'100%'}}>
            <Row justify='center'>
                <img src="images/title_head_myinfo_01.png" />
            </Row>
            <Row justify='center'>
                <Card style={{width:800, borderRadius:6, borderColor:'#108EE9'}}
                      title="내 정보 보기">
                    <Descriptions title={<span style={{color:'#108EE9'}}>※ 비밀번호를 제외한 정보 변경은 본사 관리자에게 의뢰하십시요.</span>} bordered column={{xxl:2, xl:2, lg:2, md:2, sm:2, xs:1}}>
                        <Descriptions.Item label='아이디'>{Data.userId}</Descriptions.Item>
                        <Descriptions.Item label='이  름'>{Data.userName}</Descriptions.Item>
                        <Descriptions.Item label='권  한'>{Data.auth}</Descriptions.Item>
                        <Descriptions.Item label='사용여부'>{Data.orNotUse}</Descriptions.Item>
                        <Descriptions.Item label='업체구분'>{Data.division}</Descriptions.Item>
                    </Descriptions>
                </Card>
            </Row>
            <p/>
            <p/>
            <p/>
            <p/>
            <p/>
            <Row justify='center'>
                <Button
                    type="primary"
                    onClick={openModal}>
                    비밀번호 변경하기
                </Button>
            </Row>


            <Modal
                visible={isModal}
                title='비밀번호 변경하기'
                onOk={form.submit}
                onCancel={handleCancel}
                footer={[
                    <Button onClick={handleCancel}>취 소</Button>,
                    <Button type='primary' onClick={form.submit}>비밀번호 저장</Button>,
                ]}
                width={700}
            >
                <Row justify='center' type="flex">
                    <Form form={form} onFinish={handleSubmit} style={{width:'70%'}}>
                    <Form.Item label='사용 중 비밀번호'
                               name="old_password"
                               rules={[{ required: true, message: '사용 중 인 비밀번호를 입력해주세요'}]}>
                        <Input.Password placeholder='사용 중 인 비밀번호 입력'
                                        ref={oldInput} /></Form.Item>
                    <Divider/>
                    <Form.Item label='새로운 비밀번호'
                               name="new_password"
                               rules={[{ required: true, message: '새로운 비밀번호를 입력해주세요'}]}>
                        <Input.Password placeholder='새로운 비밀번호 입력'
                                        ref={newInput} /></Form.Item>
                    <Form.Item label='비밀번호 재확인'
                               name="comp_password"
                               rules={[{ required: true, message: '확인용 비밀번호를 입력해주세요.'}]}>
                        <Input.Password placeholder='확인용 비밀번호 입력'
                                        ref={new2Input} /></Form.Item>
                    </Form>
                </Row>
            </Modal>

        </div>
    )
}

export default MyInfo;