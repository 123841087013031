import React, { useEffect, useState, useRef } from 'react'
import { Row, Col, Space, Table, Button, Modal, Input, Select, Form, notification, Card, Descriptions } from 'antd';
import { apiServer } from '../../utils/custom-axios';
import 'moment/locale/ko';
import {     
    columns_groups,
    continents_pagination,
    // option_orNotUse,
    // // option_division,
    // option_division2,
    // option_auth,
   } from '../.././common/datas/Datas';
import SelectBox from '../.././common/SelectBox';
import DeleteButton from '../../utils/modal-delbutton';

import { useLocation } from 'react-router-dom';

const { Search } = Input;
const { TextArea } = Input;
const { Option } = Select;

function Groups(props) {
    
    const [Division, setDivision] = useState('');

    const [Data, setData] = useState([]);
    const [SelectLowData, setSelectLowData] = useState({});
    const [SelectIndex, setSelectIndex] =  useState();
    const [PageSize, setPageSize] = useState(10);
    const [Modalonoff, setModalonoff] = useState(false);
    const [WModalonoff, setWModalonoff] = useState(false);
    const [ModalMode, setModalMode] = useState('읽기');
    const [filename, setFilename] = useState('');
    const [ModalTitle, setModalTitle] = useState('');
    const [codelist, setcodelist] = useState([]);
    const [nowselect, setnowselect] = useState('');
    const [inputOnOff, setInputOnOff] = useState(false);

    const [form] = Form.useForm();

    const codeInput  = useRef();
    const groupNameInput = useRef();

    // searh params parse
    let location = useLocation();
   
    let division = sessionStorage.getItem('division');
    let ssid = sessionStorage.getItem('SSID');
    let userid = sessionStorage.getItem('id');
    let userclass = sessionStorage.getItem('userClass');

    useEffect(() => {
        // let test = getNotice();
        // console.log('test',test)
        // setFilename('그룹');
        setDivision(division);
        getGroups();
    }, [])

    const runSerch = (value) => {
        // console.log('value',value)
        getGroups(value,1);
    }
    const getGroups = async (value, getMode = 0) => {

        let data = await apiServer.get('/groups',{ params : {'search' : value }}).then (response => response.data)
        //  console.log('data',data);
        if(data.msg !== 'Failed') {
            setData(data);
        }
        else if(data.recCount === 0) {
            setData([]);
            if(getMode !== 0) {
                Modal.info({
                    title : '검색된 그룹명이 없습니다.',
                    content : (
                        <><br />
                          검색 조건에 맞는 그룹명을 찾을 수 없습니다.
                        </>
                    ),
                    onOk() {},
                });
            }
        }
    }

    const UpdatePagination = (option) =>{

        setPageSize(option);
    }
    const clickselectRow = (record ,index) => {
        
        setSelectLowData(record);
        setSelectIndex(index);
        // console.log('record',record);
        showModal();
        //console.log('counter api', cdata);
        
    }
    const  WriteModal = () => {
        let record = {}
        record.division = '협력사';
        record.orNotUse = '정상';
        record.auth = '일반사용자';
        setnowselect('');
        setSelectLowData(record);
        setSelectIndex();
        setModalMode('쓰기');
        setModalTitle('그룹 등록');
        setWModalonoff(true);
        setInputOnOff(false);
    };

    const  showModal = () => {
        if (Division === '본사' && userclass === '관리자') {
            setWModalonoff(true);
            setModalMode('수정');
            setModalTitle('그룹 수정');
            setInputOnOff(true);
        }
        if (Division !== '본사' || userclass === '일반사용자') {
            setModalonoff(true);
            setModalMode('읽기');
            setModalTitle('그룹 상세보기');
        }
    };

    const  handleCancel = () => {
        getGroups();
        setModalonoff(false);
        setWModalonoff(false);
        form.resetFields();
        // if(ModalMode === '읽기' ){
        //     count_plus();}
    };

    const handleSubmit = (values) => {
        // console.log('ModalMode',ModalMode);
        // count_plus();
        if(typeof values.groupCode === 'undefined' || values.groupCode.length < 2) {
            openNotificationWithIcon('error', '그룹코드 오류', '그룹코드가 입력되지 않았거나 혹은 코드 두자리를 맞춰주세요.', 'topLeft');
            codeInput.current.focus();
        } else if( typeof values.groupName === 'undefined' ) {
            openNotificationWithIcon('error', '그룹명칭 오류', '그룹명칭이 입력되지 않았습니다.', 'topLeft');
            groupNameInput.current.focus();
        }
        else {

            if (ModalMode === '수정'){
                update_user(values);
            }
            if (ModalMode === '쓰기'){
                insert_user(values);
            }
            setModalonoff(false);
            setWModalonoff(false);
        }
        
    }

    const handleDelete = async (key) => {
        // 그룹 레코드 실제 DB 삭제
        let response =  await apiServer.delete(`/groups/${key}`).then ( res => res.data);

        if(response === "OK") {
            setModalonoff(false);
            setWModalonoff(false);
            getGroups();
        }
    }

    const insert_user = async ( values) => {
        
        let record = values;
        // const index = SelectIndex;
        
        record.firstWriter = userid;
        // record.counter = 1;
        // console.log(record,record);       
        let cdata = await apiServer.post('/groups',{record}).then ( res => res.data);
        // console.log('cdata',cdata,userid);      

        if(cdata === "OK") {
            openNotificationWithIcon('success', '그룹등록', '새로운 그룹을 등록했습니다.', 'topRight');
            getGroups();
        }
        else if(cdata === 'Failed') {
            openNotificationWithIcon('error', '그룹등록 실패', '새로운 그룹을 등록하는데 실패했습니다.', 'topLeft');
        }
        else if(cdata === 'Duplicate Key') {
            openNotificationWithIcon('error', '그룹 등록실패', '그룹명 혹은 그룹코드가 중복되어 등록하는데 실패했습니다.', 'topLeft');
        }
        else {
            Modal.info({
                title : '에러가 검출 되었습니다.',
                content : (
                    <><br />
                      {cdata}
                    </>
                ),
                onOk() {},
            });
        }
    }

    const update_user = async ( values ) => {

        let record = values;

        record.updateWriter = userid;
        record._id = SelectLowData._id;

        let cdata = await apiServer.put(`/groups/${record._id}`,{record}).then ( res => res.data);
         console.log('cdata',cdata,userid); 
        if(cdata === "OK") {
            openNotificationWithIcon('success', '그룹수정', '기존 그룹을 수정하였습니다.', 'topRight');
            setSelectLowData({});
            getGroups();
        }
        else if(cdata === 'Failed') {
            openNotificationWithIcon('error', '그룹명 수정 실패', '기존 그룹명을 수정하는데 실패했습니다.', 'topLeft');
        }
        else {
            Modal.info({
                title : '에러가 검출 되었습니다.',
                content : (
                    <><br />
                      {cdata}
                    </>
                ),
                onOk() {},
            });
        }
    }

    const inputHandler = (name,event) => {
       
        let record = SelectLowData;
        if(event.currentTarget.value === ''){
            record[name] = event.currentTarget.value;
        } else 
        { 
            record[name] = event.currentTarget.value;
        }

        setSelectLowData({...record});
    }

    const selectHandler = (name,event) => {

        let record = SelectLowData;
        console.log('event',name, event);
        record[name] = event;
       
        setSelectLowData({...record});
        console.log('record',record);

        if(name === "division"  && event === "본사") {
            setInputOnOff(true);
        } else if (name === "division" && event !== "본사") {
            setInputOnOff(false);
        }
    }

    const openNotificationWithIcon = (type, title, message, placement) => {
        notification[type]({
          message: title,
          description: message,
          placement,
        });
    };

    const optionRander = (name) =>{

        console.log('optionname',name);

    }
    const renderOption = (selectoption) => (
        selectoption && selectoption.map((value,index) => (
            <Option key={value._id} value={value.name} label={value.name} > {value.name} </Option>
        ))
    )
    const renderOption_code = (selectoption) => (
        selectoption && selectoption.map((value,index) => (
            <Option key={value._id} value={value._id} label={value.shopName} > {value.shopName} </Option>
        ))
    )
    const codeserch = async () => {

        let cdata = await apiServer.get('/groups',{ params : {'groupCode' : SelectLowData.groupCode }}).then ( res => res.data);

        if (cdata !== 'Overlap') {
            openNotificationWithIcon('success', '중복없음', '중복되는 코드가 없습니다.', 'topLeft');

            let record = SelectLowData;
            setcodelist(cdata);
            setnowselect(cdata[0].shopName) 
            record.divisionId = cdata[0]._id;
            setSelectLowData({...record});
        }       
        else {
            openNotificationWithIcon('error', '코드중복', '중복되는 코드가 있습니다. 다른 코드를 사용하세요.', 'topLeft');
        }
    }

    const getGroupName = () => {
        return form.setFieldsValue({ groupName : SelectLowData.groupName });
    }

   return(
    <div style={{ width: '100%' }}>
    <div style={{ textAlign: 'center' }}>
    <img src="images/title_head_groups_01.png" /><br /><br />
        <Row justify="center" >
            <Space size={50}>
            {Division === '본사' && userclass === '관리자' ? 
                <Button type="primary"
                onClick = {WriteModal}
                 > 그룹추가</Button>
            : null}
                <Search style={{width: 280}}
                allowClear
                placeholder="그룹명으로 검색해주세요."
                enterButton="검색"
                // size="large"
                onSearch={value => runSerch(value)}
                />
            <SelectBox
                list = {continents_pagination}
                focus_list = {PageSize}
                refreshFunction={UpdatePagination}>
            </SelectBox>
            </Space>
        </Row>
        <Row>
            <Col> <p> </p></Col>
        </Row>
        <Row justify="center">
        <Table style={{width: 600, cursor: 'pointer'}}
            dataSource={Data} 
            pagination={{ pageSize: PageSize, position: ['bottomCenter'] }}
            columns={columns_groups} 
            //pagination = {10}
            rowKey={Data => Data._id}
            onRow={(record,index) => ({
                onClick: () => {
                clickselectRow(record,index)
                },})}
            scroll={{ x: 600 }}
            size="small" >
        </Table>
        </Row>

        <Modal
            width={640}
            visible={Modalonoff}
            title={ModalTitle}
            onCancel={handleCancel}
            footer={[
                <Button key="back" onClick={handleCancel}>
                닫기
                </Button>
            ]}
            >
            <Row justify='center' type="flex">
                <Card style={{ width:600, borderRadius:6 }}>
                    <Descriptions bordered column={{xxl:2, xl:2, lg:2, md:2, sm:2, xs:1}}>
                        <Descriptions.Item label='그룹코드'>{SelectLowData.groupCode}</Descriptions.Item>
                        <Descriptions.Item label='그룹명칭'>{SelectLowData.groupName}</Descriptions.Item>
                    </Descriptions>
                </Card>
            </Row>
        </Modal>
        
        {/* 추가 및 수정 삭제 */}
        <Modal
            width={640}
            visible={WModalonoff}
            destroyOnClose={true}
            title={ModalTitle}
            onOk={form.submit}
            onCancel={handleCancel}
            footer={[
                <DeleteButton isAdmin={userclass} onDelete={handleDelete} deleteKey={SelectLowData._id} />,
                <Button key="back" onClick={handleCancel}>
                닫 기
                </Button>,
                <Button key="submit" type="primary" onClick={form.submit} style={{width:140}}>
                저  장
                </Button>
            ]}
            >
            <Row justify='center'>
                <Form form={form} onFinish={handleSubmit} style={{width:'80%'}}>
                    <Form.Item label="그룹코드"
                               name="groupCode"
                               rules={[{ required: true, message: '그룹코드를 반드시 입력해주세요.'}]}>
                        <span>
                        <Input placeholder='코드는 01 ~ 99까지 사용가능합니다.'
                               onChange={(e) => inputHandler('groupCode', e)}
                               ref={codeInput} style={{width:300}} 
                               value={SelectLowData.groupCode} 
                               defaultValue={form.setFieldsValue({ groupCode : SelectLowData.groupCode })} maxLength={2} disabled={inputOnOff} />
                        <Button
                            onClick = {codeserch}
                            style={{ }}
                            type="primary"
                            disabled={inputOnOff}
                            >중복확인</Button>
                        </span></Form.Item>
                    <Form.Item label="그룹명칭"
                               name="groupName"
                               rules={[{ required: true, message: '그룹명칭을 반드시 입력해주세요.'}]}>
                        <Input placeholder='그룹명칭'
                               onChange={(e) => inputHandler('groupName', e)}
                               ref={groupNameInput} 
                               value={SelectLowData.groupName} 
                               defaultValue={form.setFieldsValue({ groupName : SelectLowData.groupName })} /></Form.Item>
                    { inputOnOff ? <div style={{color:'darkblue'}}>수정모드에서는 그룹코드를 변경할 수 없습니다.<br /> 삭제 후 새로생성 해주십시요</div> : null }
                </Form>
            </Row>
        </Modal>
   </div>
   </div>
   ) 
}
export default Groups;