import { Popconfirm, Button } from 'antd';

function DeleteButton(props) {

    if(props.isAdmin === '관리자' && typeof props.deleteKey !== 'undefined') {
        return (
            <div style={{float:'left'}}>
            <Popconfirm title='정말로 삭제하시겠습니까 ?' onConfirm={() => props.onDelete(props.deleteKey)} okText="삭제" cancelText='취소'>
            <Button key="del" type="primary" danger>
                삭 제
            </Button>
            </Popconfirm>
            </div>
        )
    } 
    else {
        return (
            <> </>
        )
    }
}

export default DeleteButton;