import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { auth } from "../_actions/user_action";
import { useLocation, useNavigate } from "react-router-dom";

export default function (SpecificComponent, option, adminRoute = null ) {

    // option => 페이지 구분
    // null   아무나 출입가능 
    // true   로그인한 유저만 출입가능
    // false  로그인한 유저는 출입불가능
    //console.log('root adminRoute : ', adminRoute)

    function AuthenticationCheck(props) {

        //console.log('props',props)
        let navigate = useNavigate();
        const dispatch = useDispatch();

        let location = useLocation();
        let division = sessionStorage.getItem('division')

        //console.log('Auth 작동시작')

        useEffect(() => {

            dispatch(auth()).then(res => {
                    // console.log('payload', res.payload);
                    // console.clear();
                    // console.log('isAuth', res.payload.isAuth);
                    //  console.log('isAdmin', res.payload.isAdmin);
                    // console.log('option 로그인사용자 접근유무', option);
                    // console.log('SSID', sessionStorage.getItem('SSID'));
                    
                    // 로그인하지 않은 상태  => isAuth : false
                    // 혹은 서버의 세션이 만료된 상태

                    if(!res.payload.isAuth) {
                        
                        
                        sessionStorage.clear();
                        localStorage.clear();

                        // 로그인 하지 않은 상태인데 
                        // 로그인 유저만 출입가능한 곳으로 오면
                        // login 으로 보낸다.
                        if (option === true) {
                            if(location.pathname !== '/login') navigate("/login");
                        }

                    } else {
    
                        // 로그인 한 상태일때  => isAuth : true
                        // adminRoute : 각 division 만 들어갈수 있는 페이지
                        if(adminRoute !== res.payload.division )  {

                            // 본사직원이 아닌데 본사직원만 들어갈수 있는 페이지로 접근할떄
                            if(adminRoute !== 'all') {
                                navigate('/404');
                            } 
                         } 
                         else {
                            
                            // 로그인상태 
                            // 본사 직원인데 로그인 유저는 출입불가능한 곳으로 오면
                            if(option === true) {
                                if(location.pathname === '/login') navigate("/"); 
                            }
                         }
                    }
                    
            });

        }, []) 

        return (
            <SpecificComponent {...props} />    
        )
    }
    // return AuthenticationCheck()
    return <AuthenticationCheck />;
}