import React from 'react';
import { Typography, Tabs, Row, Image, Avatar, Divider, BackTop } from 'antd';

const { TabPane } = Tabs;
const { Title } = Typography;

function help1() {

    return(
        <>
        <Row justify='center'>
            <div style={{marginTop:30, color:'#fa541c'}}>* 원하는 설명을 클릭하세요. 모든 이미지는 마우스를 클릭하면 크게 확대됩니다.<p /></div>
        </Row>
        <Row justify='center'>
            <Tabs defaultActiveKey="0" style={{width:1200}}>
            <TabPane tab='협력사 작업메뉴' key='0'>
                    <p><br /><br />
                        본 프로그램은 제품을 제공하여 매출이 일어난 전표를 GND 회사의 서버로 등록하여 청구서가 자동발행 되도록 하는 인터넷 프로그램입니다.<p />
                        간단하게 메뉴를 살펴보고 어떤 작업 방식으로 해나가는지 알아보겠습니다.
                        <br /><br />
                        <center><Image src='/manual/screenshot/협력사-메뉴.png' /></center>
                        <br /><br />
                        <Row>
                            <Avatar style={{ backgroundColor: '#fa8c16', verticalAlign: 'bottom' }} size={24}>1</Avatar> &nbsp;&nbsp;
                            <Title level={4}> GND 본사에서 올리는 각종 공지사항이 올라옵니다. 항상 주의깊게 살펴봐주세요.</Title>
                        </Row>
                        <br /><br />
                        <Row>
                            <Avatar style={{ backgroundColor: '#fa8c16', verticalAlign: 'bottom' }} size={24}>2</Avatar> &nbsp;&nbsp;
                            <Title level={4}> 본 프로그램에서 가장 중요한 매출등록 입니다. 발생한 매출 전표를 엑셀파일로 올리면 자동으로 에러를 찾아 등록할 수 있는 상태로 만들어주고 GND 본사로 전표를 전송하게 됩니다.</Title>
                        </Row>
                        <br /><br />
                        <Row>
                            <Avatar style={{ backgroundColor: '#fa8c16', verticalAlign: 'bottom' }} size={24}>3</Avatar> &nbsp;&nbsp;
                            <Title level={4}> 전송된 매출전표를 확인하거나 할때 사용됩니다. 승인 이전에는 수정도 가능합니다.</Title>
                        </Row>
                        <br /><br />
                        <Row>
                            <Avatar style={{ backgroundColor: '#fa8c16', verticalAlign: 'bottom' }} size={24}>4</Avatar> &nbsp;&nbsp;
                            <Title level={4}> 사용자의 비밀번호 변경, 로그아웃이 있습니다.</Title>
                        </Row>
                        <br /><br />
                        <Row>
                            <Avatar style={{ backgroundColor: '#fa8c16', verticalAlign: 'bottom' }} size={24}>5</Avatar> &nbsp;&nbsp;
                            <Title level={4}> 현재 사용하면서 보고 계신 화면입니다.</Title>
                        </Row>
                    </p>
                    <BackTop />
                </TabPane>
                <TabPane tab='매출등록 방법' key='1'>
                    <p><br /><br />
                        매출등록 메뉴는 각 거래처에 발생한 매출을 엑셀파일로 정리한후 등록하기 쉽도록 만들어진 메뉴입니다.<p />
                        <br /><br />
                        <Row>
                            <Avatar style={{ backgroundColor: '#fa8c16', verticalAlign: 'bottom' }} size={24}>1</Avatar> &nbsp;&nbsp;
                            <Title level={4}> 매출등록 메뉴를 선택하면 아래와 같은 화면이 나옵니다.</Title>
                        </Row>
                        <center><Image src='/manual/screenshot/협력사-매출등록.png' /></center>
                        <br /><br />
                        <Row>
                            <Avatar style={{ backgroundColor: '#fa8c16', verticalAlign: 'bottom' }} size={24}>2</Avatar> &nbsp;&nbsp;
                            <Title level={4}> 엑셀파일을 등록하기 위해서 '찾아보기' 버튼을 누릅니다.</Title>
                        </Row>
                        <center><Image src='/manual/screenshot/협력사-엑셀자료등록1.png' /></center>
                        <br /><br />
                        <Row>
                            <Avatar style={{ backgroundColor: '#fa8c16', verticalAlign: 'bottom' }} size={24}>3</Avatar> &nbsp;&nbsp;
                            <Title level={4}> 컴퓨터에 있는 엑셀파일을 찾아서 선택해줍니다.</Title>
                        </Row>
                        <center><Image src='/manual/screenshot/협력사-엑셀자료등록2.png' /></center>
                        <br /><br />
                        <Row>
                            <Avatar style={{ backgroundColor: '#fa8c16', verticalAlign: 'bottom' }} size={24}>4</Avatar> &nbsp;&nbsp;
                            <Title level={4}> 등록된 엑셀파일을 화면에 가져오기 위해 '선택파일적용'을 누릅니다.</Title>
                        </Row>
                        <center><Image src='/manual/screenshot/협력사-엑셀자료등록3.png' /></center>
                        <br /><br />
                        <Row>
                            <Avatar style={{ backgroundColor: '#fa8c16', verticalAlign: 'bottom' }} size={24}>5</Avatar> &nbsp;&nbsp;
                            <Title level={4}> 자료가 등록이 되면 다음과 같은 화면이 나옵니다.</Title>
                        </Row>
                        <center><Image src='/manual/screenshot/협력사-엑셀자료등록4.png' /></center>
                        <br /><br />
                        <Row>
                            <Avatar style={{ backgroundColor: '#fa8c16', verticalAlign: 'bottom' }} size={24}>6</Avatar> &nbsp;&nbsp;
                            <Title level={4}> 에러가 없는 정상등록이 가능할때 <span style={{color:'red'}}>'서버로전송' 버튼이 파랗게 활성화</span> 됩니다.<br />
                                              해당 버튼을 눌러 자료를 서버로 전송하시면 매출 등록이 끝나게 됩니다.</Title>
                        </Row>
                    </p>
                    <BackTop />
                </TabPane>
                <TabPane tab='등록매출 추가 방법' key='2'>
                    <br /><br />
                        누락된 매출을 간단하게 추가할 수 있는 기능입니다. 급하게 추가하거나 해야할 경우가 있을때 바로 입력이나 수정이 가능합니다.<p />
                        <Title level={4} type='danger'> [권장방법] 될 수 있으면 엑셀파일을 수정해서 올리는 것이 자료를 일정하게 유지하는 좋은 방법입니다.</Title>
                    <br /><br />
                    <Row>
                            <Avatar style={{ backgroundColor: '#fa8c16', verticalAlign: 'bottom' }} size={24}>1</Avatar> &nbsp;&nbsp;
                            <Title level={4}> 상단의 추가등록 버튼을 눌러줍니다.</Title>
                    </Row>
                    <br /><br />
                    <center><Image src='/manual/screenshot/협력사-매출등록입력폼.png' /></center>
                    <br /><br />
                    <Row>
                            <Avatar style={{ backgroundColor: '#fa8c16', verticalAlign: 'bottom' }} size={24}>2</Avatar> &nbsp;&nbsp;
                            <Title level={4}> 위와 같은 추가등록 입력폼이 나타나면 입력을 해줍니다.</Title>
                    </Row>
                    <br /><br />
                    <center><Image src='/manual/screenshot/협력사-매출등록,수정 자동완성.png' /></center>
                    <br /><br />
                    <Row>
                            <Avatar style={{ backgroundColor: '#fa8c16', verticalAlign: 'bottom' }} size={24}>3</Avatar> &nbsp;&nbsp;
                            <Title level={4}> 가맹점별칭은 자동완성 기능이 되어있습니다. 아무것도 입력하지 않은 상태에서는 전체 가맹점을, 무극을 치면 무극이 들어가 있는 가맹점을 차례로 보여주고, 아이데크를 치면 아이데크가 들어간 가맹점만을 보여줍니다.
                            하단에서 화살표키나 마우스로 선택하시면 자동으로 가맹점별칭과 가맹점 코드번호를 가져오게 됩니다.</Title>
                    </Row>
                    <br /><br />
                    <Row>
                            <Avatar style={{ backgroundColor: '#fa8c16', verticalAlign: 'bottom' }} size={24}>4</Avatar> &nbsp;&nbsp;
                            <Title level={4}> 이후 나머지 입력내용을 완료하신후에 '저장' 버튼을 눌러주시면 목록에 추가가 됩니다.</Title>
                    </Row>
                    <BackTop />
                </TabPane>
                <TabPane tab='등록매출에 에러나 수정사항이 있을때' key='3'>
                    <br /><br />
                        등록한 매출이 에러가 있을때 수정하는 방법으로 등록매출 추가 및 수정과 동일한 방법으로 진행됩니다.<p />
                        <Title level={4} type='danger'> [권장방법] 될 수 있으면 엑셀파일을 수정해서 올리는 것이 자료를 일정하게 유지하는 좋은 방법입니다.</Title>
                        <br /><br />
                    <center><Image src='/manual/screenshot/협력사-매출등록에러.png' /></center>
                    <br /><br />
                    <Row>
                            <Avatar style={{ backgroundColor: '#fa8c16', verticalAlign: 'bottom' }} size={24}>1</Avatar> &nbsp;&nbsp;
                            <Title level={4}> 위와 같이 에러가 있을때는 하단에 등록불가 에러메세지 창이 뜨게되고, 각 항목에 에러마킹이 됩니다.</Title>
                    </Row>
                    <br /><br />
                    <center><Image src='/manual/screenshot/협력사-매출등록에러수정.png' /></center>
                    <br /><br />
                    <Row>
                            <Avatar style={{ backgroundColor: '#fa8c16', verticalAlign: 'bottom' }} size={24}>2</Avatar> &nbsp;&nbsp;
                            <Title level={4}> 에러가 있는 항목의 행(가로:Row)을 눌러줍니다.</Title>
                    </Row>
                    <br /><br />
                    <center><Image src='/manual/screenshot/협력사-매출수정폼.png' /></center>
                    <br /><br />
                    <Row>
                            <Avatar style={{ backgroundColor: '#fa8c16', verticalAlign: 'bottom' }} size={24}>3</Avatar> &nbsp;&nbsp;
                            <Title level={4}> 에러가 있는 항목을 수정해줍니다. </Title>
                    </Row>
                    <br /><br />
                    <center><Image src='/manual/screenshot/협력사-가맹점별칭.png' /></center>
                    <br /><br />
                    <Row>
                            <Avatar style={{ backgroundColor: '#fa8c16', verticalAlign: 'bottom' }} size={24}>4</Avatar> &nbsp;&nbsp;
                            <Title level={4}> 가맹점 별칭은 자동완성 기능이 되어 있습니다. 마우스를 눌러서 아래중에 선택하셔도 되고, 키보드를 통해 일정단어만 입력해도 동일한 단어를 포함하는 모든 가맹점을 찾아줍니다. 이후에 선택하셔도 됩니다. </Title>
                    </Row>
                    <br /><br />
                    <center><Image src='/manual/screenshot/협력사-자동가맹점코드.png' /></center>
                    <br /><br />
                    <Row>
                            <Avatar style={{ backgroundColor: '#fa8c16', verticalAlign: 'bottom' }} size={24}>5</Avatar> &nbsp;&nbsp;
                            <Title level={4}> 가맹점 별칭이 선택되면 자동으로 가맹점 코드를 가져옵니다. </Title>
                    </Row>
                    <br /><br />
                    <Row>
                            <Avatar style={{ backgroundColor: '#fa8c16', verticalAlign: 'bottom' }} size={24}>6</Avatar> &nbsp;&nbsp;
                            <Title level={4}> 수정이 완료되었으면 '저장' 버튼을 눌러주세요. </Title>
                    </Row>
                    <br /><br />
                    <center><Image src='/manual/screenshot/협력사-에러수정후.png' /></center>
                    <br /><br />
                    <Row>
                            <Avatar style={{ backgroundColor: '#fa8c16', verticalAlign: 'bottom' }} size={24}>7</Avatar> &nbsp;&nbsp;
                            <Title level={4}> 수정된 내용이 해당 가로행에 잘 적용되었습니다. </Title>
                    </Row>
                    <BackTop />
                </TabPane>
                <TabPane tab='등록매출 조회 / 일괄삭제 / 수정' key='4'>
                    <br /><br />
                        GND 서버로 등록된 매출자료를 조회할때 사용됩니다. 마감이 완료되지 않은 레코드는 수정이 가능합니다.<p />
                        <br /><br />
                    <center><Image src='/manual/screenshot/협력사-매출조회.png' /></center>
                    <br /><br />
                    <Row>
                            <Avatar style={{ backgroundColor: '#fa8c16', verticalAlign: 'bottom' }} size={24}>1</Avatar> &nbsp;&nbsp;
                            <Title level={4}> GND 서버에 기록된 매출조회를 위한 기본화면입니다. </Title>
                    </Row>
                    <br /><br />
                    <center><Image src='/manual/screenshot/협력사-매출조회-기간넣기.png' /></center>
                    <br /><br />
                    <Row>
                            <Avatar style={{ backgroundColor: '#fa8c16', verticalAlign: 'bottom' }} size={24}>2</Avatar> &nbsp;&nbsp;
                            <Title level={4}> 날짜창을 눌러 조회하고 싶은 매출자료의 기간을 먼저 설정해줍니다.</Title>
                    </Row>
                    <br /><br />
                    <center><Image src='/manual/screenshot/협력사-매출조회-기간검색.png' /></center>
                    <br /><br />
                    <Row>
                            <Avatar style={{ backgroundColor: '#fa8c16', verticalAlign: 'bottom' }} size={24}>3</Avatar> &nbsp;&nbsp;
                            <Title level={4}> 날짜설정이 완료되었으면 검색을 눌러줍니다.</Title>
                    </Row>
                    <br /><br />
                    <center><Image src='/manual/screenshot/협력사-매출조회-기간조회후.png' /></center>
                    <br /><br />
                    <Row>
                            <Avatar style={{ backgroundColor: '#fa8c16', verticalAlign: 'bottom' }} size={24}>4</Avatar> &nbsp;&nbsp;
                            <Title level={4}> 조회 된 매출자료가 화면에 표시됩니다. <br />
                            '마감'태그가 초록색아이콘이 나온것은 마감이 완료된 자료이고, 빨간색 X 아이콘은 마감이 완료되지 않은 자료입니다.</Title>
                    </Row>
                    <br /><br />
                    <center><Image src='/manual/screenshot/협력사-매출조회-마감자료.png' /></center>
                    <br /><br />
                    <Row>
                            <Avatar style={{ backgroundColor: '#fa8c16', verticalAlign: 'bottom' }} size={24}>5</Avatar> &nbsp;&nbsp;
                            <Title level={4}> 마감이 완료된 자료는 마우스로 클릭해도 수정이나 삭제를 할 수 없습니다. 'X'로 표시된 마감자료는 수정을 할 수 있습니다.</Title>
                    </Row>

                    <Divider orientation="left">일괄삭제 기능</Divider>
                    <br /><br />
                    <Row>
                        <center><Image src='/manual/screenshot/협력사-매출조회-일괄삭제1.png' />
                                <Image src='/manual/screenshot/협력사-매출조회-일괄삭제2.png' /></center>
                    </Row>
                    <br /><br />
                    <Row>
                            <Avatar style={{ backgroundColor: '#fa8c16', verticalAlign: 'bottom' }} size={24}>1</Avatar> &nbsp;&nbsp;
                            <Title level={4}> 일괄삭제는 마감되지 않은 자료에만 적용됩니다. <br />
                                            마감된 자료는 삭제에 포함되도 실제로 삭제되지 않습니다.<br />
                                            머리말 창에 있는 □ 를 누르면 화면에 보이는 자료는 전부다 체크됩니다.<br />
                                            혹은 개별적으로 원하는 자료만 눌러서 삭제가 가능합니다. (하나만 선택해도 가능합니다.)<br />
                                            선택이 완료되면 '일괄삭제' 버튼을 눌러서 삭제를 실행해주세요.</Title>
                    </Row>

                    <Divider orientation="left">등록 자료 수정</Divider>
                    <br /><br />
                        <center><Image src='/manual/screenshot/협력사-매출조회-수정1.png' /></center>
                    <br /><br />
                    <Row>
                            <Avatar style={{ backgroundColor: '#fa8c16', verticalAlign: 'bottom' }} size={24}>1</Avatar> &nbsp;&nbsp;
                            <Title level={4}> 마감이 완료되지 않은 자료만 수정할 수 있습니다.<br />
                                            다른 수정과 마찬가지로 수정하고 싶은 레코드 행 (가로:Row)를 마우스로 클릭해줍니다.</Title>
                    </Row>
                    <br /><br />
                        <center><Image src='/manual/screenshot/협력사-매출조회-수정2.png' /></center>
                    <br /><br />
                    <Row>
                            <Avatar style={{ backgroundColor: '#fa8c16', verticalAlign: 'bottom' }} size={24}>2</Avatar> &nbsp;&nbsp;
                            <Title level={4}> 내용을 수정한 후에 저장을 누르시면 됩니다.</Title>
                    </Row>
                    <BackTop />
                </TabPane>
                <TabPane tab='엑셀 파일 양식' key='5'>
                    <p><br /><br />
                        엑셀 파일 양식은 본 프로그램에서 제공하는 양식을 사용하셔야 합니다.
                        <br /><br />
                        <center><Image src='/manual/screenshot/협력사-양식다운로드.png' /></center>
                        <br /><br />
                        <Row>
                            <Avatar style={{ backgroundColor: '#fa8c16', verticalAlign: 'bottom' }} size={24}>1</Avatar> &nbsp;&nbsp;
                            <Title level={4}> 매출등록 메뉴에서 양식다운로드 버튼을 눌러 양식을 다운받습니다.</Title>
                        </Row>
                        <br /><br />
                        <center><Image src='/manual/screenshot/협력사-양식다운로드2.png' /></center>
                        <br /><br />
                        <Row>
                            <Avatar style={{ backgroundColor: '#fa8c16', verticalAlign: 'bottom' }} size={24}>2</Avatar> &nbsp;&nbsp;
                            <Title level={4}> 화면하단에 보면 다운로드된 양식이 보입니다. 이 파일을 사용하시면 됩니다.</Title>
                        </Row>
                        <br /><br />
                        <center><Image src='/manual/screenshot/협력사-엑셀양식.png' /></center>
                        <br /><br />
                        <Row>
                            <Avatar style={{ backgroundColor: '#fa8c16', verticalAlign: 'bottom' }} size={24}>3</Avatar> &nbsp;&nbsp;
                            <Title level={4}> 다운받은 엑셀의 기본양식입니다.</Title>
                        </Row>
                        <br /><br />
                        <center><Image src='/manual/screenshot/협력사-엑셀양식2.png' /></center>
                        <br /><br />
                        <Row>
                            <Avatar style={{ backgroundColor: '#fa8c16', verticalAlign: 'bottom' }} size={24}>3</Avatar> &nbsp;&nbsp;
                            <Title level={4}> 위와 동일한 형태로 편집을 하신후에 '다른 이름으로 저장' 하신후에 매출등록에서 사용하시면 됩니다.</Title>
                        </Row>
                    </p>
                    <BackTop />

                </TabPane>

            </Tabs>
        </Row>
        </>
    )
}
export default help1;