import React, { useState, useEffect, useMemo, useRef } from 'react'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import axios from "axios";
import {API_URL, IMG_URL} from "../config"

function Editbox(props) {
 

    const QuillRef = useRef();
    const [contents, setContents] = useState("");
    // let _modules = useMemo();

    useEffect(() => {

    
        setContents(props.value);
        //console.log('props',props);
        // if(props.readOnly === true){_modules = modules_Readonly} else {_modules = modules}
        
    },[])
    const inputHandler = (e) => {

        // console.log('e',e);
        setContents(e);
        props.refreshFunction(e);

    }
    // 이미지를 업로드 하기 위한 함수
    const imageHandler = () => {
        // 파일을 업로드 하기 위한 input 태그 생성
      const input = document.createElement("input");
      const formData = new FormData();
      let url = "";
  
      input.setAttribute("type", "file");
      input.setAttribute("accept", "image/*");
      input.click();
  

      input.onchange = async () => {
        //   console.log('onchange');
         const file = input.files;
        if (file !== null) {
          formData.append("image", file[0]);

        //   const res = axios.post('/editoruploads', formData, {
        //     headers: {
        //       'Content-Type': 'multipart/form-data'
        //     }
        //     })
        
        //   console.log('res',res);
        
            
          try {
            // const res = axios를 통해 백엔드 개발자분과 통신했고, 데이터는 폼데이터로 주고받았습니다.
            // const res = apiServer.post('/editoruploads',{formData}).then ( res => res.data);
            const res = await axios.post(`${API_URL}/editoruploads`, formData, {
                headers: {
                  'Content-Type': 'multipart/form-data'
                }
            })
            
            // console.log('res',res);
            
            url = `${IMG_URL}/` + res.data;
            //console.log('url',url);
  

            const range = QuillRef.current?.getEditor().getSelection()?.index;
            if (range !== null && range !== undefined) {
              let quill = QuillRef.current?.getEditor();
  
              quill?.setSelection(range, 1);
  
              quill?.clipboard.dangerouslyPasteHTML(
                range,
                `<img src=${url} />`
              );
            }
  
            return { ...res, success: true };
          } catch (error) {
            const err = error;
            return { ...err.response, success: false };
          }
        }
      };
    };
  
  // quill에서 사용할 모듈을 설정하는 코드 입니다.
  // 원하는 설정을 사용하면 되는데, 저는 아래와 같이 사용했습니다.
  // useMemo를 사용하지 않으면, 키를 입력할 때마다, imageHandler 때문에 focus가 계속 풀리게 됩니다.
  const modules = useMemo(
      () => ({
        toolbar: {
          container: [
            ["bold", "italic", "underline", "strike", "blockquote"],
            [{ size: ["small", false, "large", "huge"] }, { color: [] }, { background : [] }],
            [ 'clean' ],
            [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
            [
              { list: "ordered" },
              { list: "bullet" },
              { indent: "-1" },
              { indent: "+1" },
              { align: [] },
            ],
            ["image", "video"],
          ],
          handlers: {
            image: imageHandler,
          },
        },
        clipboard: {
          matchVisual: false          
        }
      }),
      []
    );
    const modules_Readonly = useMemo(
      () => ({
        
        toolbar: false,
          
        clipboard: {
          matchVisual: false          
        }
      }),
      []
    );
  
  return (
      <>
      {props.readOnly === false ?
        <ReactQuill
                 ref={(element) => {
                    if (element !== null) {
                      QuillRef.current = element;
                    }
                  }}
                  readOnly={props.readOnly}
                  value={contents}
                  onChange={(e) => inputHandler(e)}
                  modules={modules}
                  theme="snow"
                  placeholder="내용을 입력해주세요."
                />
         :       
         <ReactQuill
                 ref={(element) => {
                    if (element !== null) {
                      QuillRef.current = element;
                    }
                  }}
                  readOnly={props.readOnly}
                  value={contents}
                  onChange={(e) => inputHandler(e)}
                  modules={modules_Readonly}
                  theme="snow"
                 // placeholder="내용을 입력해주세요."
                />
        }
      </>
  )

}

export default Editbox