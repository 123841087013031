import React, { useEffect, useState } from 'react'
import { Row, Space, Table, Button, Input, Select, DatePicker, notification } from 'antd';
import moment from 'moment';
import { apiServer } from '../../utils/custom-axios';
import 'moment/locale/ko';
import {     
    columns_invoices,
    columns_invoices2,
    columns_invoicestotal,
   } from '../../common/datas/Datas';
import DownloadExcel from '../../common/DownloadExcel';
// import DownloadExcelMultyTable from '../../common/DownloadExcelMultyTable';
// import DownloadExcelMultyTable from '../../common/DownloadExcelMultyTable';
import 'moment/locale/ko';
import locale from 'antd/es/date-picker/locale/ko_KR'

const { Search } = Input;
const { TextArea } = Input;
const { Option } = Select;
const { MonthPicker, RangePicker } = DatePicker;
// const dateFormat = 'YYYY-MM-DD';
const dateFormat = 'YYYY-MM';

function Invoices(props) {

    // const [form] = Form.useForm();

    const [Division, setDivision] = useState('');

    const [Data, setData] = useState([]);
    const [Data2, setData2] = useState([]);
    const [Data2temp, setData2temp] = useState([]);
    const [DataTotal, setDataTotal] = useState([]);
    const [DataAll, setDataAll] = useState({});
    const [SelectLowData, setSelectLowData] = useState({});
    const [SelectIndex, setSelectIndex] =  useState();
    // const [DataTotaltemp, setDataTotaltemp] = useState([]);

    const [PageSize, setPageSize] = useState(10);

    const [Dates,setDates] = useState('');
    const [DatesString,setDatesString] = useState('');

    let division = sessionStorage.getItem('division');
    let ssid = sessionStorage.getItem('SSID');
    let userid = sessionStorage.getItem('id');
    let userclass = sessionStorage.getItem('userClass');
    let divisionId = sessionStorage.getItem('divisionId');

    useEffect(() => {
                // setDates(dates);
        //setFilename('매출');
        setDivision(division);
        getDate();
        
    }, [])

    const getDate = async () => {

        let thismonth = moment();
        setDates(thismonth);
        
        setDatesString(moment(thismonth).format(dateFormat));
    }

    const runSerch = () => {
        let dates = Dates;

        getinvoices(dates);
    }

    const getinvoices = async () => {

        let data = await apiServer.get('/invoices',{ params : {'divisionId' : divisionId, 'inputDate' : DatesString}}).then (response => response.data)

        console.log('get invoices data',data);
        console.log('get invoices data',data.invoices.length);

        if(data.invoices.length === 0) {
            openNotificationWithIcon('error', '청구서 조회', '아직 제조사에서 등록된 청구서가 없습니다.');
            // 테이블 값을 초기화 해야한다.
            setData([]);
            setData2temp([]);
            setDataTotal([]);
        }
        else {

            let tempData1 = data.invoices;
            setDataAll(data);
    
            let cdata = [];
            cdata.invociesMonth = '합계';
            cdata.partnersName = '';
            let total = 0;
            tempData1 && tempData1.map(invoices => total = total + parseInt(invoices.summary));
            cdata.summary = total;
            
            if(tempData1.length > 0) {
                tempData1.push(cdata);
            }
            setData(tempData1);
    
            setData2(data.invoicesDetails);
            setData2temp(data.invoicesDetails);
            setDataTotal(data.total)
        }
    }

    const clickselectRow = (record ,index) => {
        let datas = Data2;
        setSelectLowData(record);
        setSelectIndex(index);
        
        let partnersCode = record.partnersCode;

        let cdatas = [];
        if( typeof partnersCode === 'undefined') {
            cdatas = datas;
        } else {
            cdatas = datas && datas.filter(data => data.partnersCode === partnersCode)
        }

        setData2temp(cdatas);
    }

     const rangedatepickerhandler = (thismonth,stringmonth) =>{
        setDates(thismonth);
        setDatesString(stringmonth);
     }

     const DownloadUrl = async () => {

        let response = await apiServer.get('/invoices', {responseType:'blob', params : {'type': 'xlsx', 'divisionId' : divisionId, 'inputDate' : DatesString}});

        if(response.data.size < 800) {
            openNotificationWithIcon('error', '청구서 엑셀 다운로드', '등록된 청구서가 없어서 엑셀 파일로 다운로드 받기를 할 수 없습니다.');
        }
        else {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", '매입처별-'+DatesString+'.xlsx');
            link.style.cssText = "display:none";
            document.body.appendChild(link);
            link.click();
            link.remove();
        }
    }

    const openNotificationWithIcon = (type, title, message) => {
        notification[type]({
          message: title,
          description: message,
        });
    };

   return(
    <div style={{ width: '100%' }}>
    <div style={{ textAlign: 'center' }}>
    <img src="images/title_head_invoices_01.png" /><br /><br />
        <Row justify='center' >
          <Space size={[200, 10]} wrap>
            <span>
            <DatePicker 
                value = {Dates}
                defaultValue={Dates} 
                format={dateFormat}
                locale={locale}
                onChange={(e,f) => rangedatepickerhandler(e,f)}
                picker="month" />
            <Button 
                type="primary"
                onClick = {value => runSerch(value)}>검 색</Button>
            </span>
            <Button onClick={() => DownloadUrl()} type='primary'>Excel파일로 다운</Button>
          </Space>
        </Row>
        <p />
        <Row justify='center'>
            <Table  style={{cursor : 'pointer', width:1200}}
                    dataSource={Data} 
                    pagination={false}
                    columns={columns_invoices} 
                    // pagination = {10}
                    bordered
                    rowKey={Data => Data._id}
                    onRow={(record,index) => ({
                        onClick: () => {
                        clickselectRow(record,index)
                    },})}
                    scroll={{ x: 1000 }} size="small">
            </Table>
            <br /><br />
            <Table  style={{cursor : 'pointer', width:1200}}
                dataSource={Data2temp} 
                pagination={{ pageSize: PageSize, position: ['bottomCenter'] }}
                columns={columns_invoices2} 
                rowKey={Data => Data._id}
                bordered
                scroll={{ x: 1000 }} size="small">
            </Table>
            <br /><br />
            <Table  style={{cursor : 'pointer', width:1200}}
                dataSource={DataTotal} 
                pagination={false}
                columns={columns_invoicestotal} 
                rowKey={Data => Data._id}
                bordered
                scroll={{ x: 1000 }} size="small">
            </Table>
        </Row>
   </div>
   </div>
   ) 
}
export default Invoices;