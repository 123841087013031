import React, { useEffect, useState } from 'react'
import axios from "axios";
import { Row, Col, Form, Table, Button, notification, Space, Modal, Input, Select, Card, DatePicker, Descriptions } from 'antd';
import Uploads from '../../common/upload/uploads';
import moment from 'moment';
import { apiServer } from '../../utils/custom-axios';
import 'moment/locale/ko';
import locale from 'antd/es/date-picker/locale/ko_KR'
import {     
    columns_partners,
    continents_pagination,
    option_orNotUse2,
    option_category,
    option_businessClass,
    option_country,
   } from '../.././common/datas/Datas';
import SelectBox from '../.././common/SelectBox';
import DownloadExcel from '../.././common/DownloadExcel';
import DaumPostcode from 'react-daum-postcode';
import { useLocation } from 'react-router-dom';
import InputMask from 'react-input-mask';

import DeleteButton from '../../utils/modal-delbutton';

const { Search } = Input;
const { TextArea } = Input;
const { Option } = Select;
const { MonthPicker, RangePicker } = DatePicker;
const dateFormat = 'YYYY-MM-DD';

function Partners(props) {

    const [Division, setDivision] = useState('');

    const [Data, setData] = useState([]);
    const [SelectLowData, setSelectLowData] = useState({});
    const [SelectIndex, setSelectIndex] =  useState();
    const [PageSize, setPageSize] = useState(10);
    const [Modalonoff, setModalonoff] = useState(false);
    const [WModalonoff, setWModalonoff] = useState(false);
    const [ModalMode, setModalMode] = useState('읽기');
    const [filename, setFilename] = useState('');
    const [GroupData, setGroupData] = useState([]);
    const [Searchtext, setSearchtext] = useState({});
    const [ModalTitle, setModalTitle] = useState('');

    const [address, setAddress] = useState(''); // 주소
    const [addressDetail, setAddressDetail] = useState(''); // 상세주소
    const [isOpenPost, setIsOpenPost] = useState(false);

    const [form] = Form.useForm();

    // searh params parse
    let location = useLocation();
   
    let division = sessionStorage.getItem('division');
    let ssid = sessionStorage.getItem('SSID');
    let userid = sessionStorage.getItem('id');
    let userClass = sessionStorage.getItem('userClass');

    useEffect(() => {
        let data = {'search' : '' ,'orNotUse' : '','businessClass' : '', 'country':''};
        setSearchtext(data);
        setFilename('협력사');
        setDivision(division);
        getGroupData();
        getPartners();
    }, [])

    const runSerch = (value) => {
        let body = Searchtext;
        
        body['search'] = value;

        //console.log('value',value)
        setSearchtext(body);
        getPartners(1);
    }

    const getPartners = async (getMode = 0) => {

        let data = await apiServer.get('/partners',{ params : {'search' : Searchtext.search ,'orNotUse' : Searchtext.orNotUse, 'businessClass' : Searchtext.businessClass, 'country' : Searchtext.country }}).then (response => response.data)

        if(data.msg !== 'Failed') {
            setData(data);
        }
        else if(data.recCount === 0) {
            setData([]);
            if(getMode !== 0) {
                Modal.info({
                    title : '검색된 협력업체가 없습니다.',
                    content : (
                        <><br />
                          검색 조건에 맞는 협력업체를 찾을 수 없습니다.
                        </>
                    ),
                    onOk() {},
                });
            }
        }
    }

    const getGroupData = async (value) => {

        let data = await apiServer.get('/group-all-code').then (response => response.data)
        setGroupData(data);
    }

    const UpdatePagination = (option) =>{

        setPageSize(option);
    }

    const clickselectRow = (record ,index) => {
        
        setSelectLowData(record);
        setSelectIndex(index);
        // console.log('record',record);
        showModal();
    }

    const  WriteModal = () => {
        setSelectLowData({});
        setSelectIndex();
        setModalMode('쓰기');
        setModalTitle('협력사 등록');
        setWModalonoff(true);
    };

    const  showModal = () => {
        
        if(Division === '본사' && userClass === '관리자') {
            setWModalonoff(true);
            setModalMode('수정');
            setModalTitle('협력사 수정');
        }
        if(Division !== '본사' || userClass === '일반사용자') {
            setModalonoff(true);
            setModalMode('읽기');
        }
    };

    const  handleCancel = () => {
        getPartners();
        setModalonoff(false);
        setWModalonoff(false);
        form.resetFields();
    };

    const  handleOk = () => {
        if (ModalMode === '수정'){
            update_partners();
        }
        if (ModalMode === '쓰기'){
            insert_partners();
        }
        setModalonoff(false);
        setWModalonoff(false);
    };

    const insert_partners = async (values) => {
        
        let record = values;

        record.firstWriter = userid;
        let cdata = await apiServer.post('/partners',{record}).then ( res => res.data);
         console.log('협력사등록',cdata,userid);      
        if(cdata === "OK") {
            openNotificationWithIcon('success', '협력사 등록', '새로운 협력사를 등록했습니다.', 'topRight');
            getPartners();
        }
        else if(cdata === 'bad data') {
            openNotificationWithIcon('error', '협력사 등록 실패 !', '잘못된 데이터로 인해 등록에 실패했습니다. 다시 시도해주세요.', 'topLeft');
        }
        else if(cdata === 'Failed') {
            openNotificationWithIcon('error', '협력사 등록실패', '기존 협력사를 등록하는데 실패했습니다.', 'topLeft');
        }
        else {
            Modal.info({
                title : '에러가 검출 되었습니다.',
                content : (
                    <><br />
                      {cdata}
                    </>
                ),
                onOk() {},
            });
        }
    }

    const update_partners = async (values) => {
        
        let data = Data;
        let record = values;

        record._id = SelectLowData._id;
        record.updateWriter = userid;

        let cdata = await apiServer.put(`/partners/${record._id}`,{record}).then ( res => res.data);

        if(cdata === "OK") {
            openNotificationWithIcon('success', '협력사 수정', '기존 협력사를 수정하였습니다.', 'topRight');
            setSelectLowData({});
            getPartners();
        }
        else if(cdata === 'Failed') {
            openNotificationWithIcon('error', '협력사 수정실패', '기존 협력사를 수정하는데 실패했습니다.', 'topLeft');
        }
        else {
            Modal.info({
                title : '에러가 검출 되었습니다.',
                content : (
                    <><br />
                      {cdata}
                    </>
                ),
                onOk() {},
            });
        }
    }

    const inputHandler = (name,event) => {

        let record = SelectLowData;

        console.log(name, event)
        console.log(event.target.value)
        console.log(event.currentTarget.value)
        if(event.currentTarget.value === '')
            record[name] = event.currentTarget.value;
        else 
            record[name] = event.currentTarget.value;

        setSelectLowData({...record});
    }

    const selectHandler = (name,event) => {

        let body = Searchtext;
    
        body[name] = event;
        setSearchtext(body);

    }

    const selectHandler_modal = (name,event) => {
       
        let record = SelectLowData;
        // console.log('event',name, event);
        record[name] = event;

        setSelectLowData({...record});
    }

    const datepickerhandler = (name, _date, event) =>{
        let record = SelectLowData;

        // console.log('params', name, _date, event)
        // console.log('datehandler record',SelectLowData);
        record[name] = event;

        //console.log(moment().format('YYYY-MM-DD'))

//        form.setFieldsValue({name : event});
//        console.log(form.getFieldsValue());
        setSelectLowData({...record});
     }

    const optionRander = (name) =>{
        console.log('optionname',name);
    }

    const renderOption = (selectoption) => (
        selectoption && selectoption.map((value,index) => (
            <Option key={value._id} value={value.name} label={value.name} > {value.name} </Option>
        ))
    )

    const clearHandler = ( name ) => {
        let body = Searchtext;
        body[name] = '';
        setSearchtext(body);
        // console.log('name : ', name, body[name])

    }

    const clearHandler_modal = ( name ) => {
        let record = SelectLowData;
        // console.log('event',name);
        record[name] = '';

        setSelectLowData({...record});
    }

    const codeCheck = async (value) => {

        let cdata = await apiServer.get('/partners-codesearch',{ params : {'code' : value}}).then ( res => res.data);

        if (cdata === 'Overlap'){
            openNotificationWithIcon('error', '중복코드', '해당코드는 사용중입니다.', 'topLeft');
            return('Failed');
        } 
        else if( cdata === 'OK') {
            openNotificationWithIcon('success', '사용가능 코드', '사용할 수 있는 코드입니다.', 'topRight');
            return('Success');
        }    
        else if( cdata === 'Failed') {
            openNotificationWithIcon('error', '코드를 입력하세요', '4자리 코드가 입력되지 않았습니다.', 'topLeft');
            return('Failed');
        }
    }

    const onChangeOpenPost = () => {
        setIsOpenPost(!isOpenPost);
      };

    const onCompletePost = (data) => {

        let record = SelectLowData;
        let fullAddr = data.address;
        let extraAddr = '';
        // console.log('zipcode',data);
        if (data.addressType === 'R') {
            if (data.bname !== '') {
            extraAddr += data.bname;
            }
            if (data.buildingName !== '') {
            extraAddr += extraAddr !== '' ? `, ${data.buildingName}` : data.buildingName;
            }
            fullAddr += extraAddr !== '' ? ` (${extraAddr})` : '';
        }
        record.zipcode = data.zonecode;
        record.address1 = fullAddr;

        form.setFieldsValue({ zipcode : data.zonecode })
        form.setFieldsValue({ address1 : fullAddr })

        //        setSelectLowData({...record});
//        setAddress(data.zonecode);
  //      setAddressDetail(fullAddr);
        setIsOpenPost(false);
    };
    
    const openNotificationWithIcon = (type, title, message, placement = null ) => {
        notification[type]({
          message: title,
          description: message,
          placement,
        });
    };
    
    const postCodeStyle = {
        position: "absolute",
        top: "0px",
        left: "0px",
        zIndex: "100",
        border: "1px solid #000000",
        overflow: "hidden",
        width: "595px",
        height : "450px"
    };

    const openZipCode = (e) => {
//        console.log('열림')
        setIsOpenPost(true);
    }

    const handleSubmit = (values) => {
        if (ModalMode === '수정') {
            update_partners(values);
        }
        if (ModalMode === '쓰기') {
            insert_partners(values);
        }
        setModalonoff(false);
        setWModalonoff(false);
    }

    const handleDelete = async (key) => {
        let response =  await apiServer.delete(`/partners/${key}`).then ( res => res.data);

        if(response === "OK") {
            setModalonoff(false);
            setWModalonoff(false);
            getPartners();
        }
    }

    const postModalCancel = () => {
        setIsOpenPost(false);
    }

   return(
    <div style={{ width: '100%' }}>
    <div style={{ textAlign: 'center' }}>
    <img src="images/title_head_partners_01.png" height="130" /><br /><br />
        {/* <DownloadExcel dataSource={Data} columns={columns_partners} filename={filename} ></DownloadExcel> */}
        <Row justify='center'>
            <Space size={[60, 10]} wrap>
                {Division === '본사' && userClass === '관리자' ? 
                    <Button type='primary'
                    onClick = {WriteModal}
                     > 협력사등록</Button>
                : null}
                <Select placeholder="회원상태" 
                    style={{width:110}}
                    onSelect={(e) => selectHandler('orNotUse', e)}
                    onClear={() => clearHandler('orNotUse') }
                    allowClear>
                        {renderOption(option_orNotUse2)}</Select>
                <Select 
                    placeholder="사업자구분"
                    style={{width:100}}
                    onSelect={(e) => selectHandler('businessClass', e)}
                    onClear={() => clearHandler('businessClass') }
                    allowClear>
                        {renderOption(option_businessClass)}</Select>
                <Select 
                    placeholder="제조국가"
                    style={{width:100}}
                    onSelect={(e) => selectHandler('country', e)}
                    onClear={() => clearHandler('country') }
                    allowClear>
                        {renderOption(option_country)}</Select>
                <Search style={{width:400}}
                    allowClear
                    placeholder="상호 혹은 대표자이름 으로 검색해주세요."
                    enterButton="검 색"
                    onSearch={value => runSerch(value)}/>

                <SelectBox
                    list = {continents_pagination}
                    focus_list = {PageSize}
                    refreshFunction={UpdatePagination}></SelectBox>
                {Division === '본사' && userClass === '관리자' ? 
                    <DownloadExcel dataSource={Data} columns={columns_partners} filename={filename} /> 
                : null }
            </Space>
        </Row>
        <Row>
            <Col span={24}> <span> <p> </p> </span></Col>
        </Row>
        <Row justify='center'>
            <Table  style={{cursor : 'pointer', width:1600}}
                    dataSource={Data} 
                    pagination={{ pageSize: PageSize, position: ['bottomCenter'] }}
                    columns={columns_partners} 
                    rowKey={Data => Data._id}
                    onRow={(record,index) => ({
                        onClick: () => {
                            clickselectRow(record,index)
                        },})}
                    scroll={{ x: 1000 }} size="small">
            </Table>
        </Row>

        <Modal
            width={1200}
            visible={Modalonoff}
            title='협력사 상세 보기'
            //onOk={handleOk}
            onCancel={handleCancel}
            footer={[
                <Button key="back" onClick={handleCancel}>닫 기</Button>,
                ]}>
            <Row justify='center' sytle={{width:'100%'}}>
                <Descriptions bordered column={{xxl:3, xl:3, lg:3, md:2, sm:2, xs:1}} style={{width:'100%'}}>
                    <Descriptions.Item label='협력사코드' labelStyle={{'text-align':'right'}}>{SelectLowData.code}</Descriptions.Item>
                    <Descriptions.Item label='사업자구분' labelStyle={{'text-align':'right'}}>{SelectLowData.businessClass}</Descriptions.Item>
                    <Descriptions.Item label='제조국가' labelStyle={{'text-align':'right'}}>{SelectLowData.country}</Descriptions.Item>
                    <Descriptions.Item label='상호' labelStyle={{'text-align':'right'}}>{SelectLowData.shopName}</Descriptions.Item>
                    <Descriptions.Item label='사업자번호' labelStyle={{'text-align':'right'}}>{SelectLowData.businessNumber}</Descriptions.Item>
                    <Descriptions.Item label='대표자' labelStyle={{'text-align':'right'}}>{SelectLowData.CEO}</Descriptions.Item>
                    <Descriptions.Item label='업태' labelStyle={{'text-align':'right'}}>{SelectLowData.businessCondition}</Descriptions.Item>
                    <Descriptions.Item label='종목' labelStyle={{'text-align':'right'}}>{SelectLowData.businessType}</Descriptions.Item>
                    <Descriptions.Item label='우편번호' labelStyle={{'text-align':'right'}}>{SelectLowData.zipcode}</Descriptions.Item>
                    <Descriptions.Item label='주소' labelStyle={{'text-align':'right'}}>{SelectLowData.address1}</Descriptions.Item>
                    <Descriptions.Item label='상세주소' labelStyle={{'text-align':'right'}}>{SelectLowData.address2}</Descriptions.Item>
                    <Descriptions.Item label='전화번호' labelStyle={{'text-align':'right'}}>{SelectLowData.telNumber}</Descriptions.Item>
                    <Descriptions.Item label='팩스번호' labelStyle={{'text-align':'right'}}>{SelectLowData.FAX}</Descriptions.Item>
                    <Descriptions.Item label='휴대전화' labelStyle={{'text-align':'right'}}>{SelectLowData.mobile}</Descriptions.Item>
                    <Descriptions.Item label='담당자' labelStyle={{'text-align':'right'}}>{SelectLowData.contactMan}</Descriptions.Item>
                    <Descriptions.Item label='담당자 연락처' labelStyle={{'text-align':'right'}}>{SelectLowData.contactPhone}</Descriptions.Item>
                    <Descriptions.Item label='이메일' labelStyle={{'text-align':'right'}}>{SelectLowData.email}</Descriptions.Item>
                    <Descriptions.Item label='개업년월일' labelStyle={{'text-align':'right'}}>{SelectLowData.openDate}</Descriptions.Item>
                    <Descriptions.Item label='계약체결일' labelStyle={{'text-align':'right'}}>{SelectLowData.agreementDate}</Descriptions.Item>
                    <Descriptions.Item label='거래종료일' labelStyle={{'text-align':'right'}}>{SelectLowData.closeDate}</Descriptions.Item>
                    <Descriptions.Item label='취급품목' labelStyle={{'text-align':'right'}}>{SelectLowData.goods}</Descriptions.Item>
                    <Descriptions.Item label='거래조건' labelStyle={{'text-align':'right'}}>{SelectLowData.termsNconditions}</Descriptions.Item>
                    <Descriptions.Item label='사용여부' labelStyle={{'text-align':'right'}}>{SelectLowData.orNotUse}</Descriptions.Item>
                </Descriptions>
                <Descriptions bordered style={{width:'100%'}}>
                    <Descriptions.Item label='비고' labelStyle={{'text-align':'right'}}>
                        {SelectLowData.note && SelectLowData.note.split('\n').map((item, idx) => { return( <span key={idx}>{item}<br /></span>)})}
                    </Descriptions.Item>
                </Descriptions>
            </Row>
        </Modal>
        
        {/* 추 가 / 등 록 */}
        <Modal
            width={1200}
            visible={WModalonoff}
            title={ModalTitle}
            onOk={form.submit}
            onCancel={handleCancel}
            footer={[
                <DeleteButton isAdmin={userClass} onDelete={handleDelete} deleteKey={SelectLowData._id} />,
                <Button key="back" onClick={handleCancel}>닫 기</Button>,
                <Button key="submit" type="primary" onClick={form.submit} style={{width:150}}>저 장</Button>
            ]}>
            <Row justify='center'>
                <Form form={form} onFinish={handleSubmit} style={{width:'100%'}} labelCol={{span:8}}>
                    <Card style={{padding:0, width:'100%',borderRadius:5, borderColor:'#999999'}}>
                    <Row>
                        <Col span={8}>
                            <Form.Item label="협력사코드"
                                   name="code"
                                   rules={[{ required: true, message: '협력사 코드가 반드시 필요합니다'}]}>
                            <span>
                            <Input onChange={(e) => inputHandler('code', e)}
                                   defaultValue={form.setFieldsValue({ code : SelectLowData.code })}
                                   value={SelectLowData.code}
                                   placeholder='4자리 코드'
                                   maxlength={4}
                                   allowClear
                                   style={{width:150}} />
                                   <Button
                                        onClick = {(e) => codeCheck(form.getFieldValue('code'))}
                                        type="primary"
                                        disabled={false}
                                        // disabled={inputOnOff}
                                        >중복확인</Button>
                            </span>                                       
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="상 호"
                                   name="shopName"
                                   rules={[{ required: true, message: '상호명이 반드시 필요합니다'}]}>
                            <Input 
                                   onBlur={(e) => inputHandler('shopName', e)}
                                   defaultValue={form.setFieldsValue({ shopName : SelectLowData.shopName })}
                                   value={SelectLowData.shopName}
                                   style={{ width: '100%' }} />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={8}>
                            <Form.Item label="사업자구분"
                                   name="businessClass"
                                   rules={[{ required: true, message: '사업자 구분이 반드시 필요합니다'}]}>
                                <Select
                                    placeholder="사업자구분"
                                    style={{width:120}}
                                    onSelect={(e) => selectHandler_modal('businessClass', e)}
                                    onClear={() => clearHandler_modal('businessClass') }
                                    defaultValue={form.setFieldsValue({ businessClass : SelectLowData.businessClass })}
                                    value={SelectLowData.businessClass}
                                    allowClear>
                                        {renderOption(option_businessClass)}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="제조국가"
                                   name="country"
                                   rules={[{ required: true, message: '제조국가는 반드시 필요합니다'}]}>
                                <Select
                                    placeholder="사업자구분"
                                    style={{width:120}}
                                    onSelect={(e) => selectHandler_modal('country', e)}
                                    onClear={() => clearHandler_modal('country') }
                                    defaultValue={form.setFieldsValue({ country : SelectLowData.country })}
                                    value={SelectLowData.country}
                                    allowClear>
                                        {renderOption(option_country)}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="사용여부"
                                   name="orNotUse"
                                   rules={[{ required: true, message: '사용여부는 반드시 필요합니다'}]}>
                                <Select
                                    placeholder="사용여부"
                                    style={{width:120}}
                                    onSelect={(e) => selectHandler_modal('orNotUse', e)}
                                    onClear={() => clearHandler_modal('orNotUse') }
                                    defaultValue={form.setFieldsValue({ orNotUse : SelectLowData.orNotUse })}
                                    value={SelectLowData.orNotUse}
                                    allowClear>
                                        {renderOption(option_orNotUse2)}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    </Card>
                    <Card style={{ maxHeight: 300, overflow: "auto", borderColor:'white'}}>
                    <Row>
                        <Col span={8}>
                            <Form.Item label="사업자번호"
                                   name="businessNumber">
                                <Input onBlur={(e) => inputHandler('businessNumber', e)}
                                   defaultValue={form.setFieldsValue({ businessNumber : SelectLowData.businessNumber })}
                                   value={SelectLowData.businessNumber}
                                   style={{ width: '100%' }} />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="대표자"
                                   name="CEO">
                                <Input onBlur={(e) => inputHandler('CEO', e)}
                                   defaultValue={form.setFieldsValue({ CEO : SelectLowData.CEO })}
                                   value={SelectLowData.CEO}
                                   style={{ width: '100%' }} />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="핸드폰"
                                   name="mobile">
                                <Input onBlur={(e) => inputHandler('mobile', e)}
                                   defaultValue={form.setFieldsValue({ mobile : SelectLowData.mobile })}
                                   value={SelectLowData.mobile}
                                   style={{ width: '100%' }} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={8}>
                            <Form.Item label="우편번호"
                                   name="zipcode">
                                <Input onBlur={(e) => inputHandler('zipcode', e)}
                                       style={{width:120}}
                                       defaultValue={form.setFieldsValue({ zipcode : SelectLowData.zipcode })}
                                       value= {SelectLowData.zipcode} />
                                <Button type='primary'
                                       onClick={ (e)=> openZipCode(e) }>
                                       우편번호검색</Button>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="주소"
                                   name="address1">
                                <Input onBlur={(e) => inputHandler('address1', e)}
                                   defaultValue={form.setFieldsValue({ address1 : SelectLowData.address1 })}
                                   value={SelectLowData.address1}
                                   style={{ width: '100%' }} />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="상세주소"
                                   name="address2">
                                <Input onBlur={(e) => inputHandler('address2', e)}
                                   defaultValue={form.setFieldsValue({ address2 : SelectLowData.address2 })}
                                   value={SelectLowData.address2}
                                   style={{ width: '100%' }} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={8}>
                            <Form.Item label="전화번호"
                                   name="telNumber">
                                <Input onBlur={(e) => inputHandler('telNumber', e)}
                                   defaultValue={form.setFieldsValue({ telNumber : SelectLowData.telNumber })}
                                   value={SelectLowData.telNumber}
                                   style={{ width: '100%' }} />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="업태"
                                   name="businessCondition">
                                <Input onBlur={(e) => inputHandler('businessCondition', e)}
                                   defaultValue={form.setFieldsValue({ businessCondition : SelectLowData.businessCondition })}
                                   value={SelectLowData.businessCondition}
                                   style={{ width: '100%' }} />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="종목"
                                   name="businessType">
                                <Input onBlur={(e) => inputHandler('businessType', e)}
                                   defaultValue={form.setFieldsValue({ businessType : SelectLowData.businessType })}
                                   value={SelectLowData.businessType}
                                   style={{ width: '100%' }} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={8}>
                            <Form.Item label="팩스"
                                   name="FAX">
                                <Input onBlur={(e) => inputHandler('FAX', e)}
                                   defaultValue={form.setFieldsValue({ FAX : SelectLowData.FAX })}
                                   value={SelectLowData.FAX}
                                   style={{ width: '100%' }} />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="담당자"
                                   name="contactMan">
                                <Input onBlur={(e) => inputHandler('contactMan', e)}
                                   defaultValue={form.setFieldsValue({ contactMan : SelectLowData.contactMan })}
                                   value={SelectLowData.contactMan}
                                   style={{ width: '100%' }} />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="담당자연락처"
                                   name="contactPhone">
                                <Input onBlur={(e) => inputHandler('contactPhone', e)}
                                   defaultValue={form.setFieldsValue({ contactPhone : SelectLowData.contactPhone })}
                                   value={SelectLowData.contactPhone}
                                   style={{ width: '100%' }} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={8}>
                            <Form.Item label="이메일"
                                   name="email">
                                <Input onBlur={(e) => inputHandler('email', e)}
                                   defaultValue={form.setFieldsValue({ email : SelectLowData.email })}
                                   value={SelectLowData.email}
                                   style={{ width: '100%' }} />
                            </Form.Item>
                        </Col>
                        <Col span={16}>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={8}>
                            <Form.Item label="개업년월일"
                                       name="openDate">
                                <InputMask mask='9999-99-99' maskChar='_' style={{'border': '1px solid #cccccc', height:30, padding:10}}
                                   defaultValue={form.setFieldsValue({ openDate : SelectLowData.openDate })}
                                   value={SelectLowData.openDate } />
                                {/* <DatePicker onChange={(e,f) => datepickerhandler('openDate', e, f)}
                                    defaultValue={form.setFieldsValue({ openDate : SelectLowData.openDate ? moment(SelectLowData.openDate, dateFormat) : moment(moment(), dateFormat)})} 
                                    value={form.setFieldsValue({ openDate : SelectLowData.openDate ? moment(SelectLowData.openDate, dateFormat) : moment(moment(), dateFormat)})} 
                                    format={dateFormat}
                                    locale={locale} /> */}
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="계약체결일"
                                       name="agreementDate">
                                <InputMask mask='9999-99-99' maskChar='_' style={{'border': '1px solid #cccccc', height:30, padding:10}}
                                   defaultValue={form.setFieldsValue({ agreementDate : SelectLowData.agreementDate })}
                                   value={SelectLowData.agreementDate } />
                                {/* <DatePicker onChange={(e,f) => datepickerhandler('agreementDate', e, f)}
                                    defaultValue={form.setFieldsValue({ agreementDate : SelectLowData.agreementDate ? moment(SelectLowData.agreementDate, dateFormat) : moment(moment(), dateFormat)})} 
                                    value={form.setFieldsValue({ agreementDate : SelectLowData.agreementDate ? moment(SelectLowData.agreementDate, dateFormat) : moment(moment(), dateFormat)})} 
                                    format={dateFormat}
                                    locale={locale} /> */}
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="거래종료일"
                                   name="closeDate">
                                <InputMask mask='9999-99-99' maskChar='_' style={{'border': '1px solid #cccccc', height:30, padding:10}}
                                   defaultValue={form.setFieldsValue({ closeDate : SelectLowData.closeDate })}
                                   value={SelectLowData.closeDate } />
                                {/* <DatePicker onChange={(e,f) => datepickerhandler('closeDate', e, f)}
                                    defaultValue={form.setFieldsValue({ closeDate : SelectLowData.closeDate ? moment(SelectLowData.closeDate, dateFormat) : moment(moment(), dateFormat)})} 
                                    value={form.setFieldsValue({ closeDate : SelectLowData.closeDate ? moment(SelectLowData.closeDate, dateFormat) : moment(moment(), dateFormat)})} 
                                    format={dateFormat}
                                    locale={locale} /> */}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={8}>
                            <Form.Item label="취급품목"
                                       name="goods">
                                <TextArea style={{width:'100%'}}
                                    onBlur={(e) => inputHandler('goods', e)}
                                    rows={5}
                                    defaultValue={form.setFieldsValue({ goods : SelectLowData.goods })}
                                    value={SelectLowData.goods}
                                    disabled={false} />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="거래조건"
                                       name="termsNconditions">
                                <TextArea style={{width:'100%'}}
                                    onBlur={(e) => inputHandler('termsNconditions', e)}
                                    rows={5}
                                    defaultValue={form.setFieldsValue({ termsNconditions : SelectLowData.termsNconditions })}
                                    value={SelectLowData.termsNconditions}
                                    disabled={false} />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="비고"
                                       name="note">
                                <TextArea style={{width:'100%'}}
                                    onBlur={(e) => inputHandler('note', e)}
                                    rows={5}
                                    defaultValue={form.setFieldsValue({ note : SelectLowData.note })}
                                    value={SelectLowData.note}
                                    disabled={false} />
                            </Form.Item>
                        </Col>
                    </Row>
                    </Card>            
                </Form>
            </Row>

             {isOpenPost  ? (
                <Modal 
                    visible={isOpenPost}
                    width={200}
                    onCancel={postModalCancel}>
                    <DaumPostcode 
                        style={postCodeStyle} 
                        autoClose 
                        onComplete={onCompletePost} 
                        {...props} />
                    </Modal>
                ) : null}
            
        </Modal>
   </div>
   </div>
   ) 
}
export default Partners;